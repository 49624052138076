const awsExports = {
  API: {
    endpoints: [
      {
        name: "UsersApi",
        endpoint: import.meta.env.VITE_USERS_API_URL,
      },
    ],
  },
  Auth: {
    region: import.meta.env.VITE_COGNITO_REGION,
    userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,
    identityPoolId: import.meta.env.VITE_COGNITO_IDENTITY_POOL_ID,
    userPoolWebClientId: import.meta.env.VITE_COGNITO_WEB_CLIENT_ID,
    mandatorySignIn: false,
    oauth: {
      domain: import.meta.env.VITE_COGNITO_DOMAIN,
      scope: ["email", "openid", "profile"],
      redirectSignIn: import.meta.env.VITE_COGNITO_SIGNIN_URL,
      redirectSignOut: import.meta.env.VITE_COGNITO_SIGNOUT_URL,
      responseType: "code",
    },
  },
}

export default awsExports
