import { useEffect, useRef, useState } from "react"

const useFlashEffect = <T>(trigger: T, duration = 1500) => {
  const [isFlashing, setIsFlashing] = useState(false)
  const prevTriggerRef = useRef<T | null>(null)
  const timeoutRef = useRef<number | null>(null)
  const isFirstRender = useRef(true)

  useEffect(() => {
    // Check if we should trigger flash
    const shouldTriggerFlash = trigger && (isFirstRender.current || prevTriggerRef.current !== trigger)

    if (shouldTriggerFlash) {
      // Update refs
      isFirstRender.current = false
      prevTriggerRef.current = trigger

      // Start flash
      setIsFlashing(true)

      // Clear existing timeout
      if (timeoutRef.current) clearTimeout(timeoutRef.current)

      // Set new timeout
      timeoutRef.current = window.setTimeout(() => {
        setIsFlashing(false)
        timeoutRef.current = null
      }, duration)
    }

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
    }
  }, [trigger, duration])

  return isFlashing
}

export default useFlashEffect
