import { useQuery } from "@tanstack/react-query"
import execute from "../api/SessionsAPIGraphQL"
import { GetActiveUsersDocument } from "@torc-robotics/vda-sessions-api/graphql/document"

const useGetActiveUsers = () => {
  const query = useQuery({
    queryKey: ["active-users"],
    queryFn: async () => execute(GetActiveUsersDocument),
    refetchInterval: 4 * 60 * 1000,
  })
  return query
}

export default useGetActiveUsers
