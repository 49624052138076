import { useQuery } from "@tanstack/react-query"
import execute from "../api/CallsAPIGraphQL"
import { GenerateSipCredentialsDocument } from "@torc-robotics/vda-calls-api/graphql/document"

const useSipCredentials = () => {
  const query = useQuery({
    queryKey: ["sip-token"],
    queryFn: /** istanbul ignore next */ async () => execute(GenerateSipCredentialsDocument),
    refetchInterval: 1000 * 60 * 60 * 12, // 12 hours
    refetchIntervalInBackground: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  const token = query.data?.data?.generateSipCredentials?.token

  return { query, token }
}

export default useSipCredentials
