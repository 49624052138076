import "./scss/statusIcon.scss"
import useGetRemoteAssistanceEventsQuery from "@/framework/query/useGetRemoteAssistanceEventsQuery"
import useFlashEffect from "@/framework/hooks/useFlashEffect"

const StatusIcon = ({ loadId, noMargin }: { loadId: string; noMargin: boolean }) => {
  const { data: raEventsData } = useGetRemoteAssistanceEventsQuery()
  const latestEvent = raEventsData?.data?.getEvents?.find((event) => event.loadId === loadId)

  const flashTrigger = latestEvent?.eventDetails?.timestamp
  const isFlashing = useFlashEffect(flashTrigger)

  const isAssist = Boolean(latestEvent)
  const iconLabel = isAssist ? "A" : "M"
  const dataTestId = isAssist ? "assist-icon" : "monitor-icon"
  const iconTypeClass = isAssist ? "assist" : "monitor"
  const flashingClass = isAssist && isFlashing ? "flashing" : ""
  const noMarginClass = noMargin ? "no-margin" : ""

  return (
    <span data-testid={dataTestId} className={`tab-icon ${iconTypeClass} ${flashingClass} ${noMarginClass}`}>
      {iconLabel}
    </span>
  )
}

export default StatusIcon
