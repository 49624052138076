import { Box, Typography } from "@mui/material"
import "./scss/departureTimeCell.scss"

const DepartureTimeCell = ({ value }: { value: string }) => {
  const getCellClass = (value: string) => {
    if (value === "Pending") return "pending"
    else if (value.includes("minutes")) return "on-time"
    else return "delayed"
  }

  return (
    <Box className={`departure-time-cell ${getCellClass(value)}`}>
      <Typography variant="body2">{value}</Typography>
    </Box>
  )
}

export default DepartureTimeCell
