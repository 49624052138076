import { useQuery } from "@tanstack/react-query"
import execute from "../api/SessionsAPIGraphQL"
import { ActiveSession, GetSessionsDocument } from "@torc-robotics/vda-sessions-api/graphql/document"
import { useContext } from "react"
import { UserContext } from "@torc-robotics/mcli-mfui-auth"

const sortSessions = (sessionA: ActiveSession, sessionB: ActiveSession) => {
  if (sessionA.createdTs < sessionB.createdTs) return -1
  if (sessionA.createdTs > sessionB.createdTs) return 1
  return 0
}
export const useGetSessions = () => {
  const {
    userData: { email },
  } = useContext(UserContext)

  const query = useQuery({
    queryKey: ["getSessions"],
    queryFn: /* istanbul ignore next */ async () => await execute(GetSessionsDocument),
    refetchOnWindowFocus: "always",
    refetchInterval: false,
    staleTime: Infinity,
  })

  const sortedSessions = query.data?.data?.getSessions.sort(sortSessions)

  const myActiveSessions = sortedSessions?.filter((session) => session.user === email) || []
  const allOtherSessions = sortedSessions?.filter((session) => session.user !== email) || []
  const allActiveSessions = sortedSessions || []

  return {
    query,
    myActiveSessions,
    allOtherSessions,
    allActiveSessions,
  }
}

export default useGetSessions
