import "./DragHandle.scss"
import DragIndicatorIcon from "@mui/icons-material/DragIndicator"
import useActiveSessionTabStore from "@/framework/store/useActiveSessionTabStore"
import { grabHandleTabs } from "../remote-assistance/tabHelper"

const DragHandle = () => {
  const { currentTab } = useActiveSessionTabStore()

  return (
    <div className="swapy-drag-handle" style={{ display: grabHandleTabs.includes(currentTab) ? "" : "none" }}>
      <div className="swapy-drag-handle__icon" data-swapy-handle>
        <DragIndicatorIcon sx={{ transform: "rotate(90deg)" }} />
      </div>
    </div>
  )
}

export default DragHandle
