import { Button, Modal, Typography } from "@mui/material"
import "./ActionRerouteModal.scss"

const ActionRerouteModal = ({ isOpen, submitButton, handleCancel, disableButton, enableButton, selectedRoute }) => {
  const rerouteModalTitle = "Route Selection"
  const rerouteModalConfirmation = "Confirmation"
  const selectRerouteModalMessage = "Please select an alternative route"

  return (
    <Modal open={isOpen} onClose={handleCancel} className="reroute-modal" id="modal">
      <div className="reroute-modal-container">
        <div id="reroute-map-modal" className="reroute-modal__map" />
        <Typography className="reroute-modal__title">
          {selectedRoute ? rerouteModalConfirmation : rerouteModalTitle}
        </Typography>
        <Typography variant="body1" className="reroute-modal__text">
          {selectedRoute ? (
            <>
              <Typography>
                <span className="route-modal__route-info">
                  {selectedRoute.length_miles} mi, {selectedRoute.estimated_time_minutes} min route
                </span>{" "}
                has been selected. Are you sure you want to send a reroute request to the SDT?
              </Typography>
            </>
          ) : (
            selectRerouteModalMessage
          )}
        </Typography>
        <div className="reroute-modal__btns">
          <Button variant="contained" className="reroute-modal-btn--cancel" type="reset" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            className="reroute-modal-btn--confirm"
            type="submit"
            disabled={!enableButton || disableButton}
            onClick={submitButton}
          >
            Send Reroute Request
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ActionRerouteModal
