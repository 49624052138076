import useGetRemoteAssistanceEventsQuery from "@/framework/query/useGetRemoteAssistanceEventsQuery"
import { RemoteAssistanceEvent } from "@torc-robotics/vda-remote-assistance-api/graphql/document"
import "./AssistanceQueue.scss"
import { useState } from "react"
import useGetSessions from "@/framework/query/useGetSessions"
import AssistanceQueueListItem from "./AssistanceQueueListItem"
import InboundCallItem from "./inbound-call/InboundCallItem"
import AssistanceQueueMapPanel from "@/components/assistance-queue/AssistanceQueueMapPanel"
import useGetConferenceCalls from "@/framework/query/useGetConferenceCalls"

const AssistanceQueue = () => {
  const sessionsQuery = useGetSessions()
  const { data, isLoading, isError } = useGetRemoteAssistanceEventsQuery()
  const activeSessionMissionIds = sessionsQuery.allActiveSessions.map((session) => session.loadId)
  const { data: conferenceCallsData } = useGetConferenceCalls()

  const [expandedLoadId, setExpandedLoadId] = useState<string | false>(false)

  const selectedVehicle = data?.data?.getEvents?.find((event) => event.loadId === expandedLoadId)?.vehicleId

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedLoadId(isExpanded ? panel : false)
  }

  const filteredQueueListData = Object.values(
    data?.data?.getEvents?.reduce<Record<string, RemoteAssistanceEvent>>((acc, event) => {
      if (activeSessionMissionIds.includes(event.loadId)) {
        return acc
      }
      if (acc[event.loadId] && acc[event.loadId].eventType === "MRM_STATUS") {
        return acc
      }

      return {
        ...acc,
        [event.loadId]: event,
      }
    }, {}) ?? {}
  ).sort((a, b) => a.creationTs - b.creationTs)

  return (
    <div className="assistance-queue">
      <div className="assistance-queueu__header">
        <h2>Assistance Queue ({Object.values(filteredQueueListData)?.length})</h2>
      </div>
      <div className="assistance-queue__queue" id="assistance-queue-list" data-testid="assistance-queue-list">
        {isLoading && <div>loading...</div>}
        {isError && (
          <p>
            There was an error loading the assistance queue at this time, please contact @vda if the issue persists.
          </p>
        )}

        {conferenceCallsData?.data?.getActiveConferences?.map((call) => (
          <InboundCallItem key={call.conferenceUuid} call={call} />
        ))}

        {Object.values(filteredQueueListData).map((event) => (
          <AssistanceQueueListItem
            key={event.loadId}
            event={event}
            expandedLoadId={expandedLoadId}
            handleChange={handleChange}
          />
        ))}
      </div>
      <div className="assistance-queue__map">
        <AssistanceQueueMapPanel selectedVehicle={selectedVehicle} />
      </div>
    </div>
  )
}

export default AssistanceQueue
