import { Button, IconButton } from "@mui/material"
import "./InboundCallItem.scss"
import { formatPhoneNumber } from "@/framework/utils/Utils"
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined"
import CreateCallLogModal from "../create-note/CreateCallLogModal"
import { useState } from "react"
import { Conference } from "@torc-robotics/vda-calls-api/graphql/document"

const InboundCallItem = ({ call }: { call: Conference }) => {
  const [modalOpen, setModalOpen] = useState(false)

  const completeCallLogModal = () => {
    setModalOpen(false)
  }

  const cancelCallLogModal = () => {
    setModalOpen(false)
  }

  const openCallLogModal = () => {
    setModalOpen(true)
  }

  const number = formatPhoneNumber(call?.fromNumber)
  return (
    <div className="inbound-call-item-container">
      <p className="assistance-queue__inbound-call--number">{number}</p>

      <Button variant="contained" color="primary" size="small" className="answer-button" sx={{ pointerEvents: "auto" }}>
        Answer
      </Button>

      <IconButton className="note-icon" aria-label="edit note" onClick={openCallLogModal}>
        <EditNoteOutlinedIcon />
      </IconButton>

      <CreateCallLogModal
        conferenceUuid={call?.conferenceUuid}
        isOpen={modalOpen}
        onCancel={cancelCallLogModal}
        onComplete={completeCallLogModal}
      />
    </div>
  )
}

export default InboundCallItem
