import { useEffect, useState } from "react"

const useGetElementDimensions = (elementId: string): { width: number; height: number } => {
  const [elementDimensions, setElementDimensions] = useState({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    const element = document.getElementById(elementId)

    if (element == null) {
      return
    }

    const resizeObserver = new ResizeObserver((entries) => {
      if (!Array.isArray(entries) || entries.length === 0) {
        return
      }

      const entry = entries[0]

      setElementDimensions({
        width: entry.borderBoxSize[0].inlineSize,
        height: entry.contentRect.height,
      })
    })

    resizeObserver.observe(element)

    return () => {
      resizeObserver.unobserve(element)
    }
  }, [elementId])
  return elementDimensions
}

export default useGetElementDimensions
