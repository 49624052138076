import { useQuery } from "@tanstack/react-query"
import { GetEventsDocument } from "@torc-robotics/vda-remote-assistance-api/graphql/document"
import execute from "../api/RemoteAssistanceAPIGraphQL"

const useGetRemoteAssistanceEventsQuery = () => {
  const query = useQuery({
    queryKey: ["getEvents"],
    queryFn: /* istanbul ignore next */ async () => await execute(GetEventsDocument),
    refetchOnWindowFocus: "always",
    refetchInterval: false,
    staleTime: Infinity,
  })

  return query
}

export default useGetRemoteAssistanceEventsQuery
