import { gql, useSubscription } from "@apollo/client"
import { useQueryClient } from "@tanstack/react-query"
import {
  OnEventAddDocument,
  OnEventAddSubscription,
  OnEventAddSubscriptionVariables,
  OnEventUpdateDocument,
  OnEventUpdateSubscription,
  OnEventUpdateSubscriptionVariables,
  OnEventRemoveDocument,
  OnEventRemoveSubscription,
  OnEventRemoveSubscriptionVariables,
  GetEventsQuery,
  RemoteAssistanceEvent,
} from "@torc-robotics/vda-remote-assistance-api/graphql/document"
import { GraphQLResponse } from "../api/RemoteAssistanceAPIGraphQL"
import { getLogger } from "../../framework/contexts/DataDog"
import { getRaTraceId, logMetrics } from "../utils/Utils"

const logger = getLogger()

const EVENT_ADD_SUBSCRIPTION = gql`
  ${OnEventAddDocument}
`

const EVENT_UPDATE_SUBSCRIPTION = gql`
  ${OnEventUpdateDocument}
`

const EVENT_REMOVE_SUBSCRIPTION = gql`
  ${OnEventRemoveDocument}
`
const sameMissionAndEventType = (previous: RemoteAssistanceEvent, event: RemoteAssistanceEvent): boolean => {
  if (previous?.loadId === event.loadId && previous?.eventType === event.eventType) {
    return true
  }
  return false
}

const useRemoteAssistanceEventSubscriptions = () => {
  const queryClient = useQueryClient()

  const eventAddSubscription = useSubscription<OnEventAddSubscription, OnEventAddSubscriptionVariables>(
    EVENT_ADD_SUBSCRIPTION,
    {
      context: { clientName: "remoteAssistance" },
      onData: async ({ data: { data } }) => {
        if (!data?.onEventAdd) {
          return
        }
        const event = data.onEventAdd
        if (event.receivedTs) {
          const ra_trace_id = await getRaTraceId(event.vehicleId, event.loadId, event.tenant, event.creationTs)
          logMetrics(event, ra_trace_id, "added")
        }

        logger.debug(`event for mission ${event.loadId} added`, data)

        queryClient.setQueryData<GraphQLResponse<GetEventsQuery>>(["getEvents"], (prev) => {
          return {
            data: {
              getEvents: [...(prev?.data?.getEvents.filter((s) => !sameMissionAndEventType(s, event)) || []), event],
            },
          }
        })
      },
    }
  )

  const eventUpdateSubscription = useSubscription<OnEventUpdateSubscription, OnEventUpdateSubscriptionVariables>(
    EVENT_UPDATE_SUBSCRIPTION,
    {
      context: { clientName: "remoteAssistance" },
      onData: async ({ data: { data } }) => {
        if (!data?.onEventUpdate) {
          return
        }

        const event = data.onEventUpdate

        if (event.receivedTs) {
          const ra_trace_id = await getRaTraceId(event.vehicleId, event.loadId, event.tenant, event.creationTs)
          logMetrics(event, ra_trace_id, "updated")
        }

        logger.debug(`event for mission ${data?.onEventUpdate?.loadId} updated`, data)

        queryClient.setQueryData<GraphQLResponse<GetEventsQuery>>(["getEvents"], (prev) => {
          return {
            data: {
              getEvents: [...(prev?.data?.getEvents.filter((s) => !sameMissionAndEventType(s, event)) || []), event],
            },
          }
        })
      },
    }
  )

  const eventRemoveSubscription = useSubscription<OnEventRemoveSubscription, OnEventRemoveSubscriptionVariables>(
    EVENT_REMOVE_SUBSCRIPTION,
    {
      context: { clientName: "remoteAssistance" },
      onData: async ({ data: { data } }) => {
        if (!data?.onEventRemove) {
          return
        }

        const event = data?.onEventRemove

        if (event.receivedTs) {
          const ra_trace_id = await getRaTraceId(event.vehicleId, event.loadId, event.tenant, event.creationTs)
          logMetrics(event, ra_trace_id, "removed")
        }

        logger.debug(`event for mission ${data?.onEventRemove?.loadId} removed`, data)

        queryClient.setQueryData<GraphQLResponse<GetEventsQuery>>(["getEvents"], (prev) => {
          return {
            data: {
              getEvents: [...(prev?.data?.getEvents.filter((s) => !sameMissionAndEventType(s, event)) || [])],
            },
          }
        })
      },
    }
  )

  return { eventAddSubscription, eventUpdateSubscription, eventRemoveSubscription }
}

export default useRemoteAssistanceEventSubscriptions
