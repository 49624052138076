import {
  TableBody,
  TableHead,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Box,
  Typography,
  CircularProgress,
  Collapse,
  IconButton,
} from "@mui/material"
import "./scss/eventLog.scss"
import { CheckCircleOutline, HighlightOff, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"
import useGetMissionHistoryLog from "@/framework/query/useGetMissionHistoryLog"
import LoadScreen from "@/framework/utils/LoadScreen"
import InfiniteScroll from "react-infinite-scroll-component"
import { GetMissionLogResponse } from "@torc-robotics/mm-missions-client"
import { getFormattedTime } from "@/framework/utils/Utils"
import { useSelectedLogStore } from "@/framework/store/useSelectedLogStore"
import { useRef, useEffect, ReactNode, useState } from "react"
import EventDetails, { EventLogDetails } from "./EventDetails"
import { LogSummaryMissionStateEnum } from "@/framework/models/Mission"

type EventStatus = {
  name?: string
  icon?: string
}

type EventLog = {
  initiator?: string
  event?: string
  status?: EventStatus
  type?: string
  details?: EventLogDetails
}

const EventLog = () => {
  const tableHeaders = ["initiator", "event", "status", "type", "date", "time", ""]
  const { selectedLog, blink } = useSelectedLogStore()
  const [eventDetailsOpen, setEventDetailsOpen] = useState<Set<string>>(new Set())
  const selectedLogRef = useRef<HTMLTableRowElement | null>(null)

  const { data, isLoading, hasNextPage, fetchNextPage } = useGetMissionHistoryLog()
  console.log("Mission History Log: ", data)

  const filterLogs = (logs: GetMissionLogResponse[]) => {
    return (
      logs.filter((log) => {
        const isRemoteAssistance = log?.title === "Remote Assistance"
        const isStateTransition =
          log?.title === "Mission State Transition" &&
          Object.keys(LogSummaryMissionStateEnum).includes(log?.details?.state as string)
        const isVehicleConnectivity = log?.category === "VEHICLE_CONNECTIVITY"

        return isRemoteAssistance || isStateTransition || isVehicleConnectivity
      }) || []
    )
  }

  useEffect(() => {
    if (selectedLogRef.current && typeof selectedLogRef.current.scrollIntoView === "function") {
      selectedLogRef.current.scrollIntoView({ behavior: "instant", block: "nearest" })
    }
  }, [selectedLog])

  const handleFetchMore = async () => {
    console.debug("Fetching next page, has more:", hasNextPage)
    if (hasNextPage) {
      try {
        await fetchNextPage()
      } catch (error) {
        console.error("Error fetching next page: ", error as Error)
      }
    }
  }

  const stringOrMDash = (value: string | null | undefined) => {
    return value ? value : "\u2014"
  }

  const getInitiator = (log: GetMissionLogResponse) => {
    if (log?.details?.event_log && typeof log.details.event_log === "object" && "initiator" in log.details.event_log) {
      return (log.details.event_log as { initiator: string }).initiator
    }
    return stringOrMDash(log?.details?.user as string | null | undefined)
  }

  const getEvent = (log: GetMissionLogResponse) => {
    if (log?.details?.event_log && typeof log.details.event_log === "object" && "event" in log.details.event_log) {
      return (log.details.event_log as { event: string }).event
    }
    return stringOrMDash(log?.description)
  }

  const getStatus = (log: GetMissionLogResponse) => {
    const successIcon = <CheckCircleOutline className="status-icon success-icon" aria-label="green check" />
    const criticalIcon = <HighlightOff className="status-icon critical-icon" aria-label="red x" />
    const eventLog = log?.details?.event_log as EventLog | null

    const name = eventLog?.status?.name
    const icon =
      eventLog?.status?.icon === "successIcon"
        ? successIcon
        : eventLog?.status?.icon === "criticalIcon"
        ? criticalIcon
        : null

    return (
      <div className="status-cell-content">
        {icon}
        <span>{stringOrMDash(name)}</span>
      </div>
    )
  }

  const getType = (log: GetMissionLogResponse) => {
    const type =
      log?.details?.event_log && typeof log.details.event_log === "object" && "type" in log.details.event_log
        ? (log.details.event_log as { type: string }).type
        : null
    return stringOrMDash(type)
  }

  const getDate = (log: GetMissionLogResponse) => {
    return log?.creation_ts ? getFormattedTime(log.creation_ts, undefined, "MM/DD/YY") : "\u2014"
  }

  const getTime = (log: GetMissionLogResponse) => {
    return log?.creation_ts ? getFormattedTime(log.creation_ts, undefined, "HH:mm:ss z") : "\u2014"
  }

  const getTableRow = (log: GetMissionLogResponse, key: string) => {
    const isSelected = selectedLog?.log_uuid === log.log_uuid
    const isBlinking = isSelected && blink

    let event_log = log.details?.event_log as EventLog | null
    if (log.category === "VEHICLE_CONNECTIVITY") {
      if (log.level === "OPERATIONAL") {
        event_log = {
          initiator: "SDT",
          event: "Vehicle Connectivity",
          type: "Connectivity Regained",
        }
      }
      if (log.level === "WARN") {
        event_log = {
          initiator: "SDT",
          event: "Vehicle Connectivity",
          type: "Connectivity Lost",
        }
      }
      log.details = { event_log: event_log }
    }

    const hasDetails = event_log && event_log.details

    return (
      <TableRow
        key={key}
        ref={isSelected ? selectedLogRef : null}
        selected={isSelected}
        className={`${isBlinking ? "blink" : ""}`}
        data-testid={`event-log-row-${key}`}
      >
        <TableCell key={`${key}-0`}>{getInitiator(log)}</TableCell>
        <TableCell key={`${key}-1`}>{getEvent(log)}</TableCell>
        <TableCell key={`${key}-2`} className="status-cell">
          {getStatus(log)}
        </TableCell>
        <TableCell key={`${key}-3`}>{getType(log)}</TableCell>
        <TableCell key={`${key}-4`}>{getDate(log)}</TableCell>
        <TableCell key={`${key}-5`}>{getTime(log)}</TableCell>
        <TableCell key={`${key}-6`}>
          {hasDetails && (
            <IconButton
              size="small"
              aria-label="expand row"
              aria-controls="event-details"
              aria-haspopup="true"
              style={{ padding: 0 }}
              data-testid={`event-log-row-${key}-expand`}
              onClick={() => {
                const newSet = new Set(eventDetailsOpen)
                if (eventDetailsOpen.has(key)) {
                  newSet.delete(key)
                } else {
                  newSet.add(key)
                }
                setEventDetailsOpen(newSet)
              }}
            >
              {eventDetailsOpen.has(key) ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    )
  }

  const getDetailsRow = (details: EventLogDetails, key: string) => {
    const isOpen = eventDetailsOpen.has(key)
    const rowKey = `event-details-row-${key}`
    return (
      <TableRow className="event-details-row" key={rowKey}>
        <TableCell colSpan={7} style={{ borderBottom: isOpen ? "1px solid #d7d7d7" : "none" }}>
          <Collapse in={isOpen} data-testid={`${rowKey}-collapse`}>
            {EventDetails({ details: details })}
          </Collapse>
        </TableCell>
      </TableRow>
    )
  }

  const getTableRows = (logs: GetMissionLogResponse[]) => {
    const rows: ReactNode[] = []
    logs.forEach((log, index) => {
      const event_log = log.details?.event_log as EventLog | null
      const key = log.log_uuid ? log.log_uuid : String(index)
      rows.push(getTableRow(log, key))
      if (event_log && event_log.details) {
        rows.push(getDetailsRow(event_log.details, key))
      }
    })
    return rows
  }

  const allLogs = data?.pages.flatMap((page) => page.body) ?? []
  const filteredLogs = filterLogs(allLogs)

  return (
    <Box className="event-log" data-testid="event-log">
      {isLoading ? (
        <LoadScreen />
      ) : !isLoading && filteredLogs.length === 0 ? (
        <Typography variant="body1">No Logs Found for Mission</Typography>
      ) : (
        <TableContainer className="event-log-container">
          <InfiniteScroll
            dataLength={filteredLogs.length}
            next={handleFetchMore}
            hasMore={hasNextPage}
            loader={
              <div className="event-log-loader">
                <CircularProgress size={20} />
              </div>
            }
            scrollThreshold={0.9}
            endMessage={
              <div className="event-log-end-message">
                <Typography variant="caption">All logs loaded</Typography>
              </div>
            }
            height={730}
          >
            <Table className="event-log-table">
              <TableHead>
                <TableRow>
                  {tableHeaders.map((header, index) => (
                    <TableCell key={index}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody data-testid="event-log-table-body">{getTableRows(filteredLogs)}</TableBody>
            </Table>
          </InfiniteScroll>
        </TableContainer>
      )}
    </Box>
  )
}

export default EventLog
