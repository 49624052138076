import useGetTimezoneFromCoordinates from "@/framework/query/useGetTimezoneFromCoordinates"
import useMissionQuery from "@/framework/query/useMissionQuery"
import useVehicleStore from "@/framework/store/useVehicleStore"
import { useEffect, useRef, useState } from "react"

const SDTLocalTime = () => {
  const mission = useMissionQuery().data?.missions?.[0]
  const vehicle = useVehicleStore((state) => state.vehicles.entities[mission?.vehicle_id ?? ""])

  const coordinates = vehicle?.data?.coordinates

  const query = useGetTimezoneFromCoordinates(coordinates?.latitude, coordinates?.longitude)

  const [formattedTime, setFormattedTime] = useState<string>("--")
  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  const timezone = query.data?.[0]

  useEffect(() => {
    if (!timezone) {
      clearInterval(intervalRef.current as NodeJS.Timeout)
      intervalRef.current = null
    } else {
      intervalRef.current = setInterval(() => {
        setFormattedTime(
          new Intl.DateTimeFormat("en-US", {
            timeZone: timezone,
            hour: "numeric",
            minute: "numeric",
            hour12: false,
            timeZoneName: "short",
          }).format(new Date())
        )
      }, 1000)
    }

    return () => {
      clearInterval(intervalRef.current as NodeJS.Timeout)
    }
  }, [timezone])
  return <span className="local-time">SDT Local Time: {formattedTime}</span>
}

export default SDTLocalTime
