import { create } from "zustand"
import { devtools } from "zustand/middleware"

interface Filter {
  name: string
  type: string
  checked: boolean
}

interface MissionFilterStore {
  hubs: Filter[]
  customers: Filter[]
  setHubs: (hubs: Filter[]) => void
  setCustomers: (customers: Filter[]) => void
  clearFilters: () => void
}

const hubNames = ["Albuquerque, NM", "Blacksburg, VA", "Austin, TX", "Portland, OR", "Uvalde, TX"]
const customerNames = ["TORC"]

const useMissionFilterStore = create<MissionFilterStore>()(
  devtools(
    (set) => ({
      hubs: hubNames.map((value) => {
        return { name: value, type: "hub", checked: false }
      }),
      customers: customerNames.map((value) => {
        return { name: value, type: "customer", checked: false }
      }),
      setHubs: (hubs) => set({ hubs }),
      setCustomers: (customers) => set({ customers }),
      clearFilters: () => {
        set((state) => ({
          hubs: state.hubs.map((hub) => {
            return { ...hub, checked: false }
          }),
          customers: state.customers.map((customer) => {
            return { ...customer, checked: false }
          }),
        }))
      },
    }),
    { name: "Mission Filter Store" }
  )
)

export default useMissionFilterStore
export type { Filter }
