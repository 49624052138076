import { GetMissionLogResponse } from "@torc-robotics/mm-missions-client"
import { Auth } from "aws-amplify"

export interface MissionLogsResponse {
  body: Array<GetMissionLogResponse>
  headers: Headers
}

// create a custom fetch to the /logs endpoint because we need headers
export const getMissionLogsV2 = async ({
  missionUuid,
  count,
  cursor,
  sort,
}: {
  missionUuid: string
  count?: number
  cursor?: string
  sort?: string
}): Promise<MissionLogsResponse> => {
  const token = await Auth.currentSession().then((res) => res.getIdToken().getJwtToken())
  const path = `v2/missions/${missionUuid}/logs`
  const url = new URL(path, String(import.meta.env.VITE_MISSIONS_API_URL))

  // Build query params object with only defined values
  const params: Record<string, string> = {}
  if (count !== undefined) params.count = count.toString()
  if (cursor !== undefined) params.cursor = cursor
  if (sort !== undefined) params.sort = sort

  // Add query params to URL if any exist
  if (Object.keys(params).length > 0) {
    url.search = new URLSearchParams(params).toString()
  }

  const response = await fetch(url.toString(), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
  })

  return {
    body: (await response.json()) as Array<GetMissionLogResponse>,
    headers: response.headers,
  }
}

export default getMissionLogsV2
