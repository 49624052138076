import Assist from "./Assist"
import EventLog from "./EventLog"
import MissionDetails from "./MissionDetails"
import config from "../../config"

interface TabSettings {
  label: string
  selectedTab: string
  element: React.ReactNode
  disable: boolean
}

enum AssistTab {
  ASSIST = "assist",
  MISSION_DETAILS = "missionDetails",
  EVENT_LOG = "eventLog",
}

// Temporary tab disable
const env = config.VITE_ENV || config.VITE_DD_ENV
const disable = !["localhost", "development", "staging"].includes(env)

const defaultTab = AssistTab.ASSIST

const grabHandleTabs = ["assist"]

const tabSettings: TabSettings[] = [
  {
    label: "Assist",
    selectedTab: AssistTab.ASSIST,
    element: <Assist />,
    disable: false,
  },
  {
    label: "Mission Details",
    selectedTab: AssistTab.MISSION_DETAILS,
    element: <MissionDetails />,
    disable: false,
  },
  {
    label: "Event Log",
    selectedTab: AssistTab.EVENT_LOG,
    element: <EventLog />,
    disable: disable,
  },
]

export { tabSettings, defaultTab, grabHandleTabs, AssistTab }
