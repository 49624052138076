import "./scss/missionDetails.scss"
import MissionSchedule from "@/features/mission-details/MissionSchedule"
import MissionLoadDetails from "@/features/mission-details/MissionLoadDetails"
import MissionVehicleProgressInfo from "@/features/mission-details/MissionVehicleProgressInfo"
import MissionMap from "@/features/mission-details/MissionMap"

const MissionDetails = () => {
  return (
    <div className="mission-details-container" data-testid="mission-details-container">
      <MissionVehicleProgressInfo />
      <MissionLoadDetails />
      <MissionSchedule />
      <MissionMap />
    </div>
  )
}

export default MissionDetails
