import { useQuery } from "@tanstack/react-query"
import execute from "../api/CallsAPIGraphQL"
import { GetActiveConferencesDocument } from "@torc-robotics/vda-calls-api/graphql/document"

const useGetConferenceCalls = () => {
  const query = useQuery({
    queryKey: ["getConferenceCalls"],
    queryFn: /* istanbul ignore next */ async () => await execute(GetActiveConferencesDocument),
    refetchOnWindowFocus: "always",
    refetchInterval: false,
    staleTime: Infinity,
  })

  return query
}

export default useGetConferenceCalls
