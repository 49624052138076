import { useState, ChangeEvent } from "react"
import { Button, Modal, Typography, Box } from "@mui/material"
import "./CreateCallLogModal.scss"
import { getLogger } from "@/framework/contexts/DataDog"
import useCreateCallNoteMutation from "@/framework/query/useCreateCallNoteMutation"

const logger = getLogger()

interface CreateCallLogModalProps {
  conferenceUuid: string
  isOpen: boolean
  onCancel: () => void
  onComplete: () => void
}

const CreateCallLogModal = ({ conferenceUuid, isOpen, onCancel, onComplete }: CreateCallLogModalProps) => {
  const [logBody, setLogBody] = useState("")
  const [error, setError] = useState("")
  const createCallLogMutation = useCreateCallNoteMutation()

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setLogBody(event.target.value)
  }

  const clear = () => {
    setLogBody("")
    setError("")
  }

  const cancelCallLog = () => {
    clear()
    onCancel()
  }

  const handleSubmit = () => {
    if (logBody && logBody.length > 0) {
      const cleanedLogBody = logBody.replace(/\n/g, "\\n")
      logger.info(`Submitting call log: ${cleanedLogBody}`)
      createCallLogMutation.mutate({ body: cleanedLogBody, conferenceUuid: conferenceUuid })
      clear()
      onComplete()
    } else {
      logger.debug("No additional details to log")
      setError("*Please enter a note before submitting")
      return
    }
  }

  return (
    <Modal open={isOpen} onClose={onCancel} className="create-note-modal">
      <Box className="create-note-modal_box">
        <Typography className="create-note-modal_box-title">Call Notes</Typography>
        <Typography className="create-note-modal_box-description">Please add call notes.</Typography>
        <Typography className="create-note-modal_box-error">{error}</Typography>
        <textarea
          value={logBody}
          onChange={handleChange}
          className="create-note-modal_box-body"
          rows="4"
          cols="50"
        ></textarea>
        <Button className="create-note-modal_box-cancel" onClick={cancelCallLog}>
          Cancel
        </Button>
        <Button className="create-note-modal_box-submit" onClick={handleSubmit}>
          Submit & Complete
        </Button>
      </Box>
    </Modal>
  )
}

export default CreateCallLogModal
