import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { defaultTab } from "@/components/remote-assistance/tabHelper"

interface ActiveSessionTabStore {
  currentTab: string
  setCurrentTab: (tab: string) => void
}

const useActiveSessionTabStore = create<ActiveSessionTabStore>()(
  devtools(
    (set) => ({
      currentTab: defaultTab,
      setCurrentTab: (tab) =>
        set({
          currentTab: tab,
        }),
    }),
    { name: "Active Session Tab Store" }
  )
)

export default useActiveSessionTabStore
