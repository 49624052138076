import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { getLogger } from "@/framework/contexts/DataDog"

const logger = getLogger()

interface MetricData {
  startTime: number | null
  endTime?: number
  latency?: number
}

interface MetricCollection {
  [metricName: string]: MetricData | undefined
}

interface PerformanceStore {
  metrics: MetricCollection
  startTiming: (metricName: string) => void
  endTiming: (metricName: string) => number | void
}

const usePerformanceStore = create<PerformanceStore>()(
  devtools((set, get) => ({
    metrics: {},
    startTiming: (metricName) => {
      const startTime = performance.now()
      set((state) => ({
        metrics: {
          ...state.metrics,
          [metricName]: {
            startTime: startTime,
          },
        },
      }))
    },
    endTiming: (metricName) => {
      const componentMetrics = get()?.metrics[metricName]

      if (componentMetrics?.startTime == null) return

      const endTime = performance.now()
      const latency = endTime - componentMetrics.startTime

      logger.info(`latency calculated ${metricName}: ${latency} ms`, {
        event_type: "render_latency",
        metric_name: metricName,
        start_time_ms: componentMetrics.startTime,
        end_time_ms: endTime,
        latency_ms: latency,
      })

      set((state) => ({
        metrics: {
          ...state.metrics,
          [metricName]: {
            startTime: null,
            endTime: endTime,
            latency: latency,
          },
        },
      }))

      return latency
    },
  }))
)

export default usePerformanceStore
