import { useState } from "react"
import "./scss/monitorQueueFilter.scss"
import { Button, Checkbox, Chip, Collapse, List, ListItemButton, ListItemText, Tabs } from "@mui/material"
import ClearIcon from "@mui/icons-material/Clear"
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import useMissionFilterStore, { Filter } from "@/framework/store/useMissionFilterStore"

interface DialogProps {
  closeDialog: (event: React.MouseEvent<HTMLButtonElement>) => void
}

interface FilterListProps {
  name: string
  filters: Filter[]
  filterStateUpdate: (filters: Filter[]) => void
}

interface FilterItem {
  name: string
  checked: boolean
  type: string
}

const renderRow = (item: FilterItem, index: number, toggleFilter: { (index: number): void }): JSX.Element => {
  return (
    <ListItemButton key={`${item.name}-${index}`} component="li" dense={true} onClick={() => toggleFilter(index)}>
      <Checkbox
        edge="start"
        checked={item.checked}
        inputProps={{ "aria-labelledby": `${item.name}-${index}` }}
        sx={{
          padding: "0 9px",
          "&.Mui-checked": {
            color: "#02a7f0",
          },
        }}
      />
      <ListItemText id={`${item.name}-${index}`} primary={`${item.name}`} />
    </ListItemButton>
  )
}

const renderList = (
  type: string,
  items: FilterItem[],
  toggleFilter: (index: number, filter: FilterItem[]) => void,
  expanded: boolean,
  handleToggle: (toggled: boolean) => void
): JSX.Element[] => {
  let elements = items.map((item, index) => {
    return renderRow(item, index, (index) => toggleFilter(index, items))
  })
  const expanderButton = (
    <ListItemButton
      data-testid="expander-button"
      key="expander-button"
      className="expander-button"
      onClick={() => handleToggle(!expanded)}
      component="button"
      sx={{
        textDecoration: "underline",
        textTransform: "capitalize",
        color: "#02a7f0",
        fontSize: ".875rem",
        width: "100%",
      }}
    >{`${expanded ? "Fewer" : "More"} ${type}`}</ListItemButton>
  )
  if (!expanded && items.length > 4) {
    elements = elements.slice(0, 4)
    elements.push(expanderButton)
  }
  if (expanded) elements.push(expanderButton)
  return elements
}

const FilterList = ({ name, filters, filterStateUpdate }: FilterListProps) => {
  const [listOpen, setListOpen] = useState(true)
  const [listExpanded, setListExpanded] = useState(false)

  const handleToggleFilter = (index: number, filter: FilterItem[], setFilter: (filters: Filter[]) => void) => {
    const toggledFilter = filter.map((value, i) => {
      if (i === index) {
        return { name: value.name, checked: !value.checked, type: value.type }
      } else {
        return value
      }
    })
    setFilter(toggledFilter)
  }

  const filtersDisplay = renderList(
    name + "s",
    filters,
    (index, filter) => handleToggleFilter(index, filter, filterStateUpdate),
    listExpanded,
    (toggled: boolean) => setListExpanded(toggled)
  )

  return (
    <div>
      <ListItemButton
        onClick={() => setListOpen(!listOpen)}
        component="h4"
        aria-label={`${name}s`}
        sx={{ textTransform: "capitalize" }}
      >
        {name}
        {listOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItemButton>
      <Collapse in={listOpen} role="collapse">
        <List component={"ul"} aria-label={`${name}-filters-list`}>
          {filtersDisplay}
        </List>
        <hr />
      </Collapse>
    </div>
  )
}

const FilterDialog = ({ closeDialog }: DialogProps) => {
  const { hubs, customers, setHubs, setCustomers, clearFilters } = useMissionFilterStore()

  return (
    <div id="monitor-queue-filter-dialog" className="filter-dialog">
      <div className="filter-header">
        <span>Filter</span>
        <button id="filter-close" className="filter-close" aria-label="close" onClick={closeDialog}></button>
      </div>
      <hr />
      <div className="filter-body">
        <FilterList name="hub" filters={hubs} filterStateUpdate={setHubs} />
        <FilterList name="customer" filters={customers} filterStateUpdate={setCustomers} />
      </div>
      <div className="filter-footer">
        <Button
          variant="outlined"
          onClick={clearFilters}
          sx={{
            color: "#0066b0",
            border: "2px solid #0083d4",
            borderRadius: "5px",
            "&:hover": { backgroundColor: "#edfaff", border: "2px solid #0083d4" },
            "&:active": { backgroundColor: "#dbf1fe", border: "2px solid #0083d4" },
          }}
        >
          Clear Filter
        </Button>
        <Button
          variant="contained"
          onClick={closeDialog}
          sx={{
            backgroundColor: "#0083d4",
            borderRadius: "5px",
            "&:hover": { backgroundColor: "#0066b0" },
          }}
        >
          Apply Filter
        </Button>
      </div>
    </div>
  )
}

const getChips = (filters: Filter[], updateFilters: (filters: Filter[]) => void) => {
  return filters.map((value, index, array) => {
    if (value.checked === true) {
      return (
        <Chip
          avatar={<span>{`${value.type}:`}</span>}
          label={value.name}
          variant="outlined"
          key={`${value.type}-${index}`}
          component={"li"}
          onDelete={() => {
            array[index].checked = false
            updateFilters(array)
          }}
          deleteIcon={<ClearIcon />}
        />
      )
    }
  })
}

const MonitorQueueFilter = () => {
  const [dialogOpen, setdialogOpen] = useState(false)
  const { hubs, customers, setHubs, setCustomers } = useMissionFilterStore()

  const hubChips = getChips(hubs, setHubs)
  const customerChips = getChips(customers, setCustomers)

  const activeFilters =
    hubs.filter((hub) => hub.checked).length + customers.filter((customer) => customer.checked).length

  return (
    <div className="monitor-queue-filter">
      <Tabs className="filters-active" aria-label="active-filters" component="ul" variant="scrollable">
        {hubChips}
        {customerChips}
      </Tabs>
      <button className="filter-open" onClick={() => setdialogOpen(true)}>
        <FilterAltOutlinedIcon />
        Filter {activeFilters > 0 && `(${activeFilters})`}
      </button>
      {dialogOpen && <FilterDialog closeDialog={() => setdialogOpen(false)} />}
    </div>
  )
}

export default MonitorQueueFilter
export { FilterDialog, FilterList }
