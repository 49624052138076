import { Button, Typography } from "@mui/material"
import "./scss/endSession.scss"
import { useCallback, useState } from "react"
import useEndSessionMutation from "@/framework/query/useEndSessionMutation"
import useCurrentSession from "@/framework/hooks/useCurrentSession"
import { RemotePage, usePage } from "@/framework/contexts/Page"
import usePerformanceStore from "@/framework/store/usePerformanceStore"
import CreateMissionLogModal from "../create-note/CreateMissionLogModal"

const EndSessionButton = () => {
  const session = useCurrentSession()
  const endSessionMutation = useEndSessionMutation()
  const page = usePage()
  const performance = usePerformanceStore()
  const [modalOpen, setModalOpen] = useState(false)

  const endSessionHandler = useCallback(() => {
    performance.startTiming("endSessionToDashboard")

    if (session) {
      endSessionMutation.mutate()
    }
    page.setActive(RemotePage.Dashboard)
    return
  }, [session?.sessionId])

  const completeMissionLogModal = () => {
    setModalOpen(false)
    endSessionHandler()
  }

  const cancelMissionLogModal = () => {
    setModalOpen(false)
  }

  const openMissionLogModal = () => {
    setModalOpen(true)
  }

  return (
    <>
      <Button className="end-session-button" data-testid="end-session-button" onClick={openMissionLogModal}>
        <Typography variant="h7">Resolve Assistance</Typography>
      </Button>
      <CreateMissionLogModal
        isOpen={modalOpen}
        onCancel={cancelMissionLogModal}
        onComplete={completeMissionLogModal}
      ></CreateMissionLogModal>
    </>
  )
}

export default EndSessionButton
