import { Amplify } from "aws-amplify"
import { UserProvider } from "@torc-robotics/mcli-mfui-auth"
import awsExports from "./aws-config"
import App from "./components/app/App"
import { DatadogContextProvider } from "./framework/contexts/DataDog"
import { PageContextProvider } from "./framework/contexts/Page"
Amplify.configure(awsExports)

export default function Root({ datadogRum, datadogLogs }) {
  return (
    <UserProvider>
      <DatadogContextProvider datadogRumClient={datadogRum} datadogLogsClient={datadogLogs}>
        <PageContextProvider>
          <App />
        </PageContextProvider>
      </DatadogContextProvider>
    </UserProvider>
  )
}
