/* istanbul ignore file */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import useRerouteHandler from "../hooks/useRerouteHandler"
import { GeoJsonLayer } from "@deck.gl/layers/typed"
import palette from "@/assets/scss/variables.module.scss"
import { hexToRgb } from "../utils/Utils"
import useMapStore, { MapType } from "../store/useMapStore"
import { useEffect } from "react"
import { getLogger } from "../../framework/contexts/DataDog"

const logger = getLogger()

const { slate100, slate400, success400, success100 } = palette

const useRerouteLayers = () => {
  const { routeAdvisorQuery, routeAdvisorRoutesQuery, isRerouting } = useRerouteHandler()
  const setRoute = useMapStore((state) => state[MapType.REMOTE_VIZ].setSelectedRoute)
  const selectedRoute = useMapStore((state) => state[MapType.REMOTE_VIZ].selectedRoute)
  const setHoverRoute = useMapStore((state) => state[MapType.REMOTE_VIZ].setHoverRoute)
  const hoverRoute = useMapStore((state) => state[MapType.REMOTE_VIZ].hoverRoute)

  const geoJsonRoutesLayer = routeAdvisorRoutesQuery
    // @ts-expect-error deckgl types are not up to date
    .reduce((acc, route, idx) => {
      // return a stroke layer and a fill layer for each route
      return [
        ...acc,
        ["stroke", "fill"].map((type) => {
          return new GeoJsonLayer({
            id: routeAdvisorQuery.data.routes[idx].route_id + (type === "stroke" ? "-stroke" : ""),
            onClick: /* istanbul ignore next */ ({ object }) =>
              setRoute({
                routeId: object?.properties?.route_id as string,
                estimated_time_minutes: routeAdvisorQuery.data.routes[idx].summary_info.estimated_time_minutes,
                length_miles: routeAdvisorQuery.data.routes[idx].summary_info.length_miles,
              }),
            onHover: /* istanbul ignore next */ ({ object, coordinate }) =>
              setHoverRoute(
                object?.properties?.route_id
                  ? {
                      routeId: object?.properties?.route_id as string,
                      coordinate: coordinate as [number, number],
                      summary_info: routeAdvisorQuery.data.routes[idx].summary_info as {
                        estimated_time_minutes: number
                        length_miles: number
                      },
                    }
                  : null
              ),
            data: {
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates:
                  // route?.data?.latlons.map((latlon: { lon: number; lat: number }) => [latlon.lon, latlon.lat]) ?? [],
                  route?.data?.latlons.reduce((acc: number[][], latlon) => {
                    if (
                      latlon.lat === undefined ||
                      latlon.lon === undefined ||
                      // check if latlon values are numbers and within valid range
                      isNaN(latlon.lat) ||
                      isNaN(latlon.lon) ||
                      latlon.lat > 90 ||
                      latlon.lat < -90 ||
                      latlon.lon > 180 ||
                      latlon.lon < -180
                    ) {
                      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                      logger.warn(`Invalid latlon ${latlon}, all latlons: ${route.data?.latlons}`)
                      return acc
                    }
                    return [...acc, [latlon.lon, latlon.lat]]
                  }, []) ?? [],
              },
              properties: {
                route_id: routeAdvisorQuery.data.routes[idx].route_id,
              },
            },
            stroked: false,
            filled: false,
            pointType: "circle+text",
            pickable: type === "fill",
            autoHighlight: type === "fill",
            getLineColor: (data) =>
              data?.properties?.route_id === selectedRoute?.routeId
                ? hexToRgb(type === "stroke" ? success100 : success400)
                : hexToRgb(type === "stroke" ? (hoverRoute ? slate100 : slate400) : slate100),
            highlightColor: ({ object }) =>
              object?.properties?.route_id === selectedRoute?.routeId ? hexToRgb(success400) : hexToRgb(slate400),
            getLineWidth: type === "stroke" ? 8 : 5,
            lineCapRounded: true,
            lineJointRounded: true,
            routeCallOut: {
              routeId: routeAdvisorQuery.data.routes[idx].route_id,
              // very basic way to grab one point from a list of lat / lon
              coordinate: route?.data?.latlons[Math.floor(route?.data?.latlons.length / 2)],
              summary_info: routeAdvisorQuery.data.routes[idx].summary_info as {
                estimated_time_minutes: number
                length_miles: number
              },
            },
          })
        }),
      ]
    }, [])
    // @ts-expect-error deckgl types are not up to date
    .sort((a, b) => {
      if (hoverRoute) {
        if (a[1].id === hoverRoute.routeId) {
          return 1
        }
        if (b[1].id === hoverRoute.routeId) {
          return -1
        }
      }
      if (selectedRoute) {
        if (a[1].id === selectedRoute.routeId) {
          return 1
        }
        if (b[1].id === selectedRoute.routeId) {
          return -1
        }
      }
      return 0
    }) as GeoJsonLayer[]

  useEffect(() => {
    /* istanbul ignore next */
    if (!isRerouting) {
      setHoverRoute(null)
      setRoute(null)
    }
  }, [isRerouting])

  return geoJsonRoutesLayer
}

export default useRerouteLayers
