import { MrmStatus, RemoteAssistanceEvent } from "@torc-robotics/vda-remote-assistance-api/graphql/document"
import useGetRemoteAssistanceEventsQuery from "../query/useGetRemoteAssistanceEventsQuery"
import useGetLoadId from "./useGetLoadId"

/*
 * This hook is used to get the latest MRM Status event for a mission
 */
const useLatestMrmEvent = () => {
  const { data: raEvents } = useGetRemoteAssistanceEventsQuery()
  const loadId = useGetLoadId() ?? ""

  const mrmStatusEvent = raEvents?.data?.getEvents?.find(
    (event) => event.eventType === "MRM_STATUS" && event.loadId === loadId
  )

  return mrmStatusEvent as RemoteAssistanceEvent & { eventDetails: MrmStatus }
}

export default useLatestMrmEvent
