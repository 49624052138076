import {
  RemoteAssistanceEvent,
  RemoteAssistanceEventType,
} from "@torc-robotics/vda-remote-assistance-api/graphql/document"
import moment from "moment-timezone"
import { getLogger } from "../contexts/DataDog"

const logger = getLogger()

/**
 * ETA time remaining in HH:mm format
 * @param {string | null | undefined} futureDateString
 * @returns String of time remaining in format "HH hr mm min"
 */
export function formatTimeLeft(futureDateString: string | null | undefined): string {
  if (futureDateString == null) {
    return "N/A"
  }

  const seconds = parseFloat(futureDateString)

  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const formattedHours = hours.toString().padStart(2, "0")
  const formattedMinutes = minutes.toString().padStart(2, "0")
  return `${formattedHours} hr ${formattedMinutes} min`
}

/**
 * Calculated time remaining in HH:mm format
 * @param {number | null | undefined} remainingSeconds
 * @returns String of time with remaining seconds in format "HH:mm"
 */
export function calculateTimeRemaining(remainingSeconds: number | null | undefined): string {
  if (remainingSeconds == null) {
    return "---"
  }

  const hours = Math.floor(remainingSeconds / 3600)
  const minutes = Math.floor((remainingSeconds % 3600) / 60)
  const formattedHours = hours.toString().padStart(2, "0")
  const formattedMinutes = minutes.toString().padStart(2, "0")

  return `${formattedHours}:${formattedMinutes}`
}

/**
 * Calculated ETA time in HH:mm format
 * @param {number | null | undefined} remainingSeconds
 * @returns String of time with remaining seconds in format "HH:mm"
 */
export function formatCalculatedETA(remainingSeconds: number | null | undefined): string {
  if (remainingSeconds == null) {
    return "---"
  }

  const futureDate = moment.utc().add(remainingSeconds, "seconds") // create moment object in UTC time zone

  return futureDate.local().format("HH:mm")
}

/**
 * @param {string | number} date The date to format, in ISO 8601 format, epoch time also supported.
 * @param {string} [timezone] The specified timezone to default to.
 * @param {string} [format] The specified moment format.
 * @param {string} [invalidValueDefault] Localized value to show when date is invalid - default is null.
 * @returns The formatted time.
 */
export function getFormattedTime(
  date: string | number,
  timezone: string = moment.tz.guess(),
  format = "HH:mm:ss a",
  invalidValueDefault = "---"
): string {
  let d: moment.Moment

  if (typeof date === "string") {
    d = moment(date).tz(timezone)
  } else {
    d = moment.tz(date, date < 9999999999 ? "X" : "x", timezone) // convert to Moment object
  }

  if (date && d.isValid()) {
    return d.format(format)
  } else {
    return invalidValueDefault
  }
}

/**
 * Converts a hexadecimal color code to an RGB color array.
 *
 * @param hex - The hexadecimal color code to convert.
 * @returns An array containing the RGB values of the converted color.
 */
export const hexToRgb = (hex: string): number[] =>
  hex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (_, r: string, g: string, b: string) => "#" + r + r + g + g + b + b)
    .substring(1)
    .match(/.{2}/g)
    ?.map((x) => parseInt(x, 16)) ?? [0, 0, 0]

/**
 * Formats the user name by converting it from the format of <first> <last> to <first initial>. <last>.
 * If the user name does not contain both first and last name, it returns the original user name.
 *
 * @param userName - The user name to be formatted.
 * @returns The formatted user name.
 */
export const formatUserName = (userName: string) => {
  // The user name is in the format of <first> <last>
  // We want <first initial>. <last>

  const names = userName.split(" ")
  if (names.length < 2) {
    return userName
  }

  return `${names[0].charAt(0)}. ${names[names.length - 1]}`
}

/**
 * Formats the time since epoch in seconds to a human-readable format of HH:MM:SS.
 *
 * @param seconds - The time since epoch in seconds.
 * @returns The formatted time since epoch.
 */
export const formatTimeSinceEpoch = (seconds: number) => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const secs = seconds % 60

  return [hours, minutes, secs].map((v) => v.toString().padStart(2, "0")).join(":")
}

/**
 * Formats the latitude and longitude to a human-readable format.
 *
 * @param lat - The latitude.
 * @param lon - The longitude.
 * @returns The formatted latitude and longitude.
 */
export function formatLatLon(lat: number, lon: number, decimals = 3): string {
  const latDir = lat >= 0 ? "N" : "S"
  const lonDir = lon >= 0 ? "E" : "W"

  const absLat = Math.abs(lat).toFixed(decimals)
  const absLon = Math.abs(lon).toFixed(decimals)

  return `${absLon}' ${lonDir} ${absLat}' ${latDir}`
}

/**
 * Formats the assistance request display based on the event type.
 *
 * @param eventType - The event type to format.
 * @returns The formatted assistance request display.
 */
export function formatAssistanceRequestDisplay(eventType: string) {
  switch (
    eventType // eslint-disable-line default-case
  ) {
    case "MRM_STATUS":
      return "MRM"
    case "DETECTED_SCENARIO":
      return "SCENARIO DETECTED"
    default:
      return "UNKNOWN"
  }
}

/**
 * Formats the confidence level to a human-readable format.
 *
 * @param confidence - The confidence level.
 * @returns The formatted confidence level.
 */
export function confidenceToPercentage(confidence: number): number {
  // round to nearest whole number
  return Math.round(confidence * 100)
}

export async function getRaTraceId(
  vehicle_id: string,
  load_id: string,
  tenant: string,
  timestamp: number
): Promise<string> {
  const message = vehicle_id + load_id + tenant + String(timestamp)
  const msgUint8 = new TextEncoder().encode(message)
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("")
  return hashHex
}

export function getTimeSince(time: Date): number {
  const now = new Date()
  return (now.getTime() - time.getTime()) / 1000
}

export function normalizeTimestamp(timestamp: number, desiredLength: number): number {
  if (timestamp < 0) throw "negative timestamp not allowed"
  if (desiredLength < 0) throw "negative desired length not allowed"
  if (timestamp === 0 || desiredLength === 0) return 0
  const digits = Math.floor(Math.log10(timestamp)) + 1
  return timestamp * 10 ** (desiredLength - digits)
}

export function logMetrics(event: RemoteAssistanceEvent, ra_trace_id: string, eventAction: string): void {
  if (event.receivedTs) {
    const received = normalizeTimestamp(event.receivedTs, 13)
    const created = normalizeTimestamp(event.creationTs, 13)
    const received_time = new Date(received)
    const creation_time = new Date(created)

    logger.info(`remote assistance event ${eventAction} on frontend`, {
      ra_event_action: `event_${eventAction}`,
      ra_event: event,
      ra_system_latency_to_frontend: getTimeSince(received_time),
      ra_latency_from_vehicle_to_frontend: getTimeSince(creation_time),
      ra_event_creation_timestamp: created,
      ra_event_received_timestamp: received,
      ra_trace_id: ra_trace_id,
    })
  }
}

/**
 * Formats the phone number to a human-readable format if it is 11 digits.
 *
 * @param phone - The phone number to format.
 * @returns The formatted phone number.
 */
export function formatPhoneNumber(phone: string): string {
  // Remove non-digit characters
  const cleaned = phone.replace(/\D/g, "")
  if (cleaned.length !== 11) {
    logger.error("Invalid phone number format")
    return phone
  }

  // first digit is country code
  const area = cleaned.slice(1, 4)
  const prefix = cleaned.slice(4, 7)
  const line = cleaned.slice(7)
  return `(${area}) ${prefix}-${line}`
}

export function getReasonString(eventType: RemoteAssistanceEventType | null): string {
  let reasonString = "NONE"
  if (eventType) {
    switch (eventType) {
      case RemoteAssistanceEventType.DetectedScenario:
        reasonString = "SCENARIO_DETECTION"
        break
      case RemoteAssistanceEventType.MrmStatus:
        reasonString = "MRM"
        break
    }
  }
  return reasonString
}
