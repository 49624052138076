import { useQuery } from "@tanstack/react-query"
import MissionsAPI from "../api/MissionsAPI"
import useMissionQuery from "./useMissionQuery"

const useGetMissionSelectedRoute = () => {
  const mission = useMissionQuery()
  const missionUuid = mission.data?.missions?.[0]?.uuid ?? ""

  const missionRoute = useQuery({
    queryKey: ["missionRoute"],
    queryFn: /* istanbul ignore next */ async () =>
      await MissionsAPI.v1.getMissionRoutes({
        missionUuid: missionUuid,
        selected: "true",
      }),
    enabled: mission.data?.missions?.[0]?.uuid !== undefined,
  })

  return missionRoute
}

export default useGetMissionSelectedRoute
