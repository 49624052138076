import { Box } from "@mui/material"
import RemoteVizMap from "./RemoteVizMap"

import "./scss/mapPanel.scss"
import DragHandle from "../drag-handle/DragHandle"

const MapPanel = () => {
  return (
    <>
      <Box id="map-panel-container" data-testid="map-panel-container" data-swapy-item="remote-map">
        <DragHandle />
        <RemoteVizMap />
      </Box>
    </>
  )
}

export default MapPanel
