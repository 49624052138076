import { Box, Typography } from "@mui/material"

const MapEngageStateDisplay = ({ adsState }) => {
  return (
    <Box className="engage-state-display" textAlign={"center"}>
      <Typography variant="subtitle2" data-testid={"engage-state"}>
        {adsState == 2 ? "ADS ENGAGED" : "ADS DISENGAGED"}
      </Typography>
    </Box>
  )
}

export default MapEngageStateDisplay
