import { Box } from "@mui/material"
import { mountRootParcel } from "single-spa"
import Parcel from "single-spa-react/parcel"
import * as RemoteCamPanel from "@viz/remotecam"
import "./scss/cameraPanel.scss"
import useVehicleDetailsQuery from "@/framework/query/useVehicleDetailsQuery"
import useCurrentSession from "@/framework/hooks/useCurrentSession"
import DragHandle from "../drag-handle/DragHandle"
import useGetElementDimensions from "@/framework/hooks/useGetElementDimensions"

const CameraPanel = () => {
  const { data: vehicle } = useVehicleDetailsQuery()
  const session = useCurrentSession()
  const { width, height } = useGetElementDimensions("camera-panel-container")

  return (
    <Box id="camera-panel-container" data-testid="camera-panel-container" data-swapy-item="remote-cam">
      <DragHandle />
      <div data-testid="camera-panel-container_view" className="camera-panel-container_view">
        <Parcel
          config={RemoteCamPanel}
          mountParcel={mountRootParcel}
          cameraId={"CAM1"}
          vehicle={vehicle}
          vehicleId={vehicle?.vehicle_id}
          sessionId={session?.sessionId}
          browserId={session?.browserId}
          containerWidth={width}
          containerHeight={height}
        />
      </div>
    </Box>
  )
}

export default CameraPanel
