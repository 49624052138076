import MissionsApi from "../api/MissionsAPI"
import { useInfiniteQuery } from "@tanstack/react-query"
import { MissionSearchResult, src__model__mission__Mission_Output as Mission } from "@torc-robotics/mm-missions-client"
import useVehiclesQuery from "./useVehiclesQuery"
import { type GetVehicleResponse as Vehicle } from "@torc-robotics/mm-vehicles-client"
import { getLogger } from "../../framework/contexts/DataDog"

const logger = getLogger()

interface GetMissionsParameters {
  filters: ((mission: Mission) => boolean)[]
  states: string[]
  pageSize: number
  refetchInterval: number
  enabled: boolean
}

const useGetMissionsQuery = (params: GetMissionsParameters) => {
  const { data, isLoading: vehiclesLoading } = useVehiclesQuery()
  const filteredVehicles = getFilteredVehicles(data)

  const filters = [missionVehicleIsGen3(filteredVehicles), ...params.filters]
  const queryKey = [params, filteredVehicles]

  const missionsQuery = useInfiniteQuery({
    getNextPageParam: (LastPage: MissionSearchResult) =>
      LastPage.last_key !== undefined ? LastPage.last_key : undefined,
    initialPageParam: 0,
    queryKey: queryKey,
    refetchInterval: params.refetchInterval,
    enabled: params.enabled && !vehiclesLoading,
    queryFn: async ({ pageParam }) =>
      await MissionsApi.v1
        .getMissions({
          active: true,
          maxIterate: 20000,
          searchType: "MISSIONS",
          pageSize: params.pageSize,
          states: params.states,
          ...(pageParam !== null && { startKey: JSON.stringify(pageParam) }),
        })
        .then(
          /* istanbul ignore next @preserve */
          (response) => {
            response.missions = response.missions?.filter((mission) => {
              let accepted = true
              filters.forEach((filter) => {
                if (!filter(mission)) accepted = false
              })
              return accepted
            })
            return response
          }
        ),
  })

  if (missionsQuery.isError) logger.error("missionsQuery.isError", {}, missionsQuery.error)
  if (missionsQuery.failureCount > 0) logger.error("missionsQuery.failureCount", {}, missionsQuery.failureReason)

  return missionsQuery
}

const getFilteredVehicles = (vehicles: Vehicle[] | undefined) => {
  if (vehicles === undefined) vehicles = []
  const vehicleIds = vehicles.filter((vehicle) => vehicle?.gen?.startsWith("3")).map((vehicle) => vehicle?.vehicle_id)
  return new Set(vehicleIds)
}

const missionVehicleIsGen3 = (filteredVehicles: Set<string>) => {
  return (mission: Mission) =>
    mission.vehicle_id != null && mission.vehicle_id !== undefined && filteredVehicles.has(mission.vehicle_id)
}

export { useGetMissionsQuery, missionVehicleIsGen3 }
