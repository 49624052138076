import { RemotePage, usePage } from "../contexts/Page"
import { useLoadId } from "../contexts/LoadId"

const useNavigateToMissionSession = () => {
  const page = usePage()
  const loadId = useLoadId()

  const navigateToMissionSession = (newLoadId: string) => {
    if (page.active !== RemotePage.ActiveSessions) {
      page.setActive(RemotePage.ActiveSessions)
    }
    loadId.setCurrent(newLoadId)
  }

  return navigateToMissionSession
}

export default useNavigateToMissionSession
