/* istanbul ignore file */
import { RemoteCockpitApiClient } from "@torc-robotics/mm-remote-cockpit"
import { Auth } from "aws-amplify"

const RemoteCockpitAPI = new RemoteCockpitApiClient({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  BASE: import.meta.env.VITE_REMOTE_COCKPIT_API_URL,
  TOKEN: async () => await Auth.currentSession().then((res) => res.getIdToken().getJwtToken()),
}).default

export default RemoteCockpitAPI
