import useGetSessions from "@/framework/query/useGetSessions"
import "./scss/sessions.scss"
import SessionsList from "./SessionsList"

const SessionsPanel = () => {
  const { query, allOtherSessions, myActiveSessions } = useGetSessions()

  if (query.isLoading) {
    return <p>Loading...</p>
  }

  return (
    <div className="sessions-panel">
      <div className="my-active-sessions">
        <h2 className="my-active-sessions-title">My Active Sessions ({myActiveSessions.length || 0})</h2>
        <SessionsList sessions={myActiveSessions} isMySessions />
      </div>
      <div className="other-sessions">
        <h2 className="other-sessions-title">Other Sessions ({allOtherSessions.length || 0})</h2>
        <SessionsList sessions={allOtherSessions} isMySessions={false} />
      </div>
    </div>
  )
}

export default SessionsPanel
