import { useEffect, useState } from "react"
import { useGetMissionsQuery } from "./useGetMissionsQuery"
import { InfiniteData } from "@tanstack/react-query"
import { MissionSearchResult } from "@torc-robotics/mm-missions-client"

const useGetActiveVehicles = () => {
  const intervalMs = 60000

  const [activeVehicles, setActiveVehicles] = useState(0)
  const [isLoading, setIsLoading] = useState(true)

  const {
    data: missionsData,
    fetchNextPage: fetchNextPageMissions,
    hasNextPage: hasNextPageMissions,
    status: statusMissions,
  } = useGetMissionsQuery({
    filters: [],
    states: ["IN_PROGRESS"],
    pageSize: 300,
    refetchInterval: intervalMs,
    enabled: true,
  })

  useEffect(() => {
    if (statusMissions === "success" && hasNextPageMissions) {
      void fetchNextPageMissions()
    }
    if (statusMissions === "success" && !hasNextPageMissions) {
      const vehicles = getUniqueVehicleIds(missionsData)
      setActiveVehicles(vehicles.size)
    }
    if (statusMissions === "success" && hasNextPageMissions === false) setIsLoading(false)
  })

  return { activeVehicles: activeVehicles, isLoading: isLoading }
}

const getUniqueVehicleIds = (missionsData: InfiniteData<MissionSearchResult, unknown>) =>
  missionsData?.pages.reduce<Set<string>>((trucks, page) => {
    const pageTrucks = page.missions?.reduce<Set<string>>((trucks, mission) => {
      if (typeof mission.vehicle_id === "string") return trucks.add(mission.vehicle_id)
      return trucks
    }, new Set())
    if (typeof pageTrucks !== "undefined")
      return new Set(
        (function* () {
          yield* trucks
          yield* pageTrucks
        })()
      )
    return trucks
  }, new Set())

export default useGetActiveVehicles
