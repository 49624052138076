import useMapStore, { MapType } from "../store/useMapStore"
import { useCallback, useEffect, useMemo, useRef } from "react"
import bbox from "@turf/bbox"
import useRerouteHandler from "./useRerouteHandler"
import useMissionsRouteGeoJson from "./useMissionsRouteGeoJson"

/**
 * useMapViewHandler - Handles centering the map on a route
 */
const useMapViewHandler = (mapType: MapType, rerouteEnabled = true) => {
  const mapRef = useMapStore((state) => state[mapType].mapRef.current)
  const mapLoaded = useMapStore((state) => state[mapType].isLoaded)

  const boundsRef = useRef<number[][] | null>(null)
  const routeData = useMissionsRouteGeoJson()

  const { routeAdvisorRoutesQuery, isRerouting } = useRerouteHandler()
  const reroutePoints = routeAdvisorRoutesQuery
    .flatMap((query) => query.data?.latlons ?? [])
    .map((latlon) => [latlon.lon, latlon.lat])

  const points = reroutePoints.length > 0 && rerouteEnabled ? reroutePoints : routeData?.geometry.coordinates ?? []

  /**
   * Computes the bounding box for given coordinates
   */
  const getBoundingBox = (coordinates: number[][]) => {
    if (!coordinates.length) return null

    const [minLng, minLat, maxLng, maxLat] = bbox({
      type: "Feature",
      geometry: { type: "LineString", coordinates },
      properties: {},
    })

    const mapBounds = [
      [minLng, minLat],
      [maxLng, maxLat],
    ]

    const isValidBounds = mapBounds.every(([, lat]) => lat >= -90 && lat <= 90)
    if (!isValidBounds) return null

    return [
      [minLng, minLat],
      [maxLng, maxLat],
    ]
  }

  const mapBounds = useMemo(() => getBoundingBox(points), [points])

  const centerMap = useCallback(() => {
    if (!mapRef || !mapLoaded || points.length === 0) return

    if (!mapBounds) return

    // @ts-expect-error: API response doesn't match types
    mapRef?.fitBounds(mapBounds, { padding: 50, animate: false })
    boundsRef.current = mapBounds
  }, [mapRef, mapLoaded, mapBounds])

  useEffect(() => {
    if (!mapLoaded || points.length === 0) return

    if (boundsRef.current?.toString() !== mapBounds?.toString() || isRerouting) {
      setTimeout(() => {
        centerMap()
      }, 50)
    }
  }, [points, mapLoaded, isRerouting])

  return { centerMap }
}

export default useMapViewHandler
