import "./scss/assist.scss"
import VizPanel from "../viz-panel/VizPanel"
import CameraPanel from "../camera-panel/CameraPanel"
import ControlPanel from "../../features/sessions/control/ControlPanel"
import MrmStatusBanner from "@/features/sessions/mrm-alert-banner/MrmAlertBanner"

const Assist = () => {
  /**
   * Get the item by id
   * @param {"remote-viz" | "remote-cam" | "remote-map" | null} itemId
   * @returns {JSX.Element}
   */
  const getItemById = (itemId) => {
    switch (itemId) {
      case "remote-viz":
        return <VizPanel />
      case "remote-cam":
        return <CameraPanel />
    }
  }

  return (
    <div className="assist-container">
      <div className="assist-container__viz-panel" data-swapy-slot="A">
        {getItemById("remote-viz")}
      </div>
      <div className="assist-container__camera-panel" data-swapy-slot="C">
        {getItemById("remote-cam")}
      </div>
      <div className="assist-container__control-panel">
        <MrmStatusBanner />
        <ControlPanel />
      </div>
    </div>
  )
}

export default Assist
