import { useMutation } from "@tanstack/react-query"
import RemoteCockpitAPI from "../api/RemoteCockpitAPI"
import useMissionQuery from "./useMissionQuery"
import { useEffect } from "react"
import useMapStore, { MapType } from "../store/useMapStore"

const useActionMutation = ({ action }: { action: string }) => {
  const missionsQuery = useMissionQuery()
  const selectedRoute = useMapStore((state) => state[MapType.REMOTE_VIZ].selectedRoute)

  const mission = missionsQuery?.data?.missions?.[0]
  const actionMutation = useMutation({
    mutationKey: ["action", action],
    mutationFn: async () => {
      switch (action) {
        case "stop":
          return await RemoteCockpitAPI.postActionStopActionsStopMissionIdPost({
            missionId: mission?.uuid ?? "",
            requestBody: { vehicle_id: mission?.vehicle_id ?? "" },
          })
        case "resume":
          return await RemoteCockpitAPI.postActionResumeActionsResumeMissionIdPost({
            missionId: mission?.uuid ?? "",
            requestBody: { vehicle_id: mission?.vehicle_id ?? "" },
          })
        case "reroute":
          return await RemoteCockpitAPI.postActionRerouteActionsRerouteMissionIdPost({
            missionId: mission?.uuid ?? "",
            requestBody: { vehicle_id: mission?.vehicle_id ?? "", route_id: selectedRoute?.routeId ?? "" },
          })
        default:
          return Promise.reject("no action was called")
      }
    },
    retry: false,
  })

  // on success, reset the query after 3 seconds to let user retry on timeout
  useEffect(() => {
    if (actionMutation.isSuccess) {
      /* istanbul ignore next */
      setTimeout(() => {
        /* istanbul ignore next */
        actionMutation.reset()
      }, 3000)
    }
  }, [actionMutation.isSuccess])

  return actionMutation
}

export default useActionMutation
