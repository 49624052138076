import useGetRemoteAssistanceEventsQuery from "../query/useGetRemoteAssistanceEventsQuery"
import useGetLoadId from "./useGetLoadId"

/*
 * This hook is used to get the latest scenario detection event for a mission
 */
const useLatestScenarioEvent = () => {
  const { data: raEvents } = useGetRemoteAssistanceEventsQuery()
  const loadId = useGetLoadId() ?? ""

  const latestEvent = raEvents?.data?.getEvents?.find(
    (event) => event.eventType === "DETECTED_SCENARIO" && event.loadId === loadId
  )

  return latestEvent
}

export default useLatestScenarioEvent
