import type EgressMessage from "@/framework/models/iot/egress/EgressMessage"
import { type EgressStatus } from "@/framework/models/iot/egress/EgressStatus"
import useVehicleStore from "@/framework/store/useVehicleStore"
import { merge } from "lodash"
import useMqttSubscription from "@/framework/subscriptions/useMqttSubscription"

const useGlobalMqttSubscriptions = (): void => {
  const updateVehicle = useVehicleStore((state) => state.updateVehicleRecord)

  useMqttSubscription<EgressMessage<EgressStatus>>({
    topic: ["mission_management/to_portal/Status"],
    batchComplete: (batch) => {
      updateVehicle(batch as Record<string, EgressMessage<EgressStatus>>)
    },
    batchNext: (prev, message) => {
      const previous = prev as Record<string, Record<string, EgressMessage<EgressStatus>>>

      // vehicle_id is currently not being sent in the message, parsing from src topic...
      const vehicleId = message.vehicle_id ?? message.source_topic.split("/").slice(-1)[0]

      return {
        ...previous,
        [vehicleId]: merge({ vehicle_id: vehicleId }, previous[vehicleId], message),
      }
    },
    batchInterval: 1000,
  })
}
export default useGlobalMqttSubscriptions
