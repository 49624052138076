import ActiveSessions from "@/components/active-sessions/ActiveSessions"
import RemoteDashboard from "@/components/remote-dashboard/RemoteDashboard"
import { createContext, ReactNode, useContext, useState } from "react"

enum RemotePage {
  Dashboard,
  ActiveSessions,
}
interface PageContextValue {
  active: RemotePage
  getActive: () => ReactNode
  setActive: React.Dispatch<React.SetStateAction<RemotePage>>
}

const PageContext = createContext<PageContextValue | null>(null)

const usePage = () => {
  const pageContext = useContext(PageContext)

  if (!pageContext) {
    throw new Error("usePage must be used within <PageContext.Provider>")
  }

  return pageContext
}

const PageContextProvider = ({ children }: { children: ReactNode }) => {
  const [active, setActive] = useState(RemotePage.Dashboard)
  const getActive = () => {
    switch (active) {
      case RemotePage.ActiveSessions:
        return <ActiveSessions />
      case RemotePage.Dashboard:
        return <RemoteDashboard />
    }
  }

  return (
    <PageContext.Provider value={{ active: active, setActive: setActive, getActive: getActive }}>
      {children}
    </PageContext.Provider>
  )
}

export default PageContext
export { RemotePage, PageContextProvider, usePage }
export type { PageContextValue }
