import { create } from "zustand"
import { GetMissionLogResponse } from "@torc-robotics/mm-missions-client"

type SelectedLogState = {
  selectedLog: GetMissionLogResponse | null
  setSelectedLog: (log: GetMissionLogResponse | null) => void
  blink: boolean
  setBlink: (blink: boolean) => void
}

export const useSelectedLogStore = create<SelectedLogState>((set) => ({
  selectedLog: null,
  setSelectedLog: (log) => set({ selectedLog: log }),
  blink: false,
  setBlink: (blink) => set({ blink }),
}))
