import VehiclesApi from "../api/VehiclesAPI"
import { useQuery } from "@tanstack/react-query"

const useVehiclesQuery = () => {
  const vehiclesQuery = useQuery({
    queryKey: ["vehicles-overview"],
    queryFn: async () => await VehiclesApi.getVehiclesV2(),
  })

  return vehiclesQuery
}

export default useVehiclesQuery
