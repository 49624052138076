/* istanbul ignore file */
import Map, { NavigationControl, useControl, ScaleControl, Source, Layer } from "react-map-gl"
import { WebMercatorViewport } from "@deck.gl/core"
import { MapboxOverlay } from "@deck.gl/mapbox/typed"
import { useGeoCreds } from "@/framework/hooks/UserHooks"
import useVehicleStore from "@/framework/store/useVehicleStore"
import useCenteredVehicle from "@/framework/hooks/useCenteredVehicle"
import useMapViewHandler from "@/framework/hooks/useMapViewHandler"
import useVehiclesLayer from "@/framework/layers/useVehiclesLayer"
import LoadScreen from "@/framework/utils/LoadScreen"
import useMapStore, { MapType } from "@/framework/store/useMapStore"
import { useEffect } from "react"
import maplibregl from "maplibre-gl"
import { IconButton } from "@mui/material"
import CenterMapIcon from "../../assets/truck-icons/center-map.svg"
import config from "../../config"
import { Signer } from "@aws-amplify/core"
import HoverWrapper from "@/features/map/hover/HoverWrapper"
import "./scss/remoteDashboardMap.scss"
import { uvaldeFillStyle, uvaldeLineStyle } from "../map-panel/mapStyle"
import uvaldeGeoJson from "@/components/map-panel/uvalde.geo.json"

const initViewstate = {
  longitude: -98.5795,
  latitude: 40.8283,
  zoom: 4,
}

const INIT_VIEWSTATES = {
  [MapType.MONITOR_QUEUE]: {
    longitude: -98.5795,
    latitude: 40.8283,
    zoom: 2.5,
  },
  [MapType.ASSISTANCE_QUEUE]: {
    longitude: -98.5795,
    latitude: 40.8283,
    zoom: 2,
  },
  [MapType.REMOTE_VIZ]: initViewstate,
}

const RemoteDashboardMap = ({ mapType, maxWidth, maxHeight, vehicleIds, selectedVehicle, vehicleIconType }) => {
  const geoCreds = useGeoCreds()

  const mapRef = useMapStore((state) => state[mapType].mapRef)
  const { width, height } = useMapStore((state) => state[mapType].containerSize)
  const hoverInfo = useMapStore((state) => state[mapType].hoverInfo)
  const setMapLoaded = useMapStore((state) => state[mapType].setLoaded)
  const breakCenter = useMapStore((state) => () => state[mapType].setCentered(false))

  const vehiclesData = useVehicleStore((state) =>
    Object.values(state.vehicles.entities)
      .flatMap((vehicle) => vehicle)
      // filter out vehicles that don't have a location
      .filter((vehicle) => vehicle?.data?.coordinates?.latitude && vehicle?.data?.coordinates?.longitude)
      // filter out vehicles that are not in in-transit or in pre-departure
      .filter((vehicle) => {
        return vehicleIds.includes(vehicle.vehicle_id)
      })
  )

  const getBoundsForPoints = (vehicles) => {
    const points = vehicles.map((vehicle) => {
      return {
        longitude: vehicle.data.coordinates.longitude,
        latitude: vehicle.data.coordinates.latitude,
      }
    })

    if (points.length === 0) {
      return { longitude: initViewstate.longitude, latitude: initViewstate.latitude, zoom: initViewstate.zoom }
    }

    // Calculate corner values of bounds
    const longitudes = points.map((point) => point.longitude)
    const latitudes = points.map((point) => point.latitude)

    const cornersLongLat = [
      [Math.min(...longitudes), Math.min(...latitudes)],
      [Math.max(...longitudes), Math.max(...latitudes)],
    ]

    // Use WebMercatorViewport to get center longitude/latitude and zoom
    const viewport = new WebMercatorViewport({ width: width, height: height }).fitBounds(cornersLongLat, {
      padding: 100,
    })

    const { longitude, latitude, zoom } = viewport
    return { longitude, latitude, zoom }
  }

  const onClickCenter = () => {
    const { longitude, latitude, zoom } = getBoundsForPoints(vehiclesData)
    mapRef.current.jumpTo({
      center: [longitude, latitude],
      zoom: zoom,
    })
  }

  useMapResizeListener(mapType)
  useCenteredVehicle(mapType, selectedVehicle)

  const vehiclesLayer = useVehiclesLayer(mapType, vehicleIconType, vehicleIds)

  useMapViewHandler(mapType)

  const transformRequest = (url, resourceType) => {
    if (resourceType === "Style" && url.indexOf("https://maps.geo.us-east-1.amazonaws.com") > -1) {
      url = `${config.AWS_MAP_URL}`
    }
    if (url.includes("amazonaws.com")) {
      return {
        url: Signer.signUrl(url, {
          access_key: geoCreds.accessKeyId,
          secret_key: geoCreds.secretAccessKey,
          session_token: geoCreds.sessionToken,
        }),
      }
    }
    return { url: url || "" }
  }

  return geoCreds ? (
    <>
      <Map
        ref={mapRef}
        onLoad={setMapLoaded}
        id="mappanel"
        initialViewState={INIT_VIEWSTATES[mapType]}
        dragRotate={false}
        touchRotate={false}
        mapStyle={`https://maps.geo.us-east-1.amazonaws.com/maps/v0/maps/nav-map-esri/style-descriptor`}
        mapLib={maplibregl}
        transformRequest={transformRequest}
        onDrag={breakCenter}
        attributionControl={false}
        style={{ width: maxWidth, height: maxHeight }}
      >
        <Source id="uvalde" type="geojson" data={uvaldeGeoJson}>
          <Layer {...uvaldeFillStyle} />
          <Layer {...uvaldeLineStyle} />
        </Source>
        <div className="map-options">
          <IconButton disableRipple color="inherit" onClick={() => onClickCenter()}>
            <img className="center-map-icon" src={CenterMapIcon} />
          </IconButton>
          <NavigationControl showCompass={false} data-testid="map-controls-button" />
        </div>
        <DeckGLOverlay layers={[vehiclesLayer]} />
        {hoverInfo?.object && <HoverWrapper hoverInfo={hoverInfo} mapType={mapType} />}
        <ScaleControl maxWidth={100} unit="imperial" position="bottom-right" />
      </Map>
    </>
  ) : (
    <LoadScreen />
  )
}

const DeckGLOverlay = (props) => {
  const overlay = useControl(() => new MapboxOverlay(props))
  overlay.setProps(props)
  return null
}

const useMapResizeListener = (mapType) => {
  const mapRef = useMapStore((state) => state[mapType].mapRef)
  const setContainerSize = useMapStore((state) => state[mapType].setContainerSize)

  useEffect(() => {
    const handleResize = () => {
      if (mapRef.current) {
        mapRef.current.resize()
        const { width, height } = mapRef.current.getMap().getContainer().getBoundingClientRect()
        setContainerSize({ width, height })
      }
    }

    handleResize()
    window.addEventListener("resize", handleResize)
    screen.orientation.addEventListener("change", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
      screen.orientation.removeEventListener("change", handleResize)
    }
  }, [mapRef.current])
}

export default RemoteDashboardMap
export { DeckGLOverlay }
export { initViewstate }
