import "./scss/remoteHeader.scss"
import { Box, Button, Typography } from "@mui/material"
import AppBar from "@mui/material/AppBar"
import useGetActiveVehicles from "@/framework/query/useGetActiveVehicles"
import useGetActiveUsers from "@/framework/query/useGetActiveUsers"
import { Home } from "@mui/icons-material"
import { RemotePage, usePage } from "@/framework/contexts/Page"

const RemoteHeader = () => {
  const activeUsersQuery = useGetActiveUsers()
  const { activeVehicles, isLoading: vehiclesLoading } = useGetActiveVehicles()

  const isLoading = activeUsersQuery.isLoading || vehiclesLoading

  const fleetHealthText = isLoading ? "Fleet health loading..." : ` (RAs to SDTs)`
  const fleetHealthData = isLoading ? "" : `${activeUsersQuery.data?.data?.getActiveUsers ?? 0} : ${activeVehicles}`

  const page = usePage()

  return (
    <AppBar position="sticky" className="remote-header" data-testid="remote-header">
      <Box className="remote-header-inner">
        <Button
          variant="outlined"
          startIcon={<Home sx={{ fontSize: 14 }} />}
          className="home-button"
          onClick={() => page.setActive(RemotePage.Dashboard)}
        >
          Dashboard
        </Button>
        <div className="fleet-health">
          <Typography className="fleet-health-data">{fleetHealthData}</Typography>
          <Typography className="fleet-health-text">{fleetHealthText}</Typography>
        </div>
        <div></div>
      </Box>
    </AppBar>
  )
}

export default RemoteHeader
