import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import * as React from "react"
import "./scss/controlPanel.scss"
import { getLogger } from "../../../framework/contexts/DataDog"
import ActionsPanel from "./actions-panel/ActionsPanel"
import ScenarioDetectionPanel from "./scenario-detection-panel/ScenarioDetectionPanel"
import useGetRemoteAssistanceEventsQuery from "@/framework/query/useGetRemoteAssistanceEventsQuery"
import useGetLoadId from "@/framework/hooks/useGetLoadId"
import { Slide } from "@mui/material"
import { useEffect } from "react"
import ErrorIcon from "@mui/icons-material/Error"
import useLatesMrmEvent from "@/framework/hooks/useLatestMrmEvent"

const logger = getLogger()

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      className="control-tab-panel"
      role="tabpanel"
      hidden={value !== index}
      id={`control-tabpanel-${index}`}
      aria-labelledby={`control-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

const ControlPanel = () => {
  const { data: raEventsData } = useGetRemoteAssistanceEventsQuery()
  const loadId = useGetLoadId()

  const latestEvent = raEventsData?.data?.getEvents?.find(
    (event) => event.eventType === "DETECTED_SCENARIO" && event.loadId === loadId
  )
  const latestMrmEvent = useLatesMrmEvent()

  const [activeActionType, setActiveActionType] = React.useState(null)
  const [activeActionMutation, setActiveActionMutation] = React.useState(null)
  const [selectedTab, setSelectedTab] = React.useState(latestEvent && !latestMrmEvent ? 1 : 0)

  // Scenario Event Tab states
  const [displayData, setDisplayData] = React.useState(null)
  const [previousData, setPreviousData] = React.useState(null)
  const [showPrevious, setShowPrevious] = React.useState(false)
  const [eventCancelled, setEventCancelled] = React.useState(false)
  const cancelTimeoutRef = React.useRef(null)
  const loadIdRef = React.useRef()

  // Effect to handle new events
  useEffect(() => {
    const tabSwitch = loadId !== loadIdRef.current
    const isNewEvent = latestEvent && latestEvent !== displayData
    const shouldCancelEvent = !tabSwitch && latestEvent == null && displayData

    if (isNewEvent) {
      // If there is a new event, update the display and show the previous event
      setPreviousData(displayData)
      setShowPrevious(true)

      // Clear the event cancellation timeout if a new event arrives
      if (cancelTimeoutRef.current) {
        clearTimeout(cancelTimeoutRef.current)
        cancelTimeoutRef.current = null
      }

      if (eventCancelled) {
        // If the previous event was cancelled, we show the cancelled event for 1 second
        // before hiding it and updating the display to the latest event.
        setTimeout(() => {
          setEventCancelled(false)
          setShowPrevious(false)
          setDisplayData(latestEvent)
        }, 1000)
      } else {
        // If there is no cancellation, we determine how long to show the previous data.
        // If no event was previously displayed, we update to the latest event (0 delay).
        // Otherwise, we show the previous data for 4 seconds before updating to the latest event.
        setTimeout(
          () => {
            setShowPrevious(false)
            setDisplayData(latestEvent)
          },
          displayData == null || tabSwitch ? 0 : 4000
        )
      }

      if (tabSwitch) {
        loadIdRef.current = loadId
      }
    } else if (shouldCancelEvent) {
      // If the latest event is null and there was a displayed event, mark it as cancelled
      setEventCancelled(true)

      // Set a timeout to switch tabs after 3 seconds if no new event arrives
      cancelTimeoutRef.current = setTimeout(() => {
        setSelectedTab(0)
        setDisplayData(null)
        setEventCancelled(false)
      }, 3000)
    } else {
      setSelectedTab(0)
      setDisplayData(latestEvent)
    }
  }, [latestEvent])

  // Cleanup timeout on component unmount
  useEffect(() => {
    return () => {
      if (cancelTimeoutRef.current) {
        clearTimeout(cancelTimeoutRef.current)
      }
    }
  }, [])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    logger.debug(`new value selected ${newValue} `)
    setSelectedTab(newValue)
  }

  return (
    <div className="control-box">
      <Tabs className="control-tabs" value={selectedTab} onChange={handleChange}>
        <Tab className="control-tab" label="Actions Panel" />
        <Tab
          className="control-tab"
          label={
            <div>
              Scenario Detection
              {displayData && <ErrorIcon className="scenario-event-icon" />}
            </div>
          }
          iconPosition="top"
          disabled={!displayData}
        />
      </Tabs>
      <TabPanel value={selectedTab} index={0} key={0}>
        <ActionsPanel
          activeActionType={activeActionType}
          setActiveActionType={setActiveActionType}
          activeActionMutation={activeActionMutation}
          setActiveActionMutation={setActiveActionMutation}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        {/* Current event */}
        <Slide direction="down" in={!showPrevious} mountOnEnter unmountOnExit timeout={{ enter: 1000, exit: 1 }}>
          <div className="scenario-detection-event-container">
            <ScenarioDetectionPanel
              event={displayData}
              onAcknowledge={() => {
                setSelectedTab(0)
                setDisplayData(null)
                setEventCancelled(false)
              }}
              cancelled={eventCancelled}
            />
          </div>
        </Slide>

        {/* Previous event */}
        {showPrevious && (
          <div className="scenario-detection-event-container">
            <ScenarioDetectionPanel event={previousData} expire={!eventCancelled} cancelled={eventCancelled} />
          </div>
        )}
      </TabPanel>
    </div>
  )
}

export default ControlPanel
