import { CreateCallNoteDocument, CreateCallNoteInput } from "@torc-robotics/vda-calls-api/graphql/document"
import execute from "@/framework/api/CallsAPIGraphQL"
import { useMutation } from "@tanstack/react-query"
import { getLogger } from "../../framework/contexts/DataDog"

const logger = getLogger()

const useCreateCallNoteMutation = () => {
  const mutation = useMutation({
    mutationFn: async ({ body, conferenceUuid }: CreateCallNoteInput) => {
      return await execute(CreateCallNoteDocument, { input: { body, conferenceUuid } })
    },
    onSuccess: /* istanbul ignore next */ ({ data }) => {
      if (data?.createCallNote) {
        logger.info(`Successfully created call note: ${data?.createCallNote as string}`)
      } else {
        logger.warn("Unhandled return type for CreateCallNoteMutation: ", data?.createCallNote)
      }
    },
    onError: (error) => {
      logger.error("Error creating call note: ", error)
      alert(error)
    },
    retry: false,
  })

  return mutation
}

export default useCreateCallNoteMutation
