import useCurrentSession from "@/framework/hooks/useCurrentSession"
import useMissionRouteProgressQuery from "@/framework/query/useMissionRouteProgressQuery"
import { Divider, LinearProgress } from "@mui/material"
import "./MissionVehicleProgressInfo.scss"
import useMissionQuery from "@/framework/query/useMissionQuery"
import useGetVehicleStatus from "@/framework/query/useGetVehicleStatusQuery"
import { calculateTimeRemaining, getFormattedTime } from "@/framework/utils/Utils"

const MissionVehicleProgressInfo = () => {
  const session = useCurrentSession()

  if (!session) {
    return
  }

  const mission = useMissionQuery().data?.missions?.[0]
  const vehicleStatusQuery = useGetVehicleStatus(mission?.vehicle_id ?? "")
  const vehicleStatus = vehicleStatusQuery.data
  const { data, isPending, isError } = useMissionRouteProgressQuery(mission?.uuid ?? "")
  const progress = +(data?.percent_complete?.toFixed(2) ?? 0)

  const eta = getFormattedTime(mission?.eta_datetime ?? "", undefined, "HH:mm z")
  const lat =
    vehicleStatus?.lat ?? 0 > 0
      ? ` ${Math.abs(vehicleStatus?.lat ?? 0).toFixed(3)}' N`
      : ` ${Math.abs(vehicleStatus?.lat ?? 0).toFixed(3)}' S`
  const long =
    vehicleStatus?.lon ?? 0 > 0
      ? ` ${Math.abs(vehicleStatus?.lon ?? 0).toFixed(3)}' W`
      : ` ${Math.abs(vehicleStatus?.lon ?? 0).toFixed(3)}' E`
  const coordinates = `${lat ?? "--"} ${long ?? "--"}`
  const departureTime = getFormattedTime(mission?.tms_schedule_departure_time ?? "--", undefined, "HH:mm z")
  const arrivalTime = getFormattedTime(mission?.tms_schedule_arrival_time ?? "--", undefined, "HH:mm z")
  const remainingTime = calculateTimeRemaining(mission?.eta_remaining_seconds)

  return (
    <div className="mission-details-container__vehicle-info-panel">
      <div className="vehicle-info__header">
        <h4 className="vehicle-info__header--vehicle-id" title={mission?.load_id ?? "--"}>
          {mission?.vehicle_id ?? "--"}
        </h4>
        <p className="vehicle-info__header--eta">ETA: {eta}</p>
        <p className="vehicle-info__header--mission-id" title={session.loadId}>
          <span>Mission ID:</span>
          <span>{mission?.load_id ?? "--"}</span>
        </p>
        <p className="vehicle-info__header--coordinates">{coordinates}</p>
      </div>
      <Divider variant="middle" />
      <div className="vehicle-info__progress">
        <p className="vehicle-info__progress-title">Mission Progress</p>
        <div className="vehicle-info__progress-bar">
          <div className="vehicle-info__progress-bar--labels">
            <p>Departure: {departureTime}</p>
            <p>Arrival: {arrivalTime}</p>
          </div>
          <LinearProgress variant="determinate" value={progress} sx={{ height: 5 }} />

          <div
            className="vehicle-info__progress-tooltip--container"
            style={{
              left: `${progress}%`,
            }}
          >
            <div className="vehicle-info__progress-tooltip">
              {isPending || isError ? (
                <p>--</p>
              ) : (
                <>
                  <p>Remaining: {remainingTime}</p>
                  <p>Completed: {progress}%</p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default MissionVehicleProgressInfo
