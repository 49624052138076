import * as React from "react"
import "./scss/actionsPanel.scss"
import ActionButton from "./action-buttons/ActionButton"
import ActionConfirm from "./action-confirm/ActionConfirm"
import { ActionProgress } from "./action-progress/ActionProgress"
import useRerouteHandler from "@/framework/hooks/useRerouteHandler"

interface ActionsPanelProps {
  activeActionType?: string
  setActiveActionType: () => void
  activeActionMutation?: object
  setActiveActionMutation: () => void
}

const ActionsPanel = (props: ActionsPanelProps) => {
  const { activeActionType, setActiveActionType, activeActionMutation, setActiveActionMutation } = props
  const actionButtons: { [key: string]: { label: string; type: string; confirmText: string } } = {
    stop: {
      label: "Stop",
      type: "stop",
      confirmText:
        "The SDT will determine the best location to MRM and will stop when conditions are safe. Are you sure you want to send a stop request to the SDT?",
    },
    resume: {
      label: "Resume",
      type: "resume",
      confirmText:
        "The SDT will resume the mission when surroundings are clear and it is safe. Are you sure you want to resume the SDT?",
    },
    reroute: {
      label: "Reroute",
      type: "reroute",
      confirmText: "Select an alternative route for the SDT from the map.",
    },
  }

  const { isRerouting } = useRerouteHandler()

  const showActions = () => (
    <>
      {(!activeActionType && !activeActionMutation) || (activeActionType === "reroute" && isRerouting) ? (
        <div className="actions-box">
          {" "}
          {Object.keys(actionButtons).map((actionButton) => (
            <ActionButton
              className="action-button"
              key={actionButton}
              actionButton={actionButtons[actionButton]}
              activeActionType={activeActionType}
              setActiveActionType={setActiveActionType}
            />
          ))}{" "}
        </div>
      ) : null}
    </>
  )

  const showConfirm = () => (
    <>
      {activeActionType ? (
        <ActionConfirm
          actionButton={actionButtons[activeActionType]}
          activeActionType={activeActionType}
          setActiveActionType={setActiveActionType}
          setActiveActionMutation={setActiveActionMutation}
        />
      ) : null}
    </>
  )

  const showProgress = () => (
    <>
      {activeActionMutation ? (
        <ActionProgress activeActionMutation={activeActionMutation} setActiveActionMutation={setActiveActionMutation} />
      ) : null}
    </>
  )

  return (
    <div className="action-panel">
      {showActions()}
      {showConfirm()}
      {showProgress()}
    </div>
  )
}

export default ActionsPanel
