import { createContext, useContext } from "react"

interface LoadIdContextValue {
  current: string
  setCurrent: React.Dispatch<React.SetStateAction<string>>
}

const LoadIdContext = createContext<LoadIdContextValue | null>(null)

const useLoadId = () => {
  const loadIdContext = useContext(LoadIdContext)

  if (!loadIdContext) {
    throw new Error("useLoadId must be used within <LoadIdContext.Provider>")
  }

  return loadIdContext
}

export default LoadIdContext
export { useLoadId }
export type { LoadIdContextValue }
