import { TypedDocumentString } from "@torc-robotics/vda-sessions-api/graphql/document"
import { Auth } from "aws-amplify"
import { getLogger } from "../../framework/contexts/DataDog"

const logger = getLogger()

type GraphQLResponse<TResult> = {
  data?: TResult
  errors?: Array<{ message: string }>
}

// This function checks if the given JSON object is a valid GraphQL response
// It checks if the object has a `data` property or an `errors` property
// It also checks if the `data` property is an object and if the `errors` property is an array
function isGraphQLResponse<TResult>(json: unknown): json is GraphQLResponse<TResult> {
  if (typeof json !== "object" || json === null) {
    return false
  }

  const response = json as Record<string, unknown>

  const hasData = "data" in response && (typeof response.data === "object" || response.data === null)
  const hasErrors = "errors" in response && Array.isArray(response.errors)

  return hasData || hasErrors
}

export default async function execute<TResult, TVariables>(
  query: TypedDocumentString<TResult, TVariables>,
  variables?: TVariables
): Promise<GraphQLResponse<TResult>> {
  const response = await fetch(import.meta.env.VITE_SESSIONS_API_GRAPHQL_URL as string, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/graphql-response+json",
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
    body: JSON.stringify({
      query: query,
      variables: variables,
    }),
  })

  if (!response.ok) {
    throw new Error("Network response was not ok")
  }

  const responseJson = (await response.json()) as unknown
  if (!isGraphQLResponse<TResult>(responseJson)) {
    throw new Error("Invalid GraphQL response format")
  }

  // If there are errors, throw an error with the messages
  if (responseJson.errors && responseJson.errors.length > 0) {
    for (const error of responseJson.errors) {
      logger.error(error.message, error)
    }
    throw new Error(responseJson.errors.map((e) => e.message).join(", "))
  }

  return responseJson
}

export { isGraphQLResponse, type GraphQLResponse }
