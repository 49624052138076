import { VehiclesApiClient } from "@torc-robotics/mm-vehicles-client"
import { Auth } from "aws-amplify"

const VehiclesApi = new VehiclesApiClient({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  BASE: import.meta.env.VITE_VEHICLES_API_URL,
  TOKEN: async () => await Auth.currentSession().then((res) => res.getIdToken().getJwtToken()),
}).v2

export default VehiclesApi
