import { useQuery } from "@tanstack/react-query"
import MissionsAPI from "../api/MissionsAPI"
import { useLoadId } from "../contexts/LoadId"

const useMissionQuery = (explicitLoadId?: string) => {
  const loadIdFromContext = useLoadId().current
  const loadId = explicitLoadId ?? loadIdFromContext

  const query = useQuery({
    queryKey: ["missions", loadId],
    queryFn: async () => await MissionsAPI.v1.getMissions({ loadId }),
    enabled: !!loadId,
  })
  return query
}

export default useMissionQuery
