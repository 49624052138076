import { Box, Card, CardContent, Typography, Button, Collapse, IconButton, Grid, CardHeader } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import { src__model__mission__Mission_Output as Mission_Output } from "@torc-robotics/mm-missions-client"
import useStartSessionMutation from "@/framework/query/useStartSessionMutation"
import "./scss/missionInTransitItem.scss"
import useBrowserId from "@/framework/hooks/useBrowserId"
import useVehicleStore from "@/framework/store/useVehicleStore"
import { formatLatLon } from "@/framework/utils/Utils"
import { useEffect, useRef } from "react"
import usePerformanceStore from "@/framework/store/usePerformanceStore"
import MissionInTransitCollapseContent from "./MissionInTransitCollapseContent"

const EXPAND_ANIMATION_DURATION = 500

interface MissionInTransitItemProps {
  vehicle: string
  missionId: string
  isExpanded: boolean
  mission: Mission_Output
  onExpandClick: (mission: Mission_Output, isExpanded: boolean) => void
}

const MissionInTransitItem = ({
  vehicle,
  missionId,
  isExpanded,
  mission,
  onExpandClick,
}: MissionInTransitItemProps) => {
  const performance = usePerformanceStore()
  const startSession = useStartSessionMutation()

  const vehicleStatus = useVehicleStore((state) => state.vehicles.entities[vehicle] ?? null)

  const browserId = useBrowserId()

  const handleStartSession = () => {
    startSession.mutate({ loadId: missionId, vehicleId: vehicle, browserId, reason: null })
    performance.startTiming("startSessionToAssistView")
  }

  const expandedRef = useRef(isExpanded)
  const cardRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const scrollableDiv = document.getElementById("inTransitScrollableDiv")

    if (!scrollableDiv) {
      return
    }

    setTimeout(() => {
      const cardRect = cardRef.current?.getBoundingClientRect() as DOMRect
      const scrollableRect = scrollableDiv.getBoundingClientRect()

      if (isExpanded && !expandedRef.current) {
        const scrollTop =
          cardRect?.top + scrollableDiv.scrollTop - scrollableRect.top - (scrollableRect.height - cardRect?.height) / 2

        scrollableDiv?.scrollTo({ top: scrollTop, behavior: "smooth" })
      }

      expandedRef.current = isExpanded
    }, EXPAND_ANIMATION_DURATION)
  }, [isExpanded])

  return (
    <Card ref={cardRef} className="mission-intransit-card">
      <CardHeader
        sx={{ padding: 0 }}
        title={
          <Box className="mission-intransit-card-header">
            <Grid container alignItems="center" spacing={2} sx={{ width: "100%" }}>
              <Grid item xs={4}>
                <Typography className="vehicle-id">{vehicle}</Typography>
              </Grid>
              <Grid item xs={4} sx={{ alignItems: "center", display: "flex" }}>
                <Typography variant="body2" color="text.secondary" className="mission-id">
                  {missionId}
                </Typography>
              </Grid>
              <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body2" color="text.secondary">
                  {vehicleStatus?.data?.coordinates
                    ? formatLatLon(vehicleStatus.data.coordinates.latitude, vehicleStatus.data.coordinates.longitude)
                    : ""}
                </Typography>
              </Grid>
            </Grid>
            <Box className="mission-intransit-card-actions">
              <Button className="view-sdt-button" variant="contained" color="primary" onClick={handleStartSession}>
                View SDT
              </Button>
              <IconButton
                className="mission-in-transit-expand-button"
                onClick={() => onExpandClick(mission, isExpanded)}
              >
                {isExpanded ? (
                  <ExpandLessIcon className="expand-button" />
                ) : (
                  <ExpandMoreIcon className="expand-button" />
                )}
              </IconButton>
            </Box>
          </Box>
        }
      />
      <CardContent>
        <Collapse className="mission-intransit-card-details" in={isExpanded} unmountOnExit>
          <MissionInTransitCollapseContent missionId={missionId} />
        </Collapse>
      </CardContent>
    </Card>
  )
}

export default MissionInTransitItem
