import { useInfiniteQuery } from "@tanstack/react-query"
import MissionsAPI from "../api/MissionsAPI"
import {
  src__model__mission__Mission_Output as Mission_Output,
  type MissionSearchResult,
} from "@torc-robotics/mm-missions-client"
import useMissionFilterStore from "../store/useMissionFilterStore"

const filterByVehicleGen = (mission: Mission_Output) => mission.vehicle_gen?.startsWith("3")

const filterByCustomer = (mission: Mission_Output, selectedCustomers: string[]) => {
  if (selectedCustomers.length === 0) return true
  selectedCustomers = selectedCustomers.map((customer) => customer.toLowerCase())
  const customerName = mission.customer_name
  if (customerName === undefined || customerName === null) return false
  return selectedCustomers.indexOf(customerName.toLowerCase()) > -1
}

const filterByHub = (mission: Mission_Output, selectedHubs: string[]) => {
  if (selectedHubs.length === 0) return true

  selectedHubs = selectedHubs.map((hub) => hub.toLowerCase())

  const start = mission.start_display !== undefined ? mission.start_display : null
  const finish = mission.finish_display !== undefined ? mission.finish_display : null
  const destination = mission.destination_display !== undefined ? mission.destination_display : null

  let locations = [start, finish, destination]
  locations = locations.filter((value) => value !== null).map((value) => value.toLowerCase())
  let hubFound = false
  selectedHubs.some((hub) => {
    if (locations.indexOf(hub) > -1) {
      hubFound = true
      return true
    }
  })
  return hubFound
}

const useFilteredMissionsQuery = ({ states = ["IN_PROGRESS"] }: { states?: string[] } = {}) => {
  const { hubs, customers } = useMissionFilterStore()
  const selectedHubs = hubs
    .map((value) => (value.checked ? value.name.toLowerCase() : null))
    .filter((value) => value !== null)
  const selectedCustomers = customers
    .map((value) => (value.checked ? value.name.toLowerCase() : null))
    .filter((value) => value !== null)

  const missionsQuery = useInfiniteQuery({
    getNextPageParam: (lastPage: MissionSearchResult) =>
      lastPage.last_key !== undefined ? lastPage.last_key : undefined,
    initialPageParam: 0,
    queryKey: [
      "filteredMissions",
      {
        state: states,
        customers: customers,
        hubs: hubs,
      },
    ],
    queryFn: async ({ pageParam }) =>
      await MissionsAPI.v1
        .getMissions({
          active: true,
          maxIterate: 20000,
          searchType: "MISSIONS",
          pageSize: 30,
          ...(pageParam !== null && { startKey: JSON.stringify(pageParam) }),
          states: states,
        })
        .then(
          /* istanbul ignore next @preserve */
          (resp) => {
            resp.missions = resp.missions?.filter((mission) => {
              return (
                filterByCustomer(mission, selectedCustomers) &&
                filterByHub(mission, selectedHubs) &&
                filterByVehicleGen(mission)
              )
            })
            return resp
          }
        ),
    refetchOnMount: false,
  })

  return missionsQuery
}

export default useFilteredMissionsQuery
export { filterByVehicleGen, filterByCustomer, filterByHub }
