import "./scss/confidenceBadge.scss"

interface ConfidenceBadgeProps {
  assistanceNeeded: boolean
  percentage: number
}

const ConfidenceBadge = ({ assistanceNeeded = true, percentage }: ConfidenceBadgeProps) => {
  return (
    <div className={`confidence-badge confidence-badge--assistance-${assistanceNeeded.toString()}`}>
      <p>{`Low (${percentage}%)`}</p>
    </div>
  )
}

export default ConfidenceBadge
