/* istanbul ignore file */
import useVehicleStore from "@/framework/store/useVehicleStore"
import { IconLayer } from "@deck.gl/layers"
import DirectionalIcon from "../../assets/truck-icons/directional-truck.svg"
import OperationalInTransitIcon from "../../assets/truck-icons/monitor-queue-truck-in-transit-icon.svg"
import OperationalInPreDepartureIcon from "../../assets/truck-icons/monitor-queue-truck-in-pre-departure-icon.svg"
import AssistanceInTransitIcon from "../../assets/truck-icons/assistance-queue-truck-in-transit-icon.svg"

import useVehicleHover from "./useVehicleHover"
import VehiclesHoverTooltip from "@/features/map/hover/VehiclesHoverTooltip"
import useMapStore from "../store/useMapStore"
import useFilteredMissionsQuery from "../query/useFilteredMissionsQuery"

const VehicleIconType = Object.freeze({
  OPERATIONAL: 0,
  ASSISTANCE_NEEDED: 1,
})

const IconMapping = {
  [VehicleIconType.OPERATIONAL]: {
    inTransit: {
      url: new URL(OperationalInTransitIcon, import.meta.url).href,
      width: 36,
      height: 36,
    },
    inPreDeparture: {
      url: new URL(OperationalInPreDepartureIcon, import.meta.url).href,
      width: 36,
      height: 36,
    },
  },
  [VehicleIconType.ASSISTANCE_NEEDED]: {
    inTransit: {
      url: new URL(AssistanceInTransitIcon, import.meta.url).href,
      width: 36,
      height: 36,
    },
    inPreDeparture: {
      url: new URL(AssistanceInTransitIcon, import.meta.url).href,
      width: 36,
      height: 36,
    },
  },
}

const HoverInfoColor = {
  [VehicleIconType.OPERATIONAL]: "#4daf52",
  [VehicleIconType.ASSISTANCE_NEEDED]: "#fa3751",
}

const useVehiclesLayer = (mapType, vehicleIconType, vehicleIds) => {
  const { data: inTransitData } = useFilteredMissionsQuery({ states: ["IN_PROGRESS"] })

  const inTransitMissions = inTransitData?.pages?.flatMap((page) => page.missions || []) ?? []

  const vehicles = useVehicleStore((state) =>
    Object.values(state.vehicles.entities)
      .flatMap((vehicle) => vehicle)
      // filter out vehicles that don't have a location
      .filter((vehicle) => vehicle?.data?.coordinates?.latitude && vehicle?.data?.coordinates?.longitude)
      // filter out vehicles that are not in in-transit or in pre-departure
      .filter((vehicle) => {
        return vehicleIds.includes(vehicle.vehicle_id)
      })
  )

  const mapRef = useMapStore((state) => state[mapType].mapRef)
  const zoomLevel = mapRef?.current?.getZoom()

  const handleVehicleHover = useVehicleHover(mapType)

  const getPosition = (d, index) => [d?.data?.coordinates?.longitude, d?.data?.coordinates?.latitude, index * 10]

  const getIcon = (object, zoomLevel) => {
    const vehicleId = object?.vehicle_id
    if (zoomLevel < 10) {
      if (inTransitMissions.some((mission) => mission.vehicle_id === vehicleId)) {
        return IconMapping[vehicleIconType].inTransit
      } else {
        return IconMapping[vehicleIconType].inPreDeparture
      }
    } else {
      return {
        url: new URL(DirectionalIcon, import.meta.url).href,
        width: 135,
        height: 705,
        anchorY: 32.5,
      }
    }
  }

  const getSize = (zoomLevel) => {
    if (zoomLevel < 10) {
      return 36
    } else {
      return 90
    }
  }

  const activeVehiclesLayer = new IconLayer({
    id: "active-vehicles-layer",
    data: vehicles,
    pickable: true,
    getPosition: getPosition,
    getIcon: (d) => getIcon(d, zoomLevel),
    getSize: () => getSize(zoomLevel),
    onHover: ({ object }) => {
      return handleVehicleHover(
        <VehiclesHoverTooltip vehicleId={object?.vehicle_id} bgColor={HoverInfoColor[vehicleIconType]} />,
        object?.vehicle_id
      )
    },
    getAngle: (d) => -d?.data?.heading ?? 0,
  })

  const combinedLayers = [activeVehiclesLayer]

  return combinedLayers
}

export default useVehiclesLayer
export { VehicleIconType }
