import { mountRootParcel } from "single-spa"
import Parcel from "single-spa-react/parcel"
import { Box } from "@mui/material"
import * as RemoteVizPanel from "@viz/remoteviz"
import "./scss/vizPanel.scss"
import useVehicleDetailsQuery from "@/framework/query/useVehicleDetailsQuery"
import useCurrentSession from "@/framework/hooks/useCurrentSession"
import DragHandle from "@/components/drag-handle/DragHandle"
import useGetElementDimensions from "@/framework/hooks/useGetElementDimensions"

const VizPanel = () => {
  const session = useCurrentSession()
  const { data: vehicle } = useVehicleDetailsQuery()
  const { width, height } = useGetElementDimensions("viz-panel-container")

  return (
    <Box id="viz-panel-container" data-testid="viz-panel-container" data-swapy-item="remote-viz">
      <DragHandle />
      <div data-testid="viz-panel-container_view" className="viz-panel-container_view">
        <Parcel
          config={RemoteVizPanel}
          mountParcel={mountRootParcel}
          vehicle={vehicle}
          vehicleId={vehicle?.vehicle_id}
          sessionId={session?.sessionId}
          browserId={session?.browserId}
          containerWidth={width}
          containerHeight={height}
        />
      </div>
    </Box>
  )
}

export default VizPanel
