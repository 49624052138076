import { FillLayer, LayerProps, LineLayer } from "react-map-gl"
import palette from "@/assets/scss/variables.module.scss"

// For more information on data-driven styles, see https://www.mapbox.com/help/gl-dds-ref/
export const dataLayer: LayerProps = {
  type: "line",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": "#009AFA",
    "line-width": 5,
  },
  interactive: true,
}
export const vehicleLocationsPath: LayerProps = {
  type: "line",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": palette.neutralgray100,
    "line-width": 10,
  },
  interactive: true,
}
export const vehicleLocationsOutlinePath: LayerProps = {
  type: "line",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": palette.neutralgray400,
    "line-width": 12,
  },
  interactive: true,
}

export const uvaldeLineStyle: LineLayer = {
  id: "uvalde",
  type: "line",
  minzoom: 10,
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": [
      // add custom colors here if UX wants
      "match",
      ["get", "highway"],
      "highway",
      "#FFFFFF",
      "residential",
      "#FFFFFF",
      "service",
      "#FFFFFF",
      "raceway",
      "#FFFFFF",
      "#FFFFFF",
    ],
    "line-opacity": [
      "interpolate",
      ["linear"],
      ["zoom"],
      10,
      0, // Opacity at zoom 10
      10.5,
      ["match", ["get", "highway"], "highway", 0.5, "residential", 0, "service", 0, "raceway", 1, 0.5], // Opacity at zoom 10.5
      15,
      ["match", ["get", "highway"], "highway", 1, "residential", 1, "service", 0.75, "raceway", 1, 1], // Opacity at zoom 15
    ],
    "line-opacity-transition": { duration: 200 },
    "line-width": ["match", ["get", "highway"], "highway", 2, "residential", 2, "service", 1, "raceway", 3, 0],
  },
}

export const uvaldeFillStyle: FillLayer = {
  id: "uvalde-fill",
  type: "fill",
  minzoom: 10,
  layout: {},
  paint: {
    "fill-color": "#e5e5e5",
    "fill-opacity": 0.1,
  },
}
