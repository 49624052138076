import { Button, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import "./ActionConfirm.scss"
import useRerouteQueries from "@/framework/query/useRerouteQueries"
import useMapStore, { MapType } from "@/framework/store/useMapStore"
import useActionMutation from "@/framework/query/useActionMutation"
import useRerouteHandler from "@/framework/hooks/useRerouteHandler"
import ActionError from "../action-error/ActionError"
import { getLogger } from "../../../../../framework/contexts/DataDog"
import ActionRerouteModal from "../action-reroute-modal/ActionRerouteModal"

const logger = getLogger()

const ActionConfirm = ({ actionButton, activeActionType, setActiveActionType, setActiveActionMutation }) => {
  const actionMutation = useActionMutation({ action: actionButton.type })

  const [disableButtons, setDisableButtons] = useState(false)

  const { isRerouting } = useRerouteHandler()

  const setRoute = useMapStore((state) => state[MapType.REMOTE_VIZ].setSelectedRoute)

  const handleCancel = () => {
    logger.info(`User canceled confirmation of send ${activeActionType} request`, { action_type: activeActionType })
    setActiveActionType(null)
    setRoute(null)
  }

  const handleOk = () => {
    logger.info(`User acknowledged presented information of send ${activeActionType} request`, {
      action_type: activeActionType,
    })
    setActiveActionType(null)
  }

  const handleSubmit = () => {
    logger.info(`User approved confirmation of send ${activeActionType} request`, { action_type: activeActionType })
    actionMutation.mutate()
    setActiveActionMutation(actionMutation)
    setDisableButtons(true)
  }

  const getActionStatus = (statusCode) => {
    if (statusCode >= 400 && statusCode < 500) return "Invalid"
    else return "Error"
  }

  const handleActionErrorSubmit = () => {
    logger.info(`User acknowledged request error for ${activeActionType} request`, { action_type: activeActionType })
    setDisplayErrorBanner(false)
    setActiveActionType(null)
    setActiveActionMutation(null)
  }

  useEffect(() => {
    if (actionMutation.isError) {
      setDisplayErrorBanner(true)
      logger.error(`User received request error for send ${actionButton?.type} request`, {
        action_button: actionButton,
        action_type: actionButton?.type,
        error_code: error_code,
        error_msg: error_msg,
        action_mutation: actionMutation,
      })
    }

    if (actionMutation.isSuccess) {
      setTimeout(() => {
        setRoute(null)
        setActiveActionType(null)
      }, 1000)
    }
  }, [actionMutation.error, actionMutation.isSuccess])

  const [displayErrorBanner, setDisplayErrorBanner] = useState(false)

  const error_code = actionMutation?.error?.status ? Number(actionMutation?.error?.status) : "Error"

  const error_msg = actionMutation?.error?.body?.detail ?? "Unknown Error"

  const selectedRoute = useMapStore((state) => state[MapType.REMOTE_VIZ].selectedRoute)
  useRerouteQueries(activeActionType == "reroute", actionButton)

  const enabled = actionButton.type === "reroute" ? selectedRoute : true

  const showConfirm = () => (
    <div className="action-confirm" data-action-type={actionButton.type}>
      <ActionRerouteModal
        isOpen={actionButton?.type === "reroute" && isRerouting && activeActionType === "reroute"}
        submitButton={handleSubmit}
        handleCancel={handleCancel}
        enableButton={enabled}
        disableButton={disableButtons}
        selectedRoute={selectedRoute}
      />
      <Typography className="action-confirm__title">Send {actionButton.label} Request</Typography>
      <Typography variant="body1" className="action-confirm__text">
        {actionButton.type === "reroute" && !isRerouting
          ? "There are no alternative routes at this time."
          : actionButton.confirmText}
      </Typography>
      <div className="action-confirm__btns">
        {actionButton.type === "reroute" && !isRerouting ? (
          <Button variant="contained" className="action-confirm__btn--ok" type="reset" onClick={handleOk}>
            OK
          </Button>
        ) : (
          <>
            <Button
              variant="contained"
              className="action-confirm__btn--cancel"
              type="reset"
              onClick={handleCancel}
              disabled={disableButtons}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className="action-confirm__btn--confirm"
              type="submit"
              disabled={!enabled || disableButtons}
              onClick={handleSubmit}
            >
              Send Request
            </Button>
          </>
        )}
      </div>
    </div>
  )

  const showError = () => (
    <ActionError
      actionButton={actionButton}
      action={actionButton.label}
      display={displayErrorBanner}
      actionState={getActionStatus(error_code)}
      onSubmit={handleActionErrorSubmit}
    >
      {`${error_code}: ${error_msg}`}
    </ActionError>
  )

  return (
    <>
      {!displayErrorBanner && showConfirm()}
      {showError()}
    </>
  )
}

export default ActionConfirm
