import "./scss/remoteDashboard.scss"
import MonitorQueue from "../monitor-queue/MonitorQueue"
import SessionsPanel from "../sessions/SessionsPanel"
import AssistanceQueue from "@/features/queues/assistance-queue/AssistanceQueue"
import usePerformanceStore from "@/framework/store/usePerformanceStore"
import { useEffect } from "react"

const RemoteDashboard = () => {
  const performance = usePerformanceStore()

  useEffect(() => {
    performance.endTiming("endSessionToDashboard")
  }, [])

  return (
    <div id="remote-dashboard" className="remote-dashboard-container" data-testid="remote-dashboard-container">
      <div className="remote-dashboard-container__assistance-queue box-container">
        <AssistanceQueue />
      </div>
      <div className="remote-dashboard-container__sessions box-container">
        <SessionsPanel />
      </div>
      <div className="remote-dashboard-container__monitor-queue box-container">
        <MonitorQueue />
      </div>
    </div>
  )
}

export default RemoteDashboard
