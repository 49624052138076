import "./scss/speedLimitSign.scss"

interface SpeedLimitSignProps {
  speed: number
}

const SpeedLimitSign = ({ speed = 55 }: SpeedLimitSignProps) => {
  return (
    <div className="speed-limit-sign">
      <div className="speed-limit-sign__text">
        SPEED
        <br />
        LIMIT
      </div>
      <div className="speed-limit-sign__number">{speed}</div>
    </div>
  )
}

export default SpeedLimitSign
