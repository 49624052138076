import useGetSessions from "@/framework/query/useGetSessions"
import { useRefreshSessionMutation } from "@/framework/query/useRefreshSessionMutation"
import { CircularProgress, Paper, Typography } from "@mui/material"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Box from "@mui/material/Box"
import { ReactNode, useEffect, useState } from "react"
import "./scss/activeSessions.scss"
import useNavigateToMissionSession from "@/framework/hooks/useNavigateToMissionSession"
import useBrowserIdUpdateSubscription from "@/framework/subscriptions/useBrowserIdUpdateSubscription"
import RemoteAssistance from "../remote-assistance/RemoteAssistance"
import { getLogger } from "../../framework/contexts/DataDog"
import { RemotePage, usePage } from "@/framework/contexts/Page"
import { ActiveSession } from "@torc-robotics/vda-sessions-api/graphql/document"
import { useLoadId } from "@/framework/contexts/LoadId"
import StatusIcon from "./StatusIcon"

const logger = getLogger()

function TabPanel(props: { children: ReactNode; value: number; index: number }) {
  const { children, value, index, ...other } = props

  return (
    <div
      className="tab-panel"
      role="tabpanel"
      hidden={value !== index}
      id={`session-tabpanel-${index}`}
      aria-labelledby={`session-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: "11px 13px" }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const SessionsLoading = () => {
  return (
    <div className="active-sessions">
      <Box className="sessions-box" sx={{ width: "fit-content", p: 0 }}>
        <Paper className="sessions-loading">
          <CircularProgress size={80} />
          <p>loading...</p>
        </Paper>
      </Box>
    </div>
  )
}

const changeTab = (
  newTab: number,
  activeSessions: ActiveSession[],
  setTab: (index: number) => void,
  goToSession: (newLoadId: string) => void
) => {
  setTab(newTab)
  goToSession(activeSessions[newTab].loadId)
}

const selectZIndex = (selectedTab: number, index: number, activeSessions: ActiveSession[]) => {
  if (selectedTab == index) {
    return activeSessions.length
  } else {
    return activeSessions.length - Math.abs(selectedTab - index)
  }
}

const setTabStyle = (selectedTab: number, index: number) => {
  let style = "sessions-tab"

  if (index === 0) {
    style += " first-tab"
  }

  if (selectedTab > index) {
    style += " left-tab"
  } else {
    style += " right-tab"
  }

  return style
}

const setTabIconMargin = (selectedTab: number, index: number) => {
  return index >= selectedTab
}

const ActiveSessions = () => {
  useBrowserIdUpdateSubscription()
  useRefreshSessionMutation()

  const navigateToMissionSession = useNavigateToMissionSession()
  const [selectedTab, setSelectedTab] = useState(0)
  const page = usePage()
  const loadId = useLoadId()
  const { query, myActiveSessions } = useGetSessions()

  if (myActiveSessions.length < 1) {
    if (query.isPending || query.isFetching) {
      return <SessionsLoading />
    } else {
      page.setActive(RemotePage.Dashboard)
      return
    }
  }

  useEffect(() => {
    if (loadId.current === "" || myActiveSessions.length === 0) {
      page.setActive(RemotePage.Dashboard)
    }
    const newIndex = myActiveSessions.findIndex((session) => {
      return session.loadId === loadId.current
    })
    if (newIndex >= 0) {
      changeTab(newIndex, myActiveSessions, setSelectedTab, navigateToMissionSession)
    } else {
      logger.debug(`load ID ${loadId.current} not found in active sessions`, {
        sessions: myActiveSessions,
        loadId: loadId.current,
      })
      page.setActive(RemotePage.Dashboard)
    }
  }, [loadId.current, myActiveSessions])

  return (
    <div className="active-sessions">
      <Box className="sessions-box" sx={{ width: "fit-content", p: 0 }}>
        <Tabs
          className="sessions-tabs"
          value={selectedTab}
          onChange={(e, newTab: number) =>
            changeTab(newTab, myActiveSessions, setSelectedTab, navigateToMissionSession)
          }
          variant="scrollable"
          scrollButtons="auto"
        >
          {myActiveSessions.map((session, index) => (
            <Tab
              className={setTabStyle(selectedTab, index)}
              label={
                <span className="tab-label">
                  <span className="tab-text">{session.loadId}</span>
                  <StatusIcon
                    key={session.loadId}
                    loadId={session.loadId}
                    noMargin={setTabIconMargin(selectedTab, index)}
                  />
                </span>
              }
              key={index}
              sx={{ zIndex: selectZIndex(selectedTab, index, myActiveSessions) }}
            />
          ))}
        </Tabs>
        {myActiveSessions.map((session, i) => (
          <TabPanel value={0} index={i} key={session.sessionId}>
            <RemoteAssistance key={session?.sessionId} />
          </TabPanel>
        ))}
      </Box>
    </div>
  )
}

export default ActiveSessions
