/* istanbul ignore file */
import Map, { Layer, Popup, Source, useControl } from "react-map-gl"
import config from "../../config"
import { Signer } from "@aws-amplify/core"
import maplibregl from "maplibre-gl"
import { useEffect } from "react"
import useMapStore, { MapType } from "../../framework/store/useMapStore"
import LoadScreen from "../../framework/utils/LoadScreen"
import useMissionsRouteGeoJson from "../../framework/hooks/useMissionsRouteGeoJson"
import { dataLayer, uvaldeFillStyle, uvaldeLineStyle } from "./mapStyle"
import { MapboxOverlay } from "@deck.gl/mapbox/typed"
import useVehicleLayer from "../../framework/layers/useVehicleLayer"
import { useGeoCreds } from "../../framework/hooks/UserHooks"
import { useGetVehicleId } from "../../framework/hooks/useGetVehicleId"
import uvaldeGeoJson from "./uvalde.geo.json"
import "./scss/mapPanel.scss"
import useGetElementDimensions from "@/framework/hooks/useGetElementDimensions"
import useMapViewHandler from "@/framework/hooks/useMapViewHandler"
import useRerouteLayers from "@/framework/layers/useRerouteLayers"
import useRerouteHandler from "@/framework/hooks/useRerouteHandler"
import { createPortal } from "react-dom"
import { Box } from "@mui/material"
import MapVelocityDisplay from "./MapVelocityDisplay"
import MapRouteProgressDisplay from "./MapRouteProgressDisplay"
import MapEngageStateDisplay from "./MapEngageStateDisplay"
import useVehicleStore from "@/framework/store/useVehicleStore"

const initViewstate = {
  longitude: -98.5795,
  latitude: 39.8283,
  zoom: 4,
}

const RemoteVizMap = () => {
  const mapRef = useMapStore((state) => state[MapType.REMOTE_VIZ].mapRef)
  const setMapLoaded = useMapStore((state) => state[MapType.REMOTE_VIZ].setLoaded)
  const geoCreds = useGeoCreds()
  const selectedRoute = useMapStore((state) => state[MapType.REMOTE_VIZ].selectedRoute)
  const setRoute = useMapStore((state) => state[MapType.REMOTE_VIZ].setSelectedRoute)

  const { isRerouting } = useRerouteHandler()
  const mapModalPortal = document.getElementById("reroute-map-modal")

  useMapResizeListener("map-panel-container")

  const vehicleLayer = useVehicleLayer()
  const rerouteLayers = useRerouteLayers()

  const routeData = useMissionsRouteGeoJson()

  useMapViewHandler(MapType.REMOTE_VIZ)

  const vehicleStatus = useVehicleStore((state) => state.vehicles.entities[useGetVehicleId()] ?? null)

  const transformRequest = (url, resourceType) => {
    if (resourceType === "Style" && url.indexOf("https://maps.geo.us-east-1.amazonaws.com") > -1) {
      url = `${config.AWS_MAP_URL}`
    }
    if (url.includes("amazonaws.com")) {
      return {
        url: Signer.signUrl(url, {
          access_key: geoCreds.accessKeyId,
          secret_key: geoCreds.secretAccessKey,
          session_token: geoCreds.sessionToken,
        }),
      }
    }
    return { url: url || "" }
  }

  return geoCreds ? (
    <>
      {isRerouting && mapModalPortal ? (
        createPortal(
          <Box id="map-panel-container" data-testid="map-panel-modal-container">
            <Map
              ref={mapRef}
              onLoad={setMapLoaded}
              id="mappanel"
              initialViewState={initViewstate}
              dragRotate={false}
              touchRotate={false}
              mapStyle={`https://maps.geo.us-east-1.amazonaws.com/maps/v0/maps/nav-map-esri/style-descriptor`}
              mapLib={maplibregl}
              transformRequest={transformRequest}
              attributionControl={false}
            >
              <Source id="uvalde" type="geojson" data={uvaldeGeoJson}>
                <Layer {...uvaldeFillStyle} />
                <Layer {...uvaldeLineStyle} />
              </Source>
              {routeData ? (
                <Source id="geojsonRoute" type="geojson" data={routeData}>
                  <Layer {...dataLayer} />
                </Source>
              ) : (
                <></>
              )}
              {rerouteLayers.map(
                (geoJsonLayer) =>
                  geoJsonLayer[0].props.routeCallOut.coordinate && (
                    <Popup
                      key={geoJsonLayer[0].props.routeCallOut.routeId}
                      longitude={geoJsonLayer[0].props.routeCallOut.coordinate.lon}
                      latitude={geoJsonLayer[0].props.routeCallOut.coordinate.lat}
                      closeButton={false}
                      closeOnClick={false}
                      style={{ zIndex: 2 }}
                      className={
                        "reroute-popup" +
                        (selectedRoute?.routeId === geoJsonLayer[0].props.routeCallOut.routeId ? " selected-route" : "")
                      }
                    >
                      <div
                        className={"popup-clicker"}
                        onClick={() =>
                          setRoute({
                            routeId: geoJsonLayer[0].props.routeCallOut.routeId,
                            estimated_time_minutes:
                              geoJsonLayer[0].props.routeCallOut.summary_info.estimated_time_minutes,
                            length_miles: geoJsonLayer[0].props.routeCallOut.summary_info.length_miles,
                          })
                        }
                      >
                        {geoJsonLayer[0].props.routeCallOut.summary_info.length_miles} mi,{" "}
                        {geoJsonLayer[0].props.routeCallOut.summary_info.estimated_time_minutes} min route
                      </div>
                    </Popup>
                  )
              )}
              <DeckGLOverlay layers={[vehicleLayer, rerouteLayers]} />
              <MapEngageStateDisplay adsState={vehicleStatus?.data.mode} />
              <MapVelocityDisplay
                vehicleSpeed={vehicleStatus?.data.speed ? Math.round((vehicleStatus?.data.speed ?? 0) * 2.237) : 0}
              />
            </Map>
          </Box>,
          mapModalPortal
        )
      ) : (
        <Map
          ref={mapRef}
          onLoad={setMapLoaded}
          id="mappanel"
          initialViewState={initViewstate}
          dragRotate={false}
          touchRotate={false}
          mapStyle={`https://maps.geo.us-east-1.amazonaws.com/maps/v0/maps/nav-map-esri/style-descriptor`}
          mapLib={maplibregl}
          transformRequest={transformRequest}
          attributionControl={false}
        >
          {routeData ? (
            <Source id="geojsonRoute" type="geojson" data={routeData}>
              <Layer {...dataLayer} />
            </Source>
          ) : (
            <></>
          )}
          <Source id="uvalde" type="geojson" data={uvaldeGeoJson}>
            <Layer {...uvaldeFillStyle} />
            <Layer {...uvaldeLineStyle} />
          </Source>
          <MapEngageStateDisplay adsState={vehicleStatus?.data.mode} />
          <MapVelocityDisplay
            vehicleSpeed={vehicleStatus?.data.speed ? Math.round((vehicleStatus?.data.speed ?? 0) * 2.237) : 0}
          />
          <MapRouteProgressDisplay />
        </Map>
      )}
    </>
  ) : (
    <LoadScreen />
  )
}

const DeckGLOverlay = (props) => {
  const overlay = useControl(() => new MapboxOverlay(props))
  overlay.setProps(props)
  return null
}

const useMapResizeListener = (elementId) => {
  const mapRef = useMapStore((state) => state[MapType.REMOTE_VIZ].mapRef)
  const { width, height } = useGetElementDimensions(elementId)
  useEffect(() => {
    mapRef.current?.resize()
  }, [width, height])
}

export default RemoteVizMap
export { DeckGLOverlay, initViewstate }
