import { useMemo } from "react"
import useGetMissionRoute from "../query/useGetMissionRoute"
import { getLogger } from "../../framework/contexts/DataDog"

const logger = getLogger()

interface GeoJson {
  type: string
  properties: Record<string, unknown>
  geometry: {
    type: string
    coordinates: number[][]
  }
}

const useMissionsRouteGeoJson = () => {
  const { routeAdvisorRoute } = useGetMissionRoute()

  const geojson = useMemo(() => {
    // To support multi lines look here: https://docs.mapbox.com/mapbox-gl-js/example/zoomto-linestring/
    const geoData: GeoJson = {
      type: "Feature",
      properties: {},
      geometry: {
        type: "LineString",
        coordinates: [],
      },
    }

    if (routeAdvisorRoute.data?.latlons === undefined) {
      return geoData
    }
    const coordinates = routeAdvisorRoute.data?.latlons?.reduce((acc: number[][], latlon) => {
      if (
        latlon.lat === undefined ||
        latlon.lon === undefined ||
        isNaN(latlon.lat) ||
        isNaN(latlon.lon) ||
        latlon.lat > 90 ||
        latlon.lat < -90 ||
        latlon.lon > 180 ||
        latlon.lon < -180
      ) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        logger.warn(`Invalid latlon ${latlon}, all latlons: ${routeAdvisorRoute.data?.latlons}`)
        return acc
      }
      return [...acc, [latlon.lon, latlon.lat]]
    }, [])

    return {
      ...geoData,
      geometry: {
        ...geoData.geometry,
        coordinates,
      },
    }
  }, [routeAdvisorRoute.data])

  if (geojson.geometry.coordinates.length === 0) {
    return null
  }

  return geojson
}

export default useMissionsRouteGeoJson
