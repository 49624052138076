import { useState } from "react"
import RemoteHeader from "../remote-header/RemoteHeader"
import { usePage } from "@/framework/contexts/Page"
import LoadIdContext from "@/framework/contexts/LoadId"

const RemoteRoot = () => {
  const [currentLoadId, setCurrentLoadId] = useState("")
  const page = usePage()
  return (
    <LoadIdContext.Provider value={{ current: currentLoadId, setCurrent: setCurrentLoadId }}>
      <RemoteHeader />
      {page.getActive()}
    </LoadIdContext.Provider>
  )
}

export default RemoteRoot
