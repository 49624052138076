/* istanbul ignore file */
import { useEffect } from "react"
import { MapboxOverlay } from "@deck.gl/mapbox/typed"
import { Layer, Map, NavigationControl, Source, useControl } from "react-map-gl"
import maplibregl, { GeoJSONFeature } from "maplibre-gl"
import { useGeoCreds } from "@/framework/hooks/UserHooks"
import CenterMapIcon from "@/assets/truck-icons/center-map.svg"
import useMissionsRouteGeoJson from "@/framework/hooks/useMissionsRouteGeoJson"
import {
  dataLayer,
  uvaldeFillStyle,
  uvaldeLineStyle,
  vehicleLocationsOutlinePath,
  vehicleLocationsPath,
} from "@/components/map-panel/mapStyle"
import { initViewstate } from "@/components/map-panel/RemoteVizMap"
import config from "@/config"
import { Signer } from "aws-amplify"
import useMapStore, { MapType } from "@/framework/store/useMapStore"
import useMapViewHandler from "@/framework/hooks/useMapViewHandler"
import useMissionQuery from "@/framework/query/useMissionQuery"
import useVehiclesLayer, { VehicleIconType } from "@/framework/layers/useVehiclesLayer"
import useVehiclePathLayer from "@/framework/layers/useVehiclePathLayer"
import uvaldeGeoJson from "@/components/map-panel/uvalde.geo.json"
import { MapboxOverlayProps } from "@deck.gl/mapbox/typed"
import { IconButton } from "@mui/material"
import SDTLocalTime from "./SDTLocalTime"
import "./MissionMap.scss"

const MissionMap = () => {
  const mapRef = useMapStore((state) => state[MapType.MISSION_DETAILS].mapRef)
  const setMapLoaded = useMapStore((state) => state[MapType.MISSION_DETAILS].setLoaded)
  const resetLoaded = useMapStore((state) => state[MapType.MISSION_DETAILS].resetLoaded)
  const geoCreds = useGeoCreds()

  const mission = useMissionQuery().data?.missions?.[0]
  const vehicleId = mission?.vehicle_id

  const vehicleIconType = VehicleIconType.OPERATIONAL

  const vehiclesLayer = useVehiclesLayer(MapType.MISSION_DETAILS, vehicleIconType, [vehicleId])
  const vehiclePathLayer = useVehiclePathLayer()

  const transformRequest = (url: string, resourceType: string) => {
    if (resourceType === "Style" && url.indexOf("https://maps.geo.us-east-1.amazonaws.com") > -1) {
      url = `${config.AWS_MAP_URL}`
    }
    if (url.includes("amazonaws.com")) {
      return {
        url: Signer.signUrl(url, {
          access_key: geoCreds?.accessKeyId,
          secret_key: geoCreds?.secretAccessKey,
          session_token: geoCreds?.sessionToken,
        }),
      }
    }
    return { url: url || "" }
  }

  const routeData = useMissionsRouteGeoJson() as GeoJSONFeature | null

  const { centerMap } = useMapViewHandler(MapType.MISSION_DETAILS)

  useEffect(() => {
    return () => {
      resetLoaded()
    }
  }, [])

  if (!geoCreds) {
    return null
  }

  return (
    <div className="mission-details-container__dist-travel-map-panel" data-testid="mission-map">
      <SDTLocalTime />
      <Map
        // @ts-expect-error - ref type is not correct
        ref={mapRef}
        onLoad={setMapLoaded}
        initialViewState={initViewstate}
        // @ts-expect-error - maplibregl is not typed
        mapLib={maplibregl}
        transformRequest={transformRequest}
        dragRotate={false}
        mapStyle={`https://maps.geo.us-east-1.amazonaws.com/maps/v0/maps/nav-map-esri/style-descriptor`}
        attributionControl={false}
      >
        <div className="map-options">
          <IconButton disableRipple color="inherit" onClick={() => centerMap()}>
            <img className="center-map-icon" src={CenterMapIcon} />
          </IconButton>
          <NavigationControl showCompass={false} data-testid="map-controls-button" />
        </div>
        <Source id="uvalde" type="geojson" data={uvaldeGeoJson}>
          <Layer {...uvaldeFillStyle} />
          <Layer {...uvaldeLineStyle} />
        </Source>
        {routeData !== null && (
          <Source id="current-route" type="geojson" data={routeData}>
            <Layer {...dataLayer} />
          </Source>
        )}
        <Source id="vehicle-locations" type="geojson" data={vehiclePathLayer}>
          <Layer {...vehicleLocationsOutlinePath} />
          <Layer {...vehicleLocationsPath} />
        </Source>
        <DeckGLOverlay layers={[vehiclesLayer]} />
      </Map>
    </div>
  )
}

const DeckGLOverlay = (props: MapboxOverlayProps) => {
  const overlay = useControl(() => new MapboxOverlay(props))
  overlay.setProps(props)
  return null
}

export default MissionMap
