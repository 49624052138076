import { useState, ChangeEvent } from "react"
import { Button, Modal, Typography, Box } from "@mui/material"
import "./CreateMissionLogModal.scss"
import { getLogger } from "@/framework/contexts/DataDog"
import usePostMissionLogMutation from "@/framework/query/usePostMissionLog"

const logger = getLogger()

interface CreateMissionLogModalProps {
  isOpen: boolean
  onCancel: () => void
  onComplete: () => void
}

const CreateMissionLogModal = (props: CreateMissionLogModalProps) => {
  const { isOpen, onCancel, onComplete } = props
  const postMissionLogMutation = usePostMissionLogMutation()
  const [logBody, setLogBody] = useState("")

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setLogBody(event.target.value)
  }

  const submitMissionLog = () => {
    if (logBody && logBody.length > 0) {
      postMissionLogMutation.mutate({ body: logBody })
    } else {
      logger.debug("No additional details to log")
    }
    onComplete()
  }

  return (
    <Modal open={isOpen} onClose={onCancel} className="create-note-modal">
      <Box className="create-note-modal_box">
        <Typography className="create-note-modal_box-title">Session Notes</Typography>
        <Typography className="create-note-modal_box-description">Please add session notes.</Typography>
        <textarea
          value={logBody}
          onChange={handleChange}
          className="create-note-modal_box-body"
          rows="4"
          cols="50"
        ></textarea>
        <Button className="create-note-modal_box-cancel" onClick={onCancel}>
          Cancel
        </Button>
        <Button className="create-note-modal_box-submit" onClick={submitMissionLog}>
          Submit & Complete
        </Button>
      </Box>
    </Modal>
  )
}

export default CreateMissionLogModal
