import { cloneElement, useLayoutEffect, useRef, useState } from "react"
import useMapStore from "@/framework/store/useMapStore"

/**
 * Handles placing the hover component on the map, ensures it is not off screen
 *
 * @param {hoverInfo} object - object containing information about the hover event
 * @param {hoverInfo.component} component - component to render in the hover div
 * @param {hoverInfo.object} object - object that was hovered over
 * @param {hoverInfo.x} number - x coordinate of the hover event
 * @param {hoverInfo.y} number - y coordinate of the hover event
 * @returns
 */
const HoverWrapper = ({ hoverInfo, mapType }) => {
  const ref = useRef(null)
  const [hoverXY, setHoverXY] = useState({ x: 0, y: 0 })
  const { width: mapWidth, height: mapHeight } = useMapStore((state) => state[mapType].containerSize)

  const component = cloneElement(hoverInfo.component, { object: hoverInfo.object })

  const mapMargin = 20

  useLayoutEffect(() => {
    if (!ref || !ref.current) return

    const { height, width } = ref.current.getBoundingClientRect()

    // i don't understand the x positioning here... but it works ish
    const maxX = mapWidth - width
    let updatedX = hoverInfo.x
    if (hoverInfo.x > maxX) {
      updatedX = maxX
    } else if (hoverInfo.x < width / 2) {
      updatedX = width / 2
    }
    const hoverDivLeft = updatedX >= maxX ? maxX : updatedX - width / 2

    let hoverDivTop
    // Place the hover div above the hover object
    hoverDivTop = hoverInfo.y - height - mapMargin

    setHoverXY({ x: hoverDivLeft, y: hoverDivTop })
  }, [mapWidth, mapHeight, hoverInfo.x, hoverInfo.y])

  return (
    <div
      ref={ref}
      style={{
        position: "absolute",
        left: hoverXY.x,
        top: hoverXY.y,
        padding: "0px",
        margin: "0px",
        zIndex: 3,
      }}
    >
      {component}
    </div>
  )
}

export default HoverWrapper
