import { gql, useSubscription } from "@apollo/client"
import { useQueryClient } from "@tanstack/react-query"
import {
  Conference,
  OnConferenceEventDocument,
  OnConferenceEventSubscription,
  OnConferenceEventSubscriptionVariables,
  GetActiveConferencesQuery,
  ConferenceStatus,
} from "@torc-robotics/vda-calls-api/graphql/document"
import { GraphQLResponse } from "../api/RemoteAssistanceAPIGraphQL"
import { getLogger } from "../../framework/contexts/DataDog"

const logger = getLogger()

const CONFERENCE_EVENT_SUBSCRIPTION = gql`
  ${OnConferenceEventDocument}
`

const useCallEventSubscriptions = () => {
  const queryClient = useQueryClient()

  const conferenceEventSubscription = useSubscription<
    OnConferenceEventSubscription,
    OnConferenceEventSubscriptionVariables
  >(CONFERENCE_EVENT_SUBSCRIPTION, {
    context: { clientName: "callEvents" },
    onData: ({ data: { data } }) => {
      if (!data?.onConferenceEvent) {
        return
      }
      const event = data.onConferenceEvent
      logger.debug(`call event for ${event.conferenceUuid} of type ${event.eventType}`, event)

      queryClient.setQueryData<GraphQLResponse<GetActiveConferencesQuery>>(["getConferenceCalls"], (prev) => {
        if (event.eventType == "CONFERENCE_STARTED") {
          logger.debug(`adding conference ${event.conferenceUuid} to list`)
          const newConf: Conference = {
            conferenceUuid: event.conferenceUuid,
            conferenceName: event.conferenceName,
            fromNumber: event.fromNumber ?? "",
            toNumber: event.toNumber ?? "",
            status: ConferenceStatus.Active,
            tenant: event.tenant,
            callUuid: event.callUuid,
          }
          return {
            data: {
              getActiveConferences: [
                ...(prev?.data?.getActiveConferences.filter((s) => event.conferenceUuid !== s?.conferenceUuid) || []),
                newConf,
              ],
            },
          }
        } else if (event.eventType == "CONFERENCE_ENDED") {
          logger.debug(`removing conference ${event.conferenceUuid} from list`)
          return {
            data: {
              getActiveConferences: [
                ...(prev?.data?.getActiveConferences.filter((s) => event.conferenceUuid !== s?.conferenceUuid) || []),
              ],
            },
          }
        } else {
          logger.debug(`ignoring event type ${event.eventType} for conference ${event.conferenceUuid}`)
        }
      })
    },
  })

  return { conferenceEventSubscription }
}

export default useCallEventSubscriptions
