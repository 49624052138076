import { useParams } from "react-router-dom"
import useCurrentSession from "./useCurrentSession"

const useGetLoadId = () => {
  const { loadId: loadIdV1 } = useParams()
  const session = useCurrentSession()

  const loadId = session?.loadId || loadIdV1
  return loadId
}

export default useGetLoadId
