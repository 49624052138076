import useActionMutation from "@/framework/query/useActionMutation"
import useInferredVehicleState from "@/framework/hooks/useInferredVehicleState"
import Button from "@mui/material/Button"
import "./scss/actionButton.scss"
import { getLogger } from "../../../../../framework/contexts/DataDog"
import { datadogRum } from "@datadog/browser-rum"

const logger = getLogger()

interface ActionButton {
  label: string
  type: ActionType
  confirmText: string
}

type ActionType = "stop" | "resume" | "reroute"

interface ActionButtonProps {
  actionButton: ActionButton
  activeActionType: ActionType | null
  setActiveActionType: (type: ActionType | null | ((prev: ActionType | null) => ActionType | null)) => void
}

const ActionButton = ({ actionButton, activeActionType, setActiveActionType }: ActionButtonProps) => {
  const actionMutation = useActionMutation({ action: actionButton.type })

  const vehicleLogState = useInferredVehicleState()

  const vehicleIsStopped =
    vehicleLogState.motionState?.key === "STOP-REQUEST-COMPLETED" ||
    vehicleLogState.motionState?.key === "STOP-REQUEST-APPROVED" ||
    (vehicleLogState.motionState?.type === "resume" &&
      !(
        vehicleLogState.motionState?.key === "RESUME-REQUEST-COMPLETED" ||
        vehicleLogState.motionState?.key === "RESUME-REQUEST-APPROVED"
      ))

  const requestIsPending =
    (vehicleLogState.motionState?.key && vehicleLogState.motionState?.value < 100) ||
    (vehicleLogState.rerouteState?.key && vehicleLogState.rerouteState?.value < 100)

  const shouldDisabled =
    (actionButton.type === "resume" && !vehicleIsStopped) || (actionButton.type === "stop" && vehicleIsStopped)
  const disabled =
    shouldDisabled ||
    actionMutation.isPending ||
    actionMutation.isSuccess ||
    actionMutation.isError ||
    !!requestIsPending ||
    (activeActionType && activeActionType !== actionButton.type)

  const handleClick = () => {
    datadogRum.addAction("SDT-Request", {
      request_type: actionButton?.type,
    })
    logger.info(`User selected send ${actionButton?.type} request`, {
      action_button: actionButton,
      action_type: actionButton?.type,
    })
    setActiveActionType((prev) => (prev !== actionButton.type ? actionButton.type : null))
  }

  return (
    <Button className="action-button" variant="contained" onClick={handleClick} disabled={!!disabled}>
      {`Send ${actionButton.label} Request`}
    </Button>
  )
}

export default ActionButton
