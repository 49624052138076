import { useQuery } from "@tanstack/react-query"
import useGetMissionSelectedRoute from "./useGetMissionSelectedRoute"
import RouteAdvisorAPI from "../api/RouteAdvisorAPI"

const useGetMissionRoute = () => {
  const missionRoute = useGetMissionSelectedRoute()

  const routeAdvisorRoute = useQuery({
    queryKey: ["routeAdvisor"],
    queryFn: /* istanbul ignore next */ async () =>
      await RouteAdvisorAPI.getRouteById({
        routeId: missionRoute.data?.[0]?.route_id ?? "",
      }),
    enabled: !!missionRoute.data?.[0]?.route_id,
  })

  return {
    missionRoute,
    routeAdvisorRoute,
  }
}

export default useGetMissionRoute
