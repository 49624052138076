import { useInfiniteQuery } from "@tanstack/react-query"
import useMissionQuery from "./useMissionQuery"
import parse from "parse-link-header"
import getMissionLogsV2, { MissionLogsResponse } from "../api/getMissionLogsV2"

interface ParsedLink {
  url: string
  rel: string
  [param: string]: string
}

interface LinkParseResult {
  first?: ParsedLink
  next?: ParsedLink
  prev?: ParsedLink
  [rel: string]: ParsedLink | undefined
}

const useGetMissionHistoryLog = ({ pageSize = 20 }: { pageSize?: number } = {}) => {
  const missionQuery = useMissionQuery()
  const missionUuid = missionQuery.data?.missions?.[0]?.uuid
  const missionsHistoryLogQuery = useInfiniteQuery({
    queryKey: ["mission-history-log", missionUuid, pageSize],
    refetchInterval: 1000,
    refetchIntervalInBackground: true,
    initialPageParam: undefined,
    getNextPageParam: (lastPage: MissionLogsResponse) => {
      try {
        const linkHeader = lastPage.headers.get("link")
        if (!linkHeader) return undefined

        const links = parse(linkHeader) as LinkParseResult | null
        if (!links?.next?.url) return undefined

        const url = new URL(links.next.url)
        return url.searchParams.get("cursor")
      } catch (error) {
        return undefined
      }
    },
    queryFn: /* istanbul ignore next */ async ({ pageParam }) => {
      const response = await getMissionLogsV2({
        missionUuid: missionUuid ?? "",
        count: pageSize,
        cursor: pageParam,
        sort: "desc",
      })

      return response
    },
    enabled: !!missionUuid,
  })

  return missionsHistoryLogQuery
}

export default useGetMissionHistoryLog
