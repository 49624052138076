import useGetSessions from "../query/useGetSessions"
import { useLoadId } from "../contexts/LoadId"

/*
 * This hook is used to get the current session from the location state.
 */
const useCurrentSession = () => {
  const loadId = useLoadId().current

  const { myActiveSessions } = useGetSessions()
  const currentSession = myActiveSessions.find((session) => session.loadId === loadId)

  return currentSession
}

export default useCurrentSession
