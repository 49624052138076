import { Divider } from "@mui/material"
import "./MissionLoadDetails.scss"
import useMissionQuery from "@/framework/query/useMissionQuery"
import useMissionRouteProgressQuery from "@/framework/query/useMissionRouteProgressQuery"
import useVehicleStore from "@/framework/store/useVehicleStore"
import useVehicleDetailsQuery from "@/framework/query/useVehicleDetailsQuery"
import { getLogger } from "@/framework/contexts/DataDog"

const logger = getLogger()

const MissionLoadDetails = () => {
  const missionsQuery = useMissionQuery()
  const mission = missionsQuery?.data?.missions?.[0] ?? undefined
  logger.info(`mission load=${mission?.load_id ?? ""}, vehicle=${mission?.vehicle_id ?? ""}`)
  const missionProgressQuery = useMissionRouteProgressQuery(mission?.uuid ?? "")
  const vehicleQuery = useVehicleDetailsQuery().data
  const missionProgress = missionProgressQuery.data
  const vehicleStore = useVehicleStore((state) => state.vehicles.entities[mission?.vehicle_id ?? ""])?.data

  const carrier = mission?.tenant_id ?? "--"
  const destination = mission?.destination_display ?? "--"
  const fuelTankSize =
    vehicleStore?.fuel_capacity && vehicleStore.fuel_capacity > 0
      ? vehicleStore?.fuel_capacity
      : vehicleQuery?.fuel_tank_size ?? 0
  const fuelPercent = vehicleStore?.fuel_level ? vehicleStore?.fuel_level / 100 : 0
  logger.info(`fuel from api tank=${fuelTankSize} and percent=${fuelPercent}`)
  const remainingFuel = fuelTankSize * fuelPercent > 0 ? Math.floor(fuelTankSize * fuelPercent) : "--"
  const estFuelNeeded = Math.ceil(
    ((missionProgress?.total_route_distance_miles ?? 0) - (missionProgress?.total_distance_traveled_miles ?? 0)) / 7
  )

  return (
    <div className="mission-details-container__load-details-panel">
      <h4 className="load-details-panel__header">Load Details</h4>
      <dl className="load-details-panel__content">
        <div className="load-details-panel__content-item">
          <dt className="load-details-panel__content-item--label">Carrier</dt>
          <dd className="load-details-panel__content-item--value">{carrier}</dd>
        </div>
        <Divider orientation="vertical" flexItem />
        <div className="load-details-panel__content-item">
          <dt className="load-details-panel__content-item--label">Destination</dt>
          <dd className="load-details-panel__content-item--value">{destination}</dd>
        </div>
        <Divider orientation="vertical" flexItem />
        <div className="load-details-panel__content-item">
          <dt className="load-details-panel__content-item--label">Remaining Fuel</dt>
          <dd className="load-details-panel__content-item--value">{remainingFuel} Gallons</dd>
        </div>
        <Divider orientation="vertical" flexItem />
        <div className="load-details-panel__content-item">
          <dt className="load-details-panel__content-item--label">Est. Fuel Needed</dt>
          <dd className="load-details-panel__content-item--value">
            {estFuelNeeded > 0 ? estFuelNeeded : "--"} Gallons
          </dd>
        </div>
      </dl>
    </div>
  )
}

export default MissionLoadDetails
