import { Box, Typography } from "@mui/material"

const MapVelocityDisplay = ({ vehicleSpeed }) => {
  return (
    <Box className="velocity-display" textAlign={"center"}>
      <Typography className="speed" data-testid={"velocity"}>
        {vehicleSpeed}
      </Typography>
      <Typography className="unit" data-testid={"unit"}>
        (MPH)
      </Typography>
    </Box>
  )
}

export default MapVelocityDisplay
