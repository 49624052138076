import { initViewstate } from "@/components/remote-dashboard-map/RemoteDashboardMap"
import useMapStore, { MapType } from "@/framework/store/useMapStore"
import useVehicleStore from "@/framework/store/useVehicleStore"
import { useEffect, useMemo, useRef } from "react"
import { getLogger } from "../../framework/contexts/DataDog"

const logger = getLogger()

/**
 * useCenteredVehicle - handles all aspects of centering the map on a vehicle
 */
const useCenteredVehicle = (mapType: MapType, vehicleId: string) => {
  const mapRef = useMapStore((state) => state[mapType].mapRef.current)
  const selectedVehicle = useVehicleStore((state) => state.vehicles.entities[vehicleId] ?? null)

  // vehicleCenter is the center of the vehicle, if it exists, otherwise null
  const vehicleCenter = useMemo((): [number, number] | null => {
    if (!selectedVehicle?.data?.coordinates?.longitude || !selectedVehicle?.data?.coordinates?.latitude) {
      return null
    } else {
      return [selectedVehicle?.data?.coordinates?.longitude, selectedVehicle?.data?.coordinates?.latitude]
    }
  }, [selectedVehicle?.data?.coordinates?.longitude, selectedVehicle?.data?.coordinates?.latitude])
  const prevVehicleCenter = useRef(vehicleCenter)

  // isCentered is a boolean that determines if the map should be centered on the vehicle
  const isCentered = useMapStore((state) => state[mapType].isCentered)
  const prevIsCentered = useRef(isCentered)
  const setIsCentered = useMapStore((state) => state[mapType].setCentered)

  if (setIsCentered === undefined) {
    logger.error("setIsCentered is undefined. This is likely due to an invalid mapType.")
    return
  }

  // handles setting isCentered to true when vehicleCenter is set or false when vehicleCenter is null
  useEffect(() => {
    vehicleCenter && !prevVehicleCenter.current && setIsCentered(true)
    !vehicleCenter && setIsCentered(false)
    prevVehicleCenter.current = vehicleCenter
  }, [vehicleCenter])

  // handles centering the map on init viewstate or vehicleCenter (with or without zoom)
  useEffect(() => {
    if (!mapRef || !mapRef.loaded()) {
      return
    }
    if (!vehicleCenter) {
      logger.log("jumping to init viewstate")
      mapRef.jumpTo({
        center: [initViewstate.longitude, initViewstate.latitude],
        zoom: initViewstate.zoom,
      })
    } else if (vehicleCenter && isCentered) {
      mapRef.jumpTo({
        center: vehicleCenter,
        ...(prevIsCentered.current !== isCentered ? { zoom: 14 } : {}),
      })
    }
    prevIsCentered.current = isCentered
  }, [vehicleCenter, isCentered])
}
export default useCenteredVehicle
