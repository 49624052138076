import { gql, useSubscription } from "@apollo/client"
import { useQueryClient } from "@tanstack/react-query"
import {
  OnStartSessionDocument,
  OnStartSessionSubscription,
  OnStartSessionSubscriptionVariables,
  OnEndSessionDocument,
  OnEndSessionSubscription,
  OnEndSessionSubscriptionVariables,
  GetSessionsQuery,
} from "@torc-robotics/vda-sessions-api/graphql/document"
import { GraphQLResponse } from "../api/SessionsAPIGraphQL"
import { getLogger } from "../../framework/contexts/DataDog"

const logger = getLogger()

const START_SESSION_SUBSCRIPTION = gql`
  ${OnStartSessionDocument}
`

const END_SESSION_SUBSCRIPTION = gql`
  ${OnEndSessionDocument}
`

const useSessionSubscriptions = () => {
  const queryClient = useQueryClient()

  const startSessionSubscription = useSubscription<OnStartSessionSubscription, OnStartSessionSubscriptionVariables>(
    START_SESSION_SUBSCRIPTION,

    {
      context: { clientName: "sessions" },
      onData: ({ data: { data } }) => {
        if (!data?.onStartSession) {
          return
        }

        logger.debug(`session for mission ${data?.onStartSession?.loadId} started`, data)

        const session = data?.onStartSession

        queryClient.setQueryData<GraphQLResponse<GetSessionsQuery>>(["getSessions"], (prev) => {
          const existingSession = prev?.data?.getSessions.find((s) => s.loadId === session?.loadId)
          if (
            (existingSession && existingSession?.createdTs < session?.createdTs) ||
            session?.sessionId === existingSession?.sessionId
          ) {
            return prev
          }

          return {
            data: {
              getSessions: [...(prev?.data?.getSessions || []), session],
            },
          }
        })
      },
    }
  )

  const endSessionSubscription = useSubscription<OnEndSessionSubscription, OnEndSessionSubscriptionVariables>(
    END_SESSION_SUBSCRIPTION,
    {
      context: { clientName: "sessions" },
      onData: ({ data: { data } }) => {
        if (!data?.onEndSession) {
          return
        }

        logger.debug(`session for mission ${data?.onEndSession?.loadId} ended`, data)

        const session = data?.onEndSession

        queryClient.setQueryData<GraphQLResponse<GetSessionsQuery>>(["getSessions"], (prev) => {
          return {
            data: {
              getSessions: prev?.data?.getSessions.filter((s) => s.sessionId !== session?.sessionId) || [],
            },
          }
        })
      },
    }
  )

  return { startSessionSubscription, endSessionSubscription }
}

export default useSessionSubscriptions
