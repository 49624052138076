import ScenarioDetectedBanner from "@/components/scenario-detected-banner/ScenarioDetectedBanner"
import "./scss/scenarioDetectionPanel.scss"
import { Box, Button, Stack } from "@mui/material"
import SpeedLimitSign from "@/components/speed-limit-sign/SpeedLimitSign"
import ConfidenceBadge from "@/components/confidence-badge/ConfidenceBadge"
import { confidenceToPercentage, formatLatLon, getFormattedTime } from "@/framework/utils/Utils"
import { RemoteAssistanceEvent, DetectedScenario } from "@torc-robotics/vda-remote-assistance-api/graphql/document"
import useAcknowledgeEventMutation from "@/framework/query/useAcknowledgeEventMutation"
import { useEffect, useState } from "react"

interface ScenarioDetectionPanelProps {
  event: RemoteAssistanceEvent & { eventDetails: DetectedScenario }
  onAcknowledge: () => void
  expire?: boolean
  cancelled?: boolean
}

const ProgressOverlay = ({ progress }: { progress: number }) => {
  return <div className="progress-overlay" style={{ width: `${progress}%` }} />
}

const ScenarioDetectionPanel = ({
  event,
  onAcknowledge,
  expire = false,
  cancelled = false,
}: ScenarioDetectionPanelProps) => {
  const [expireButtonProgress, setExpireButtonProgress] = useState(0)

  const acknowledgeEvent = useAcknowledgeEventMutation()
  const speedLimit = Math.round(event?.eventDetails?.scenarioDetails?.detectedSpeedLimit)
  const confidencePercentage = confidenceToPercentage(event?.eventDetails?.scenarioDetails?.detectedSpeedConfidence)
  const location = formatLatLon(event?.eventDetails?.coordinates?.latitude, event?.eventDetails?.coordinates?.longitude)
  const date = getFormattedTime(event?.eventDetails?.timestamp, "utc", "HH:mm z")

  const handleAcknowledge = () => {
    acknowledgeEvent.mutate()
    onAcknowledge()
  }

  useEffect(() => {
    if (!expire || cancelled) return

    let progressInterval = 0

    // Increment progress every 50ms to reach 100% in 3 seconds
    const interval = setInterval(() => {
      progressInterval += 100 / (3000 / 50)
      setExpireButtonProgress(progressInterval)

      /* istanbul ignore next */
      if (progressInterval >= 100) {
        clearInterval(interval)
      }
    }, 50)
  }, [expire])

  const buttonText = expireButtonProgress >= 100 ? "Alert Expired" : expire ? "Alert Expiring" : "OK"

  return (
    <>
      {event ? (
        <div className="scenario-detection-container">
          <div className="scenario-detection-header">
            <ScenarioDetectedBanner assistanceNeeded={!cancelled} />
          </div>
          <div className="scenario-detection-details">
            <div className="scenario-detection-details--sign">
              <SpeedLimitSign speed={speedLimit} />
            </div>

            <div className="scenario-detection-details--detection">
              <Stack direction="column" spacing={0}>
                <p className={`detail-header ${cancelled ? "detail-header-cancel" : ""}`}>Object Detected:</p>
                <p className={`detail-value ${cancelled ? "detail-value-cancel" : ""}`}>{speedLimit} mph</p>
              </Stack>
            </div>

            <div className="scenario-detection-details--confidence">
              <Stack direction="column" spacing={0}>
                <p className={`detail-header ${cancelled ? "detail-header-cancel" : ""}`}>Confidence:</p>
                <ConfidenceBadge assistanceNeeded={!cancelled} percentage={confidencePercentage} />
              </Stack>
            </div>

            <div className="scenario-detection-details--location">
              <Stack direction="column" spacing={0}>
                <p className={`detail-header ${cancelled ? "detail-header-cancel" : ""}`}>Location:</p>
                <p className={`detail-value ${cancelled ? "detail-value-cancel" : ""}`}>{location}</p>
              </Stack>
            </div>

            <div className="scenario-detection-details--time">
              <Stack direction="column" spacing={0}>
                <p className={`detail-header ${cancelled ? "detail-header-cancel" : ""}`}>Time:</p>
                <p className={`detail-value ${cancelled ? "detail-value-cancel" : ""}`}>{date}</p>
              </Stack>
            </div>

            {!cancelled && (
              <div className="scenario-detection-details--button">
                <Box className="ack-button-box">
                  <Button
                    className={`ack-button  ${cancelled ? "ack-button-cancelled" : ""}`}
                    variant="contained"
                    disabled={expire}
                    onClick={handleAcknowledge}
                  >
                    {buttonText}
                  </Button>
                  {expire && <ProgressOverlay progress={expireButtonProgress} />}
                </Box>
              </div>
            )}
          </div>
        </div>
      ) : null}
    </>
  )
}

export default ScenarioDetectionPanel
