import { CircleOutlined } from "@mui/icons-material"
import { Divider } from "@mui/material"
import "./MissionSchedule.scss"
import useMissionQuery from "@/framework/query/useMissionQuery"
import useGetMissionSelectedRoute from "@/framework/query/useGetMissionSelectedRoute"
import useMissionRouteProgressQuery from "@/framework/query/useMissionRouteProgressQuery"
import { getFormattedTime } from "@/framework/utils/Utils"
import { getLogger } from "@/framework/contexts/DataDog"
import moment from "moment"

const logger = getLogger()

export function formattedETA(scheduledArrival: string, estimatedArrival: string) {
  const eta_delta = moment(scheduledArrival).diff(moment(estimatedArrival))
  if (Number.isNaN(eta_delta)) {
    return "--"
  }
  const eta_delta_duration = moment.duration(Math.abs(eta_delta))
  const eta_delta_hours = eta_delta_duration.get("hours") ?? "--"
  const eta_delta_minutes = eta_delta_duration.subtract(eta_delta_hours, "hours").get("minutes") ?? "--"
  const eta_delta_label = `${eta_delta_hours} hr ${eta_delta_minutes} min ${eta_delta > 0 ? "early" : "late"}`

  return eta_delta_label
}

export function formattedTimeTraveled(scheduledDepartureTime: string) {
  const departure_delta = moment().diff(moment(scheduledDepartureTime))
  if (Number.isNaN(departure_delta)) {
    return "--"
  }

  const departure_delta_duration = moment.duration(departure_delta)
  const traveled_hours = departure_delta_duration.get("hours") ?? "--"
  const traveled_minutes = departure_delta_duration.subtract(traveled_hours, "hours").get("minutes") ?? "--"

  const traveled_label = `${traveled_hours} hr ${traveled_minutes} mins`

  return traveled_label
}

const MissionSchedule = () => {
  const missionsQuery = useMissionQuery()
  const missionRouteQuery = useGetMissionSelectedRoute()

  const mission = missionsQuery?.data?.missions?.[0]
  const missionRoute = missionRouteQuery?.data?.[0]
  const missionProgressQuery = useMissionRouteProgressQuery(mission?.uuid ?? "")
  const missionProgress = missionProgressQuery.data
  logger.debug("mission-progress=", missionProgress)

  const departedLocation = mission?.start_display ?? "--"
  const departureTime = getFormattedTime(mission?.tms_schedule_departure_time ?? "--", undefined, "HH:mm z MM/DD/YY")
  logger.debug("routeData", missionRoute)

  const durationTraveled = formattedTimeTraveled(mission?.tms_schedule_departure_time ?? "--")
  const distanceTraveled = missionProgress?.total_distance_traveled_miles
    ? missionProgress?.total_distance_traveled_miles.toFixed(2)
    : "--"
  const arrivingLocation = mission?.destination_display ?? "--"

  const scheduledArrival = getFormattedTime(mission?.tms_schedule_arrival_time ?? "--", undefined, "HH:mm z MM/DD/YY")
  const estimatedArrival = getFormattedTime(mission?.eta_datetime ?? "--", undefined, "HH:mm z MM/DD/YY")
  logger.debug(` ETA time=${mission?.eta_datetime ?? ""} seconds=${mission?.eta_remaining_seconds ?? ""}`)
  const eta_delta_label = formattedETA(scheduledArrival, estimatedArrival)

  const remainingDistance = (
    (missionProgress?.total_route_distance_miles ?? 0) - (missionProgress?.total_distance_traveled_miles ?? 0)
  ).toFixed(2)

  return (
    <div className="mission-details-container__schedule-panel">
      <h4 className="schedule-panel__header">Schedule</h4>
      <dl className="schedule-panel__content">
        <div className="schedule-panel__content--departed-icon">
          <CircleOutlined />
        </div>
        <div className="schedule-panel__content--arriving-icon">
          <CircleOutlined />
        </div>
        <div className="schedule-panel__content--divider">
          <Divider orientation="vertical" />
        </div>
        <div className="schedule-panel__content--departed-label">
          <span id="departed-label" className="label__key">
            Departed:
          </span>
        </div>
        <div className="schedule-panel__content--departed-details">
          <div className="details__row">
            <span aria-labelledby="departed-label" className="label__value">
              {departedLocation}
            </span>
          </div>
          <div className="details__row">
            <dt className="label__key">Start:</dt>
            <dd className="label__value">{departureTime}</dd>
          </div>
          <div className="details__row">
            <dt className="label__key">Duration Traveled:</dt>
            <dd className="label__value">{durationTraveled}</dd>
          </div>
          <div className="details__row">
            <dt className="label__key">Distance Traveled:</dt>
            <dd className="label__value">{distanceTraveled} miles</dd>
          </div>
        </div>
        <div className="schedule-panel__content--arriving-label">
          <span id="arriving-label" className="label__key">
            Arriving:
          </span>
        </div>
        <div className="schedule-panel__content--arriving-details">
          <div className="details__row">
            <dt aria-labelledby="arriving-label" className="label__value">
              {arrivingLocation}
            </dt>
            <dd className="label__value--primary">{eta_delta_label}</dd>
          </div>
          <div className="details__row">
            <dt className="label__key">Scheduled:</dt>
            <dd className="label__value">{scheduledArrival}</dd>
          </div>
          <div className="details__row">
            <dt className="label__key">Estimated:</dt>
            <dd className="label__value">{estimatedArrival}</dd>
          </div>
          <div className="details__row">
            <dt className="label__key">Remaining:</dt>
            <dd className="label__value">{remainingDistance} miles</dd>
          </div>
        </div>
      </dl>
    </div>
  )
}

export default MissionSchedule
