import { useQueryClient } from "@tanstack/react-query"
import { useEffect, useRef } from "react"
import useInferredVehicleState from "./useInferredVehicleState"

const useInvalidateRoute = () => {
  const queryClient = useQueryClient()

  const { rerouteLog, motionLog } = useInferredVehicleState()

  const newRouteFetched = useRef(false)
  useEffect(() => {
    if (rerouteLog?.details?.result === "REROUTE-REQUEST-APPROVED" && !newRouteFetched.current) {
      void queryClient.invalidateQueries({
        queryKey: ["missionRoute"],
      })
      newRouteFetched.current = true
    } else if (
      rerouteLog?.details?.result !== "REROUTE-REQUEST-APPROVED" ||
      (motionLog?.created && motionLog?.created > rerouteLog?.created)
    ) {
      newRouteFetched.current = false
    }
  }, [rerouteLog])
}

export default useInvalidateRoute
