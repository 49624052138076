import { Box } from "@mui/material"
import "./scss/assistanceQueueMapPanel.scss"
import RemoteDashboardMap from "../remote-dashboard-map/RemoteDashboardMap"
import { MapType } from "@/framework/store/useMapStore"
import { VehicleIconType } from "@/framework/layers/useVehiclesLayer"
import useGetRemoteAssistanceEventsQuery from "@/framework/query/useGetRemoteAssistanceEventsQuery"

interface AssistanceQueueMapPanelProps {
  selectedVehicle: string | null | undefined
}

const AssistanceQueueMapPanel = ({ selectedVehicle }: AssistanceQueueMapPanelProps) => {
  const { data } = useGetRemoteAssistanceEventsQuery()

  const vehicleIds = [...new Set(data?.data?.getEvents?.map((event) => event.vehicleId) ?? [])]

  return (
    <Box id="assistance-queue-map-panel-container" data-testid="monitor-queue-map-panel-container">
      <RemoteDashboardMap
        mapType={MapType.ASSISTANCE_QUEUE}
        maxWidth={367}
        maxHeight={297}
        vehicleIds={vehicleIds}
        selectedVehicle={selectedVehicle}
        vehicleIconType={VehicleIconType.ASSISTANCE_NEEDED}
      />
    </Box>
  )
}

export default AssistanceQueueMapPanel
