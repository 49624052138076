/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Box, Typography } from "@mui/material"
import { type ReactElement } from "react"
import "./scss/VehicleHover.scss"

const VehiclesHoverTooltip = ({ vehicleId, bgColor }: { vehicleId: string; bgColor: string }): ReactElement => {
  return (
    <Box
      className="vehicle-hover-title-container"
      sx={{
        backgroundColor: bgColor,
        border: `2px solid ${bgColor}`,
      }}
    >
      <Typography className="hover-title" data-testid="hover-title">
        {vehicleId}
      </Typography>
    </Box>
  )
}

export default VehiclesHoverTooltip
