import { useQueries, useQuery } from "@tanstack/react-query"
import RouteAdvisorAPI from "../api/RouteAdvisorAPI"
import useInvalidateRoute from "./useInvalidateRoute"

const useRerouteHandler = () => {
  useInvalidateRoute()

  const routeAdvisorQuery = useQuery({
    queryKey: ["reroute"],
    // @ts-expect-error: not actually calling the function
    queryFn: /* istanbul ignore next */ () => RouteAdvisorAPI.routeRequest(),
    initialData: { routes: [] },
    enabled: false,
  })

  const routeAdvisorRoutesQuery = useQueries({
    queries:
      routeAdvisorQuery?.data?.routes?.map((route) => ({
        queryKey: ["reroute", route.route_id],
        queryFn: /* istanbul ignore next */ () => RouteAdvisorAPI.getRouteById({ routeId: route.route_id }),
      })) ?? [],
  })

  const isRerouting = routeAdvisorQuery.data?.routes?.length > 0

  return {
    routeAdvisorQuery,
    routeAdvisorRoutesQuery,
    isRerouting,
  }
}

export default useRerouteHandler
