// reference atmcjs -> src/target/models/Mission.ts
export enum MissionStateEnum {
  SCHEDULED = "SCHEDULED",
  PREPARING = "PREPARING",
  REVIEWING = "REVIEWING",
  CLEARED = "CLEARED",
  WAIT_ACCEPT = "WAIT_ACCEPT",
  ACCEPTED = "ACCEPTED",
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  CANCELED = "CANCELED",
  DST_CHG_REQ = "DST_CHG_REQ",
}

// reference mm-remote-cockpit -> lambdas/src/domain/service_category.py
export enum ServiceCategoryEnum {
  STOP_REQUEST = "remote-cockpit:action-stop",
  RESUME_REQUEST = "remote-cockpit:action-resume",
}

// reference mm-remote-cockpit -> lambdas/src/domain/action_type.py
export enum StopRequestEnum {
  INITIATED = "STOP-REQUEST-INITIATED",
  SENT = "STOP-REQUEST-SENT",
  APPROVED = "STOP-REQUEST-APPROVED",
  REJECTED = "STOP-REQUEST-REJECTED",
  TIMEOUT = "STOP-REQUEST-TIMEOUT",

  RECEIVED = "STOP-REQUEST-RECEIVED",
  EXECUTING = "STOP-REQUEST-EXECUTING",
  COMPLETED = "STOP-REQUEST-COMPLETED",
}

// reference mm-remote-cockpit -> lambdas/src/domain/action_type.py
export enum ResumeRequestEnum {
  INITIATED = "RESUME-REQUEST-INITIATED",
  SENT = "RESUME-REQUEST-SENT",
  APPROVED = "RESUME-REQUEST-APPROVED",
  REJECTED = "RESUME-REQUEST-REJECTED",
  TIMEOUT = "RESUME-REQUEST-TIMEOUT",

  RECEIVED = "RESUME-REQUEST-RECEIVED",
  EXECUTING = "RESUME-REQUEST-EXECUTING",
  COMPLETED = "RESUME-REQUEST-COMPLETED",
}

export enum LogSummaryMissionStateEnum {
  IN_PROGRESS = "IN_PROGRESS",
  ARRIVED = "ARRIVED", // Does not currently exist
  COMPLETED = "COMPLETED",
}
