import { Box, Typography, CircularProgress, Tooltip } from "@mui/material"
import useGetMissionHistoryLog from "@/framework/query/useGetMissionHistoryLog"
import useMissionQuery from "@/framework/query/useMissionQuery"
import LoadScreen from "@/framework/utils/LoadScreen"
import { getFormattedTime } from "@/framework/utils/Utils"
import InfiniteScroll from "react-infinite-scroll-component"
import { useSelectedLogStore } from "@/framework/store/useSelectedLogStore"
import useActiveSessionTabStore from "@/framework/store/useActiveSessionTabStore"
import { AssistTab } from "../remote-assistance/tabHelper"
import { LogSummaryMissionStateEnum } from "@/framework/models/Mission"

import "./scss/vdhl.scss"

const VDHLPanel = () => {
  const mission = useMissionQuery()?.data?.missions?.[0] ?? {}
  const { data, isLoading, fetchNextPage, hasNextPage } = useGetMissionHistoryLog()
  const { setSelectedLog, setBlink } = useSelectedLogStore()
  const { setCurrentTab } = useActiveSessionTabStore()

  const allLogs = data?.pages.flatMap((page) => page.body) ?? []
  const historyLogs =
    allLogs?.filter((log) => {
      const isRemoteAssistance = log?.title === "Remote Assistance" && log?.details?.event_log?.event !== "Notes"
      const isStateTransition =
        log?.title === "Mission State Transition" &&
        Object.keys(LogSummaryMissionStateEnum).includes(log?.details?.state)
      const isVehicleConnectivity = log?.category === "VEHICLE_CONNECTIVITY"

      return isRemoteAssistance || isStateTransition || isVehicleConnectivity
    }) || []

  const handleFetchMore = () => {
    console.debug("Fetching next page, has more:", hasNextPage)
    if (hasNextPage) {
      fetchNextPage()
    }
  }

  const handleLogClick = (log) => {
    setCurrentTab(AssistTab.EVENT_LOG)
    setSelectedLog(log)
    setBlink(true)
    setTimeout(() => {
      setBlink(false)
    }, 2000)
  }

  return (
    <Box className="vdhl-panel" data-testid="vdhl-panel-container">
      <div className="vdhl-panel__header">
        <Typography>Event Log Summary</Typography>
      </div>
      {isLoading ? (
        <LoadScreen />
      ) : (
        <Box className="vdhl-panel__body" id="vdhl-scrollable-container">
          {historyLogs.length <= 0 || mission?.state !== "IN_PROGRESS" ? (
            <Typography data-testid="vdhl-no-logs" className="vdhl-panel__body__nologs">
              No Logs Found for Mission
            </Typography>
          ) : (
            <InfiniteScroll
              dataLength={historyLogs.length}
              next={handleFetchMore}
              hasMore={!!hasNextPage}
              loader={
                <div className="vdhl-panel__loader">
                  <CircularProgress size={20} />
                </div>
              }
              scrollableTarget="vdhl-scrollable-container"
              scrollThreshold={0.9}
              endMessage={
                <div className="vdhl-panel__end-message">
                  <Typography variant="caption">All logs loaded</Typography>
                </div>
              }
            >
              {historyLogs.map((log, index) => (
                <div
                  data-testid={`vdhl-log-${index}`}
                  className={`vdhl-panel__body__log ${index % 2 === 0 ? "vdhl-panel__body__log__even" : ""}`}
                  key={index}
                  onClick={() => handleLogClick(log)}
                >
                  <Tooltip
                    title={
                      log.title !== "Mission State Transition" && log.category !== "VEHICLE_CONNECTIVITY"
                        ? log?.details?.summary || log?.details?.result || log?.description
                        : log.category === "VEHICLE_CONNECTIVITY"
                        ? log.level === "OPERATIONAL"
                          ? "Truck Connection Regained"
                          : log.level === "WARN"
                          ? "Truck Connection Lost"
                          : log?.details?.summary || log?.details?.result || log?.description
                        : log?.details?.summary || log?.description
                    }
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, -5],
                            },
                          },
                        ],
                      },
                      tooltip: {
                        sx: {
                          color: "#333333",
                          backgroundColor: "#f2f2f2",
                          fontSize: "12px",
                          border: "1px solid #7f7f7f",
                          textTransform: "capitalize",
                        },
                      },
                      arrow: {
                        sx: {
                          "&::before": {
                            background: "#f2f2f2",
                            border: "1px solid #7f7f7f",
                          },
                        },
                      },
                    }}
                    placement="top-end"
                    disableInteractive
                    arrow
                  >
                    <Typography className="vdhl-panel__body__log__title">
                      {log.title !== "Mission State Transition" && log.category !== "VEHICLE_CONNECTIVITY"
                        ? log?.details?.summary || log?.details?.result || log?.description
                        : log.category === "VEHICLE_CONNECTIVITY"
                        ? log.level === "OPERATIONAL"
                          ? "Truck Connection Regained"
                          : log.level === "WARN"
                          ? "Truck Connection Lost"
                          : log?.details?.summary || log?.details?.result || log?.description
                        : log?.details?.summary || log?.description}
                    </Typography>
                  </Tooltip>
                  <div className="vdhl-panel__body__log__ellipsis"></div>
                  <Typography className="vdhl-panel__body__log__date">
                    {getFormattedTime(log.creation_ts, null, "HH:mm:ss", null)}
                  </Typography>
                </div>
              ))}
            </InfiniteScroll>
          )}
        </Box>
      )}
    </Box>
  )
}

export default VDHLPanel
