import { useEffect } from "react"

export const telemetry = (datadogRum) => {
  const sendActionTelemetry = (action, context) => {
    return datadogRum.addAction(action, context)
  }

  const useViewContext = (name, value, deps) => {
    useEffect(() => {
      datadogRum.setViewContextProperty(name, value)
      return () => {
        datadogRum.setViewContextProperty(name, undefined)
      }
    }, deps)
  }

  const useAction = (action, context) => {
    useEffect(() => {
      sendActionTelemetry(`${action} Success`, {
        ...context,
        _: {
          name: action,
          state: "success",
        },
      })
    }, [])
  }

  const withActionTelemetry = (handler, action, context = {}) => {
    return (...args) => {
      sendActionTelemetry(`${action} Start`, {
        ...context,
        _: {
          name: action,
          state: "start",
        },
      })
      try {
        handler(...args)
      } catch (e) {
        sendActionTelemetry(`${action} Failure`, {
          ...context,
          _: {
            name: action,
            state: "failure",
          },
        })
        throw e
      }
      sendActionTelemetry(`${action} Success`, {
        ...context,
        _: {
          name: action,
          state: "success",
        },
      })
    }
  }

  return { withActionTelemetry, useAction, useViewContext }
}
