import { useEffect, useState } from "react"
import MonitorQueueFilter from "./MonitorQueueFilter"
import "./scss/monitorQueue.scss"
import { Box, CircularProgress, Grid, Typography } from "@mui/material"
import useFilteredMissionsQuery from "@/framework/query/useFilteredMissionsQuery"
import useGetSessions from "@/framework/query/useGetSessions"
import MissionInTransitList from "./MissionsInTransitList"
import MissionPreDepartureTable from "./MissionsPreDepartureTable"
import { src__model__mission__Mission_Output as Mission_Output } from "@torc-robotics/mm-missions-client"
import InfiniteScroll from "react-infinite-scroll-component"
import LoadError from "./LoadError"
import MonitorQueueMapPanel from "./MonitorQueueMapPanel"
import { getLogger } from "../../framework/contexts/DataDog"
import useGetRemoteAssistanceEventsQuery from "@/framework/query/useGetRemoteAssistanceEventsQuery"

const logger = getLogger()

const MINIMUM_MISSIONS_TO_FETCH = 10

const MonitorQueue = () => {
  const { allActiveSessions } = useGetSessions()
  const { data: assistanceQueueData } = useGetRemoteAssistanceEventsQuery()
  const allAssistanceQueueMissionIds = assistanceQueueData?.data?.getEvents?.map((event) => event.loadId) || []

  const [initialLoadInTransitData, setInitialLoadInTransitData] = useState(false)
  const [initialLoadPreDepartureData, setInitialLoadPreDepartureData] = useState(false)

  const {
    data: inTransitData,
    fetchNextPage: fetchNextInTransitPage,
    hasNextPage: hasNextInTransitPage,
    isError: isInTransitError,
    error: inTransitError,
  } = useFilteredMissionsQuery({
    states: ["IN_PROGRESS"],
  })

  const {
    data: preDepartureData,
    fetchNextPage: fetchNextPreDeparturePage,
    hasNextPage: hasNextPreDeparturePage,
    isError: isPreDepartureError,
    error: preDepartureError,
  } = useFilteredMissionsQuery({
    states: ["SCHEDULED", "PREPARING", "REVIEWING", "CLEARED", "WAIT_ACCEPT", "ACCEPTED", "PENDING"],
  })

  const [selectedMission, setSelectedMission] = useState<Mission_Output | null>(null)

  const inTransitMissions =
    inTransitData?.pages.flatMap(
      (page) =>
        page.missions?.filter(
          (mission: Mission_Output) =>
            !allActiveSessions.find((session) => session.loadId === mission.load_id) &&
            !allAssistanceQueueMissionIds.includes(mission.load_id as string)
        ) || []
    ) || []
  const preDepartureMissions = preDepartureData?.pages.flatMap((page) => page.missions || []) || []
  const totalMissions = inTransitMissions.length + preDepartureMissions.length

  const handleExpandMission = (mission: Mission_Output, isExpanded: boolean) => {
    setSelectedMission(!isExpanded ? mission : null)
  }

  const selectedVehicle = selectedMission?.vehicle_id

  const handleFetchNextInTransit = async () => {
    while (hasNextInTransitPage) {
      try {
        const result = await fetchNextInTransitPage()
        if (!result?.hasNextPage) {
          break
        }

        if ((result?.data?.pages[result.data.pages.length - 1].missions?.length as number) > 0) {
          break
        }
      } catch (error) {
        logger.error("Error fetching next in-transit page:", {}, error as Error)
        console.error("Error fetching next in-transit page:", error)
        break
      }
    }
  }

  const handleFetchNextDeparture = async () => {
    while (hasNextPreDeparturePage) {
      try {
        const result = await fetchNextPreDeparturePage()
        if (!result?.hasNextPage) {
          break
        }

        if ((result?.data?.pages[result.data.pages.length - 1].missions?.length as number) > 0) {
          break
        }
      } catch (error) {
        logger.error("Error fetching next pre-departure page:", {}, error as Error)
        console.error("Error fetching next pre-departure page:", error)
        break
      }
    }
  }

  useEffect(() => {
    const countPreDeparture = preDepartureData
      ? preDepartureData.pages.reduce((acc, page) => {
          return acc + (page.missions?.length === undefined ? 0 : page.missions.length)
        }, 0)
      : 0
    if (hasNextPreDeparturePage && countPreDeparture < MINIMUM_MISSIONS_TO_FETCH) {
      setInitialLoadPreDepartureData(true)
      void fetchNextPreDeparturePage()
    } else {
      setInitialLoadPreDepartureData(false)
    }
  }, [preDepartureData?.pages])

  useEffect(() => {
    const countInTransit = inTransitData
      ? inTransitData.pages.reduce((acc, page) => {
          return acc + (page.missions?.length === undefined ? 0 : page.missions.length)
        }, 0)
      : 0

    if (hasNextInTransitPage && countInTransit < MINIMUM_MISSIONS_TO_FETCH) {
      setInitialLoadInTransitData(true)
      void fetchNextInTransitPage()
    } else {
      setInitialLoadInTransitData(false)
    }
  }, [inTransitData?.pages])

  const renderLoader = (count: number) => (
    <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
      <CircularProgress size={24} />
      <Typography variant="body2" style={{ marginLeft: 10 }}>
        Loading more missions... (Current count: {count})
      </Typography>
    </Box>
  )

  const renderNoOtherMissions = () => (
    <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
      <Typography variant="body2">No other missions to show</Typography>
    </Box>
  )

  return (
    <div className="monitor-queue">
      <div className="box-header">
        <h2>Monitor Queue ({totalMissions})</h2>
        <MonitorQueueFilter />
      </div>
      <div className="box-body">
        <Box className={"map-panel"}>
          <MonitorQueueMapPanel selectedVehicle={selectedVehicle} />
        </Box>

        <Box className="in-transit-mission-panel">
          <Typography className="in-transit-mission-panel-title" variant="h6">
            In-transit ({inTransitMissions.length})
          </Typography>

          <Box className="in-transit-mission-panel-content">
            <Grid container className="sticky-header">
              <Grid item xs={3}>
                <Typography className="header-text">SDT</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className="header-text">Mission ID</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className="header-text">Coordinates</Typography>
              </Grid>
              <Grid item xs={2} /> {/* Spacer for the VIEW SDT button column */}
            </Grid>

            <Box
              id="inTransitScrollableDiv"
              data-testid="inTransitScrollableDiv"
              className="in-transit-mission-panel-list"
            >
              <InfiniteScroll
                dataLength={inTransitMissions.length}
                next={handleFetchNextInTransit}
                hasMore={!!hasNextInTransitPage && !initialLoadInTransitData}
                loader={renderLoader(inTransitMissions.length)}
                scrollableTarget="inTransitScrollableDiv"
              >
                <MissionInTransitList
                  missions={inTransitMissions}
                  selectedMission={selectedMission}
                  onMissionSelect={handleExpandMission}
                />
                {isInTransitError && <LoadError error={inTransitError} />}
              </InfiniteScroll>
              {initialLoadInTransitData && renderLoader(inTransitMissions.length)}
              {!initialLoadInTransitData && !hasNextInTransitPage && renderNoOtherMissions()}
            </Box>
          </Box>
        </Box>

        <Box className="pre-departure-mission-panel">
          <Typography className="pre-departure-mission-panel-title" variant="h6">
            Pre-departure ({preDepartureMissions.length})
          </Typography>
          <Box
            id="preDepartureScrollableDiv"
            data-testid="preDepartureScrollableDiv"
            className="pre-departure-mission-panel-list"
          >
            <MissionPreDepartureTable
              missions={preDepartureMissions}
              handleFetchNextPreDeparture={handleFetchNextDeparture}
              hasNextPreDeparturePage={hasNextPreDeparturePage}
              initialLoadPreDepartureData={initialLoadPreDepartureData}
              isPreDepartureError={isPreDepartureError}
              preDepartureError={preDepartureError}
            />
          </Box>
        </Box>
      </div>
    </div>
  )
}

export default MonitorQueue
