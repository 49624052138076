import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
} from "@mui/material"
import { src__model__mission__Mission_Output as Mission_Output } from "@torc-robotics/mm-missions-client"
import DepartureTimeCell from "./DepartureTimeCell"
import React from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import "./scss/missionsPreDepartureTable.scss"
import { Box } from "@mui/material"
import LoadError from "./LoadError"

interface MissionPreDepartureTableProps {
  missions: Mission_Output[]
  handleFetchNextPreDeparture: () => Promise<void>
  hasNextPreDeparturePage: boolean
  initialLoadPreDepartureData: boolean
  isPreDepartureError: boolean
  preDepartureError: Error | null
}

const MissionPreDepartureTable = React.memo(
  ({
    missions,
    handleFetchNextPreDeparture,
    hasNextPreDeparturePage,
    initialLoadPreDepartureData,
    isPreDepartureError,
    preDepartureError,
  }: MissionPreDepartureTableProps) => {
    const timeUntil = (dateString: string) => {
      const targetDate = new Date(dateString)
      const currentTime = new Date()
      const timeDiff = targetDate.getTime() - currentTime.getTime()
      const minutesDiff = Math.floor(timeDiff / (1000 * 60))

      if (minutesDiff < 0) {
        return "Past due"
      }

      if (minutesDiff === 1) {
        return "1 minute"
      } else {
        return `${minutesDiff} minutes`
      }
    }

    const renderLoader = (count: number) => (
      <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
        <CircularProgress size={24} />
        <Typography variant="body2">Loading more missions... (Current count: {count})</Typography>
      </Box>
    )

    const renderNoOtherMissions = () => (
      <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
        <Typography variant="body2">No other missions to show</Typography>
      </Box>
    )

    return (
      <TableContainer className="mission-pre-departure-table-container">
        <Table stickyHeader>
          <TableHead className="table-header">
            <TableRow>
              <TableCell className="table-header-cell col-sdt">SDT</TableCell>
              <TableCell className="table-header-cell col-hub">Departure HUB</TableCell>
              <TableCell className="table-header-cell col-hub">Arrival HUB</TableCell>
              <TableCell className="table-header-cell col-departure-time">Estimated Departure Time</TableCell>
              <TableCell className="col-scrollbar">{/* Dummy cell for scrollbar width */}</TableCell>
            </TableRow>
          </TableHead>
        </Table>
        <div id="scrollable-body" className="scrollable-body" data-testid="scrollable-body">
          <InfiniteScroll
            dataLength={missions.length}
            next={handleFetchNextPreDeparture}
            hasMore={hasNextPreDeparturePage && !initialLoadPreDepartureData}
            loader={renderLoader(missions.length)}
            scrollableTarget="scrollable-body"
          >
            <Table>
              <TableBody className="mission-pre-departure-table-body">
                {missions.map((mission) => (
                  <TableRow key={mission.uuid} className="mission-pre-departure-table-row">
                    <TableCell className="mission-pre-departure-table-cell col-sdt">
                      <Typography className="vehicle-id">{mission.vehicle_id}</Typography>
                    </TableCell>
                    <TableCell className="mission-pre-departure-table-cell col-hub">
                      <Typography className="table-cell-value">{mission.start_display}</Typography>
                    </TableCell>
                    <TableCell className="mission-pre-departure-table-cell col-hub">
                      <Typography className="table-cell-value">{mission.destination_display}</Typography>
                    </TableCell>
                    <TableCell className="mission-pre-departure-table-cell col-departure-time">
                      <DepartureTimeCell
                        value={
                          mission.tms_schedule_arrival_time ? timeUntil(mission.tms_schedule_arrival_time) : "Unknown"
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </InfiniteScroll>
          {isPreDepartureError && <LoadError error={preDepartureError} />}
          {initialLoadPreDepartureData && renderLoader(missions.length)}
          {!hasNextPreDeparturePage && !initialLoadPreDepartureData && renderNoOtherMissions()}
        </div>
      </TableContainer>
    )
  }
)

MissionPreDepartureTable.displayName = "MissionPreDepartureTable"

export default MissionPreDepartureTable
