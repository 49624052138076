import { useQuery } from "@tanstack/react-query"
import { featureCollection, lineString } from "@turf/turf"

import useMissionQuery from "../query/useMissionQuery"
import MissionsAPI from "../api/MissionsAPI"

const useVehiclePathLayer = () => {
  const mission = useMissionQuery().data?.missions?.[0]

  const query = useQuery({
    queryKey: ["mission", mission?.uuid, "locations"],
    queryFn: /* istanbul ignore next */ async () =>
      await MissionsAPI.v2.getMissionLocationsV2({
        missionUuid: mission?.uuid ?? "",
      }),
    enabled: !!mission?.uuid,
    refetchInterval: 5000,
  })

  const routeTraveledTransform = query.data?.map((location) => [location.lon, location.lat])

  if (!routeTraveledTransform?.length) {
    return null
  }

  return featureCollection([lineString(routeTraveledTransform ?? [])])
}

export default useVehiclePathLayer
