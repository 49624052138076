import { Box, Typography } from "@mui/material"
import { getFormattedTime, calculateTimeRemaining } from "../../framework/utils/Utils"
import useMissionQuery from "@/framework/query/useMissionQuery"

const MapRouteProgressDisplay = () => {
  const missionsQuery = useMissionQuery()

  const mission = missionsQuery?.data?.missions?.[0]

  return (
    <Box className="route-progress-display" textAlign={"center"}>
      <Typography variant="subtitle2">Route Status:</Typography>
      <Typography variant="subtitle2" className={"eta-line"} data-testid={"eta"}>
        ETA: <span className={"eta"}>{getFormattedTime(mission?.eta_datetime ?? "--", undefined, "HH:mm")}</span>
      </Typography>
      <Typography variant="subtitle2" className={"time-left-line"} data-testid={"time-left"}>
        Time Left: <span className={"time-left"}>{calculateTimeRemaining(mission?.eta_remaining_seconds)}</span>
      </Typography>
    </Box>
  )
}

export default MapRouteProgressDisplay
