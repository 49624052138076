import { gql, useSubscription } from "@apollo/client"
import {
  OnUpdateBrowserIdDocument,
  OnUpdateBrowserIdSubscription,
  OnUpdateBrowserIdSubscriptionVariables,
} from "@torc-robotics/vda-sessions-api/graphql/document"
import useBrowserId from "../hooks/useBrowserId"
import { useEffect } from "react"
import { getLogger } from "../../framework/contexts/DataDog"
import { RemotePage, usePage } from "../contexts/Page"

const logger = getLogger()

const UPDATE_BROWSER_ID_SUBSCRIPTION = gql`
  ${OnUpdateBrowserIdDocument}
`

const useBrowserIdUpdateSubscription = () => {
  const page = usePage()
  const browserId = useBrowserId()

  const browserIdUpdateSubscription = useSubscription<
    OnUpdateBrowserIdSubscription,
    OnUpdateBrowserIdSubscriptionVariables
  >(UPDATE_BROWSER_ID_SUBSCRIPTION, {
    context: { clientName: "sessions" },
    onData: ({ data: { data } }) => {
      logger.debug("browserIdUpdateSubscription", data)
      if (!data?.onUpdateBrowserId) {
        return
      }
      logger.debug(`browserId updated`, data.onUpdateBrowserId)
    },
  })

  const browserIdSubscriptionData = browserIdUpdateSubscription.data?.onUpdateBrowserId?.browserId

  useEffect(() => {
    if (browserIdSubscriptionData === undefined || browserIdSubscriptionData === browserId) {
      return
    }

    alert("Your sessions have been opened in another window. You will be redirected to the dashboard.")
    page.setActive(RemotePage.Dashboard)
  }, [browserIdSubscriptionData])

  return browserIdUpdateSubscription
}

export default useBrowserIdUpdateSubscription
