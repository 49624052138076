/* istanbul ignore file */
import { datadogLogs as defaultDatadogLogs } from "@datadog/browser-logs"
import { datadogRum as defaultDatadogRum } from "@datadog/browser-rum"
import { UserContext } from "@torc-robotics/mcli-mfui-auth"
import { createContext, useMemo, useContext } from "react"
import { telemetry } from "../utils/events/telemetry"

const team = import.meta.env.VITE_DD_TEAM
const service = import.meta.env.VITE_DD_SERVICE
const level = import.meta.env.VITE_DD_LOG_LEVEL
const env = import.meta.env.VITE_DD_ENV
const appId = import.meta.env.VITE_DD_APPLICATION_ID
const clientToken = import.meta.env.VITE_DD_CLIENT_TOKEN
const version = String(import.meta.env.VITE_VCS_SHORT_REF)

export function createLogger() {
  let handler = []
  const context = {
    service: service,
    level: level,
    env: env,
    team: team,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ["error", "warn"],
  }
  /* eslint-disable */
  switch (env) {
    case "localhost":
      handler = ["console"]
      break

    case "production":
      handler = ["http"]
      break

    default:
      handler = ["http", "console"]
      break
  }
  /* eslint-enable */

  const logger = defaultDatadogLogs.createLogger(service, {
    context: context,
  })
  logger.setHandler(handler)

  return logger
}

const setRum = (datadogRumClient) => {
  if (!datadogRumClient || datadogRumClient.getInitConfiguration().applicationId != (appId || "unknown")) {
    defaultDatadogRum.init({
      applicationId: appId ? appId : "fake-app-id",
      clientToken: clientToken ? clientToken : "fake-token",
      site: "datadoghq.com",
      service: service ? service : "0.0.0",
      env: env,
      version: version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      trackSessionAcrossSubdomains: true,
      allowedTracingUrls: [
        {
          match: /https:\/\/missions.*\.api\.cloud\.torc\.tech/,
          propagatorTypes: ["datadog"],
        },
        {
          match: /https:\/\/users.*\.api\.cloud\.torc\.tech/,
          propagatorTypes: ["datadog"],
        },
        {
          match: /https:\/\/vehicles.*\.api\.cloud\.torc\.tech/,
          propagatorTypes: ["datadog"],
        },
        {
          match: /https:\/\/notes.*\.api\.cloud\.torc\.tech/,
          propagatorTypes: ["datadog"],
        },
        {
          match: /https:\/\/health-monitoring.*\.api\.cloud\.torc\.tech/,
          propagatorTypes: ["datadog"],
        },
        {
          match: /https:\/\/routes.*\.api\.cloud\.torc\.tech/,
          propagatorTypes: ["datadog"],
        },
        {
          match: /https:\/\/actions.*\.api\.cloud\.torc\.tech/,
          propagatorTypes: ["datadog"],
        },
        {
          match: /https:\/\/remote-cockpit*\.api\.cloud\.torc\.tech/,
          propagatorTypes: ["datadog"],
        },
        {
          match: /https:\/\/sessions*\.api\.cloud\.torc\.tech/,
          propagatorTypes: ["datadog"],
        },
        {
          match: /https:\/\/remote-assistance*\.api\.cloud\.torc\.tech/,
          propagatorTypes: ["datadog"],
        },
      ],
      enableExperimentalFeatures: ["clickmap"],
      defaultPrivacyLevel: "allow",
    })
    return defaultDatadogRum
  } else {
    return datadogRumClient
  }
}

const setLogger = (datadogLogsClient) => {
  let datadogLogs
  if (datadogLogsClient && datadogLogsClient.getInitConfiguration().clientToken === clientToken) {
    datadogLogs = datadogLogsClient
    datadogLogs.setGlobalContext({ service, team })
  } else {
    datadogLogs = defaultDatadogLogs
    if (datadogLogs.getInitConfiguration()) {
      datadogLogs = datadogLogsClient
    } else {
      datadogLogs.init({
        clientToken: clientToken ? clientToken : "fake-token",
        site: "datadoghq.com",
        service: service ? service : "fake-service",
        team: team,
        version: version,
        env: env,
        forwardErrorsToLogs: true,
        forwardConsoleLogs: ["log", "info", "error"],
        sessionSampleRate: 100,
        useSecureSessionCookie: true,
      })
    }
  }
  return datadogLogs
}

export function getLogger() {
  let logger = defaultDatadogLogs.getLogger(service)
  if (logger) {
    return logger
  } else {
    return createLogger()
  }
}

const setRumUser = (datadogRumClient) => {
  const userData = useContext(UserContext)?.userData
  console.log("Rum User set to ", userData?.email?.split("@", 1)[0])

  datadogRumClient.setUser({
    name: userData?.email?.split("@", 1)[0],
    email: userData?.email,
  })
}

export const DatadogContext = createContext(null)

export function DatadogContextProvider({ datadogRumClient, datadogLogsClient, children }) {
  const datadogRum = useMemo(() => setRum(datadogRumClient), [])
  const datadogLogs = useMemo(() => setLogger(datadogLogsClient), [])
  const value = {
    datadogRum: datadogRum,
    datadogLog: datadogLogs,
    telemetry: telemetry(datadogRum),
  }
  setRumUser(datadogRum)
  datadogRum.startSessionReplayRecording

  return <DatadogContext.Provider value={value}>{children}</DatadogContext.Provider>
}
