import VehiclesApi from "../api/VehiclesAPI"
import { useQuery } from "@tanstack/react-query"
import { useGetVehicleId } from "../hooks/useGetVehicleId"

const useVehicleDetailsQuery = (lookupVehicleId?: string) => {
  const vehicleId = useGetVehicleId() ?? ""
  const findVehicleId = lookupVehicleId ?? vehicleId

  const vehicleQuery = useQuery({
    queryKey: ["vehicles-overview", findVehicleId],
    queryFn: /* istanbul ignore next */ async () => await VehiclesApi.getVehicleV2({ vehicleId: findVehicleId }),
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    enabled: !!findVehicleId,
    refetchInterval: 10000,
    retry: 3,
    refetchOnWindowFocus: false,
  })

  return vehicleQuery
}

export default useVehicleDetailsQuery
