import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query"
import { MissionsApiV2 } from "../api/MissionsAPI"
import { toast } from "react-toastify"
import useMissionQuery from "./useMissionQuery"
import { useContext } from "react"
import { UserContext } from "@torc-robotics/mcli-mfui-auth"
import { datadogRum } from "@datadog/browser-rum"

const service = String(import.meta.env.VITE_DD_SERVICE)
const env = String(import.meta.env.VITE_DD_ENV)

const postLogMutationFn = async ({
  missionUuid,
  body,
  email,
}: {
  missionUuid: string
  body: string
  email: string
}) => {
  return await MissionsApiV2.postMissionLogs({
    missionUuid: missionUuid,
    requestBody: {
      title: "Remote Assistance",
      description: `User ${email} logged additional details`,
      service: service,
      environment: env,
      details: {
        body: body,
        request: "User Notation",
        user: email,
        event_log: {
          initiator: email,
          event: "Notes",
          status: null,
          type: null,
          details: {
            status: null,
            title: "Notes",
            body: body,
          },
        },
      },
    },
  }).catch((error) => {
    throw error
  })
}

export { postLogMutationFn }

const postLogOnSuccess = ({ missionUuid, queryClient }: { missionUuid: string; queryClient: QueryClient }) => {
  toast.success("Successfully created new mission log.")
  return queryClient.invalidateQueries({ queryKey: ["mission", missionUuid, "notes"] })
}

export { postLogOnSuccess }

const postLogOnError = () => {
  toast.error(
    "An error occurred when attempting to create a new mission log. Please try again, or contact support if the error persists."
  )
}

export { postLogOnError }

const usePostMissionLogMutation = () => {
  const missionsQuery = useMissionQuery()
  const queryClient = useQueryClient()
  const email = useContext(UserContext)?.userData?.email ?? ""

  const missionUuid = missionsQuery?.data?.missions?.[0]?.uuid ?? ""

  const postMissionLogMutation = useMutation({
    mutationKey: ["mission", "notes-post"],
    mutationFn: ({ body }: { body: string }) =>
      postLogMutationFn({ missionUuid: missionUuid, body: body, email: email }),
    onSuccess: (data) => postLogOnSuccess({ missionUuid: data.mission_uuid, queryClient: queryClient }),
    onError: () => postLogOnError(),
    onSettled(data, error, variables) {
      datadogRum.addAction("Posting Mission Log", { data: data, variables: variables, error: error })
    },
  })

  return postMissionLogMutation
}

export default usePostMissionLogMutation
