import VehiclesAPI from "@/framework/api/VehiclesAPI"
import { useQuery } from "@tanstack/react-query"

const useGetVehicleStatusQuery = (vehicleId: string) => {
  const vehicleStatusQuery = useQuery({
    queryKey: ["status", vehicleId],
    queryFn: async () => await VehiclesAPI.getVehicleStatusV2VehiclesVehicleIdStatusGet({ vehicleId }),
    retry: 3,
    refetchInterval: 10000,
    refetchOnWindowFocus: true,
  })

  return vehicleStatusQuery
}

export default useGetVehicleStatusQuery
