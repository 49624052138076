import { ActiveSession } from "@torc-robotics/vda-sessions-api/graphql/document"
import SessionCard from "./SessionCard"

const SessionsList = ({ sessions, isMySessions }: { sessions: ActiveSession[]; isMySessions: boolean }) => {
  return (
    <div className="sessions-list">
      {sessions.map((session) => (
        <SessionCard key={session.sessionId} session={session} assistable={isMySessions} />
      ))}

      {sessions.length === 0 && <p>No sessions found.</p>}
    </div>
  )
}

export default SessionsList
