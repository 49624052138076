import { Box } from "@mui/material"
import "./scss/monitorQueueMapPanel.scss"
import RemoteDashboardMap from "../remote-dashboard-map/RemoteDashboardMap"
import { MapType } from "@/framework/store/useMapStore"
import useFilteredMissionsQuery from "@/framework/query/useFilteredMissionsQuery"
import { VehicleIconType } from "@/framework/layers/useVehiclesLayer"

interface MonitorQueueMapPanelProps {
  selectedVehicle: string | null | undefined
}

const MonitorQueueMapPanel = ({ selectedVehicle }: MonitorQueueMapPanelProps) => {
  const { data: inTransitData } = useFilteredMissionsQuery({ states: ["IN_PROGRESS"] })
  const { data: preDepartureData } = useFilteredMissionsQuery({
    states: ["SCHEDULED", "PREPARING", "REVIEWING", "CLEARED", "WAIT_ACCEPT", "ACCEPTED", "PENDING"],
  })

  const inTransitMissions = inTransitData?.pages?.flatMap((page) => page.missions || []) ?? []
  const preDepartureMissions = preDepartureData?.pages.flatMap((page) => page.missions || []) || []

  const vehicleIds = [...inTransitMissions, ...preDepartureMissions].map((mission) => mission.vehicle_id)

  return (
    <Box id="monitor-queue-map-panel-container" data-testid="monitor-queue-map-panel-container">
      <RemoteDashboardMap
        mapType={MapType.MONITOR_QUEUE}
        maxWidth={716}
        maxHeight={304}
        vehicleIds={vehicleIds}
        selectedVehicle={selectedVehicle}
        vehicleIconType={VehicleIconType.OPERATIONAL}
      />
    </Box>
  )
}

export default MonitorQueueMapPanel
