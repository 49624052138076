import { Alert, AlertTitle, Box, Button } from "@mui/material"
import { useState } from "react"
import { useRouteError } from "react-router-dom"
import { getLogger } from "../../framework/contexts/DataDog"

const ErrorBoundary = () => {
  let error = useRouteError()
  const logger = getLogger()

  const [showError, setShowError] = useState(false)
  logger.error(error)

  return (
    <Alert sx={{ margin: 1, maxWidth: "900px" }} severity="error">
      <AlertTitle>Error</AlertTitle>
      There was an issue loading this component. Please contact @vda if this error persists. <br />
      <Button onClick={() => setShowError(!showError)} size="small" variant="outlined" color="error" sx={{ my: 1 }}>
        Show Error Details
      </Button>
      {showError && (
        <Box sx={{ backgroundColor: "rgba(0,0,0,0.1)", padding: 1, borderRadius: 1, overflow: "auto" }}>
          <pre>
            {error?.toString()}
            <br />
            {error?.fileName}:{error?.lineNumber}:{error?.columnNumber}
          </pre>
        </Box>
      )}
    </Alert>
  )
}

export default ErrorBoundary
