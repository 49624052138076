import { Button, Typography } from "@mui/material"
import "./scss/actionError.scss"
import { getLogger } from "../../../../../framework/contexts/DataDog"

const logger = getLogger()

const ActionError = ({
  actionButton,
  action,
  actionState,
  children,
  onSubmit = () => {},
  display = true,
  title = "",
}) => {
  const handleSubmit = () => {
    logger.info(`User acknowledged request error for ${actionButton?.type} request`, {
      action_button: actionButton,
      action_type: actionButton?.type,
      action_state: actionState,
    })
    onSubmit()
  }

  if (!display) return null
  return (
    display && (
      <div className="action-error-banner action-error-banner__ok" data-action-type={actionButton?.type}>
        <Typography variant="h5" className="action-error-banner__title">
          {title || `${action} Request ${actionState}`}
        </Typography>
        <Typography variant="body1" className="action-error-banner__text">
          {children}
        </Typography>
        <div className="action-error-banner__btns">
          <Button
            variant="contained"
            color="primary"
            className="action-error__btn--confirm"
            type="submit"
            onClick={handleSubmit}
          >
            Ok
          </Button>
        </div>
      </div>
    )
  )
}

export default ActionError
