import { useMutation, useQueryClient } from "@tanstack/react-query"
import {
  AcknowledgeEventDocument,
  AcknowledgeEventInput,
} from "@torc-robotics/vda-remote-assistance-api/graphql/document"
import execute from "../api/RemoteAssistanceAPIGraphQL"
import { getLogger } from "../../framework/contexts/DataDog"
import useLatestScenarioEvent from "../hooks/useLatestScenarioEvent"

const logger = getLogger()

const useAcknowledgeEventMutation = () => {
  const queryClient = useQueryClient()
  const event = useLatestScenarioEvent()

  const acknowledgeEventInput: AcknowledgeEventInput = {
    eventType: event?.eventType,
    loadId: event?.loadId,
    vehicleId: event?.vehicleId,
    tenant: event?.tenant,
  }

  const mutation = useMutation({
    mutationFn: async () => {
      return await execute(AcknowledgeEventDocument, { input: acknowledgeEventInput })
    },
    onSuccess: /* istanbul ignore next */ async ({ data }) => {
      if (data?.acknowledgeEvent.__typename === "UserNotAuthorizedError") {
        logger.error(data.acknowledgeEvent.message)
        alert(data.acknowledgeEvent.message)
      } else if (data?.acknowledgeEvent.__typename === "EventNotFoundError") {
        logger.error(data.acknowledgeEvent.message)
      } else if (data?.acknowledgeEvent.__typename === "RemoteAssistanceEvent") {
        logger.debug(`Successfully acknowledged event: ${event?.eventType ?? "Unknown"}`)
        await queryClient.invalidateQueries({
          queryKey: ["ra-events"],
        })
      } else {
        logger.warn("Unhandled return type for AcknowledgeEventMutation: ", data?.acknowledgeEvent)
      }
    },
    onError: (error) => {
      logger.error("Error ending session: ", error)
      alert(error)
    },
    retry: false,
  })

  return mutation
}

export default useAcknowledgeEventMutation
