import { useEffect, useMemo, useState } from "react"
import LinearProgress from "@mui/material/LinearProgress"
import "./scss/actionProgress.scss"
import { Box, Button, Typography } from "@mui/material"
import useInferredVehicleState from "@/framework/hooks/useInferredVehicleState"
import { getLogger } from "../../../../../framework/contexts/DataDog"
import palette from "@/assets/scss/variables.module.scss"

const logger = getLogger()

const SHOW_DURATION = 10000

const rejectReasonDisplayMapping = {
  "SDT is in manual mode": "ADS is Disengaged. SDT Cannot Accept a Request in Manual Mode.",
  "There is a system error": "Display system error", // TODO: This message will need to be changed once display text is finalized
  "Mission ID mismatch": "Mission ID mismatch", // TODO: This message will need to be changed once display text is finalized
}

const formatRejectReason = (rejectReason) => {
  // Default to the input if no mapping found
  return rejectReasonDisplayMapping[rejectReason] || rejectReason
}

const ResultDetails = ({ log }) => {
  const state_key = log?.details?.result

  if (state_key == "STOP-REQUEST-REJECTED" || state_key == "RESUME-REQUEST-REJECTED")
    return `Reason: ${formatRejectReason(log?.details?.reason)}`
  else if (state_key == "REROUTE-REQUEST-REJECTED")
    return "Request is not compatible with current SDT software generation."
  else return ""
}

const ActionProgress = ({ activeActionMutation, setActiveActionMutation }) => {
  const vehicleState = useInferredVehicleState()

  // returns the latest log, or whichever is defined
  const lastLog = useMemo(() => {
    const { motionLog, rerouteLog } = vehicleState || {}
    if (!motionLog || (rerouteLog && rerouteLog.created > motionLog.created)) {
      return rerouteLog || null
    }
    return motionLog || null
  }, [vehicleState?.motionLog, vehicleState?.rerouteLog])

  const logTimestamp = new Date(lastLog?.created * 1000)
  const requestState = lastLog === vehicleState?.motionLog ? vehicleState.motionState : vehicleState.rerouteState

  const [isStillShowing, setIsStillShowing] = useState(false)
  const [displayProgressBar, setDisplayProgressBar] = useState(false)
  const [displayAckButton, setDisplayAckButton] = useState(false)

  const handleAckButtonSubmit = () => {
    logger.info(`User acknowledged request finished for ${activeActionMutation?.type} request`, {
      action_type: activeActionMutation?.type,
      last_log: lastLog,
    })
    setIsStillShowing(false)
    setDisplayAckButton(false)
    setActiveActionMutation(null)
  }

  useEffect(() => {
    if (requestState?.value === 100) {
      if (new Date().getTime() - logTimestamp < SHOW_DURATION) {
        setIsStillShowing(true)
        setDisplayProgressBar(true)
        setTimeout(() => {
          logger.debug(`Switching from displaying progress bar to results for ${lastLog?.type} request`, {
            active_mutation: activeActionMutation,
            last_log: lastLog,
          })
          setDisplayProgressBar(false)
          setDisplayAckButton(true)
        }, SHOW_DURATION)
      } else {
        setIsStillShowing(true)
        setDisplayProgressBar(false)
        setDisplayAckButton(true)
      }
    } else if (requestState?.value > 0) {
      setIsStillShowing(true)
      setDisplayProgressBar(true)
      setDisplayAckButton(false)
    }
  }, [requestState?.value, vehicleState?.motionLog, vehicleState?.rerouteLog])

  return (
    isStillShowing &&
    activeActionMutation && (
      <Box className="action-progress-bar">
        <Typography className="action-progress-bar_title">
          {requestState?.message} {requestState?.icon ? requestState.icon : ""}
        </Typography>
        {displayProgressBar && (
          <LinearProgress
            className="action-progress-bar_bar"
            variant="determinate"
            value={requestState?.value}
            color={requestState?.error ? "error" : requestState?.value === 100 ? "success" : "primary"}
            sx={{
              "& .MuiLinearProgress-barColorPrimary": { backgroundColor: palette.primaryblue600 },
              "& .MuiLinearProgress-barColorError": { backgroundColor: palette.critical400 },
              "& .MuiLinearProgress-barColorSuccess": { backgroundColor: palette.success400 },
            }}
          />
        )}
        {displayAckButton && (
          <>
            {ResultDetails(lastLog) != "" && (
              <Typography className="action-progress-bar_details">
                <b>Reason:</b>
                <ResultDetails log={lastLog} />
              </Typography>
            )}
            <Button
              variant="contained"
              color="primary"
              className="action-progress-bar_button"
              type="submit"
              onClick={handleAckButtonSubmit}
            >
              Ok
            </Button>
          </>
        )}
      </Box>
    )
  )
}

export { ActionProgress, ResultDetails, formatRejectReason }
