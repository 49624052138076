import "./MrmAlertBanner.scss"
import useLatestMrmEvent from "@/framework/hooks/useLatestMrmEvent"
import { getFormattedTime } from "@/framework/utils/Utils"
import { Box, Button, Typography } from "@mui/material"
import ErrorIcon from "@mui/icons-material/Error"
import { isEqual } from "lodash"
import { RemoteAssistanceEventType, MrmRequestSource } from "@torc-robotics/vda-remote-assistance-api/graphql/document"

const WIKI_URL = "https://torc.atlassian.net/wiki/search?ancestor=1236434979&text="

const MrmAlertBanner = () => {
  const mrmStatus = useLatestMrmEvent()

  const reasons = mrmStatus?.eventDetails?.reason?.filter((reason) => !isEqual(reason, [0, 0, 0, 0]))

  const errorCodes = reasons?.map((reason) => reason[2])

  const errorURIString = errorCodes?.length ? errorCodes.join(" ") : ""
  const wikiURL = `${WIKI_URL}${encodeURIComponent(errorURIString)}`

  if (!mrmStatus) {
    return null
  }

  const isPauseRequest =
    mrmStatus.eventType === RemoteAssistanceEventType.MrmStatus &&
    mrmStatus.eventDetails.source === MrmRequestSource.MissionPause
  const mrmSourceText = isPauseRequest ? "Mission Paused" : "MRM"
  const mrmDetails = isPauseRequest
    ? "Remote User Initiated"
    : `${mrmStatus.eventDetails.status} - ${mrmStatus.eventDetails.type} - Errors ${JSON.stringify(reasons)}`

  return (
    <div className="mrm-alert-banner">
      <div className="mrm-alert-banner__icon">
        <ErrorIcon />
      </div>
      <div className="mrm-alert-banner__content">
        <div className="mrm-alert-banner__title">
          <Typography title={"Errors - " + JSON.stringify(reasons)} component={"div"}>
            <Box fontWeight={700} display={"inline"} pr={0.5}>
              {mrmSourceText}:
            </Box>
            {mrmDetails}
          </Typography>
        </div>
        <div className="mrm-alert-banner__message">
          <Typography component={"div"} variant="body2">
            Time of event:
            <Box fontWeight={700} display={"inline"} pl={0.5}>
              {getFormattedTime(mrmStatus.creationTs, Intl.DateTimeFormat().resolvedOptions().timeZone, "HH:mm")}
            </Box>
          </Typography>
        </div>
      </div>

      <div className="mrm-alert-banner__button">
        {!isPauseRequest && (
          <Button href={wikiURL} target="_blank" size="small" variant="contained" disableElevation disableRipple>
            Learn more
          </Button>
        )}
      </div>
    </div>
  )
}

export default MrmAlertBanner
