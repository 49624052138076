import { useQuery } from "@tanstack/react-query"
import { find } from "browser-geo-tz/src/find"

const useGetTimezoneFromCoordinates = (lat: number, lon: number) => {
  const query = useQuery({
    queryKey: ["timezone", { lat, lon }],
    queryFn: /* istanbul ignore next */ async () => {
      return await find(lat, lon)
    },
    refetchInterval: 60000,
    enabled: !!lat && !!lon,
  })

  return query
}

export default useGetTimezoneFromCoordinates
