import MissionInTransitItem from "./MissionInTransitItem"
import { src__model__mission__Mission_Output as Mission_Output } from "@torc-robotics/mm-missions-client"
import { Box } from "@mui/material"

interface MissionsInTransitListProps {
  missions: Mission_Output[]
  selectedMission: Mission_Output | null
  onMissionSelect: (mission: Mission_Output, isExpanded: boolean) => void
}

const MissionsInTransitList = ({ missions, selectedMission, onMissionSelect }: MissionsInTransitListProps) => {
  return (
    <Box>
      {missions.map((mission) => (
        <MissionInTransitItem
          key={mission.uuid}
          vehicle={mission.vehicle_id || ""}
          missionId={mission.load_id || ""}
          isExpanded={selectedMission?.load_id === mission.load_id}
          mission={mission}
          onExpandClick={onMissionSelect}
        />
      ))}
    </Box>
  )
}

export default MissionsInTransitList
