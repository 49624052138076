import { useQuery, useQueryClient } from "@tanstack/react-query"
import { useEffect } from "react"
import RouteAdvisorAPI from "../api/RouteAdvisorAPI"
import useGetMissionRoute from "./useGetMissionRoute"

/**
 * useRerouteQueries - handles the reroute query
 * @param {boolean} open - the open state of the confirm action banner
 * @param {object} actionButton - the action button object
 */
const useRerouteQueries = (open, actionButton) => {
  const queryClient = useQueryClient()

  const { routeAdvisorRoute } = useGetMissionRoute()

  const routeQuery = useQuery({
    queryKey: ["reroute"],
    queryFn: /* istanbul ignore next */ async () =>
      await RouteAdvisorAPI.routeRequest({
        match: false,
        origin: routeAdvisorRoute.data?.origin,
        destination: routeAdvisorRoute.data?.destination,
        mapVersion: routeAdvisorRoute.data?.map_version,
      }),
    enabled:
      actionButton.type === "reroute" &&
      open &&
      !!routeAdvisorRoute.data?.origin &&
      !!routeAdvisorRoute.data?.destination &&
      !!routeAdvisorRoute.data?.map_version,
  })

  // reset reroute query when confirm action banner is closed
  useEffect(() => {
    if (!open) {
      queryClient.resetQueries({ queryKey: ["reroute"], exact: true })
    }
  }, [open])

  return routeQuery
}

export default useRerouteQueries
