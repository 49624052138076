import { IconLayer } from "@deck.gl/layers"
import useVehicleDetailsQuery from "../query/useVehicleDetailsQuery"
import DirectionalIcon from "../../assets/truck-icons/directional.svg"
import ErrorDirectionIcon from "../../assets/truck-icons/error-directional.svg"
import WarningDirectionalIcon from "../../assets/truck-icons/warning-directional.svg"
import WarningIcon from "../../assets/truck-icons/Warning.svg"

const useVehicleLayer = () => {
  const vehicleDetails = useVehicleDetailsQuery()?.data ?? null
  const deck = []
  function getDirectionalIcon() {
    const connectivity = vehicleDetails?.connectivity?.data?.connected || false
    const vehicleHealth = vehicleDetails?.health?.health
    return vehicleHealth === "ERROR"
      ? ErrorDirectionIcon
      : vehicleHealth === "WARN" || !connectivity
      ? WarningDirectionalIcon
      : DirectionalIcon
  }

  function getWarningIcon() {
    const connectivity = vehicleDetails?.connectivity?.data?.connected || false
    const vehicleHealth = vehicleDetails?.health?.health
    const hasWarning = !connectivity || vehicleHealth === "ERROR" || vehicleHealth === "WARN"
    if (hasWarning) {
      return WarningIcon
    }
  }

  const getPosition = (d, index) => [vehicleDetails?.status?.lon, vehicleDetails?.status?.lat, index * 10]

  if (vehicleDetails !== null) {
    const activeVehiclesLayer = new IconLayer({
      id: "vehicle-layer",
      data: [vehicleDetails],
      pickable: true,
      getPosition: getPosition,
      getIcon: () => ({
        url: new URL(getDirectionalIcon(), import.meta.url).href,
        width: 37,
        height: 51,
        anchorY: 32.5,
      }),
      getSize: () => 40,
      getAngle: () => -vehicleDetails.status?.data?.direction?.heading || 0,
    })

    deck.push(activeVehiclesLayer)

    const warningIconLayer = new IconLayer({
      id: "warning-icon-layer",
      data: [vehicleDetails],
      pickable: true,
      getIcon: () => ({
        url: new URL(getWarningIcon(), import.meta.url).href,
        width: 11,
        height: 11,
      }),
      getSize: () => 40,
      getAngle: -vehicleDetails.status?.data?.direction?.heading,
    })

    deck.push(warningIconLayer)
  }
  return deck ?? null
}

export default useVehicleLayer
