import { Box, Typography } from "@mui/material"

type EventLogDetails = {
  status?: string
  title?: string
  body: string
  url?: string
  reason?: string
}

const EventDetails = ({ details }: { details: EventLogDetails }) => {
  return (
    <Box className="event-details">
      {details.status && (
        <Typography variant="h5" className={"event-details-status"}>
          {details.status}
        </Typography>
      )}
      <Box className="event-details-content">
        {details.title && (
          <Typography variant="subtitle1" className="event-details-title">
            {details.title}:
          </Typography>
        )}
        <Typography className="event-details-body">
          {details.body}
          {details.url && details.reason && (
            <span>
              {" "}
              -{" "}
              <a href={details.url} target="_blank" rel="noreferrer" className="event-details-link">
                {details.reason}
              </a>
            </span>
          )}
        </Typography>
      </Box>
    </Box>
  )
}

export type { EventLogDetails }
export default EventDetails
