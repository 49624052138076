import { Auth } from "aws-amplify"

interface RouteRequestResponse {
  routes: Route[]
}

interface Route {
  route_id: string
  summary_info: {
    estimated_time_minutes: number
    length_miles: number
  }
}

interface RouteDetails {
  origin: LatLon
  destination: LatLon
  latlons: LatLon[]
  map_version: string
  summary_info: {
    estimated_time_minutes: number
    length_miles: number
  }
  vias: []
  ways: number[]
}

interface LatLon {
  type?: "latlon"
  lat: number
  lon: number
}

interface IRouteAdvisorAPI {
  routeRequest: ({
    origin,
    destination,
    mapVersion,
    match,
  }: {
    origin: LatLon
    destination: LatLon
    mapVersion: string
    match?: boolean
  }) => Promise<RouteRequestResponse>
  getRouteById: ({ routeId }: { routeId: string }) => Promise<RouteDetails>
}

const RouteAdvisorAPI: IRouteAdvisorAPI = {
  routeRequest: async ({ origin, destination, mapVersion, match = true }) => {
    const url = new URL(`${import.meta.env.VITE_ROUTE_ADVISOR_API_URL as string}/route-requests`)
    const params = {
      match: match.toString(),
    }
    url.search = new URLSearchParams(params).toString()
    const session = await Auth.currentSession()

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
      },

      body: JSON.stringify({
        map_version: mapVersion,
        origin: {
          type: "latlon",
          lat: origin.lat,
          lon: origin.lon,
        },
        destination: {
          type: "latlon",
          lat: destination.lat,
          lon: destination.lon,
        },
        vias: [],
      }),
    })
    return response.json()
  },
  getRouteById: async ({ routeId }) => {
    const url = new URL(`${import.meta.env.VITE_ROUTE_ADVISOR_API_URL as string}/routes/${routeId}`)
    const session = await Auth.currentSession()

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
      },
    })
    return response.json()
  },
}

export default RouteAdvisorAPI
