import "./scss/scenarioDetectedBanner.scss"

interface ScenarioDetectedBannerProps {
  assistanceNeeded: boolean
}

const ScenarioDetectedBanner = ({ assistanceNeeded }: ScenarioDetectedBannerProps) => {
  return (
    <div
      data-testid="scenario-detected-banner"
      className={`scenario-detected-banner scenario-detected-banner--assistance-${assistanceNeeded.toString()}`}
    >
      {assistanceNeeded ? (
        <p
          className={`scenario-detected-banner-text scenario-detected-banner-text--assistance-${assistanceNeeded.toString()}`}
        >
          Object Detected
        </p>
      ) : (
        <div className="scenario-detected-banner-text-box">
          <p
            className={`scenario-detected-banner-text scenario-detected-banner-text--assistance-${assistanceNeeded.toString()}`}
          >
            Object Detection Canceled:
          </p>
          <p
            className={`scenario-detected-banner-text-description scenario-detected-banner-text-description--assistance-${assistanceNeeded.toString()}`}
          >
            High Confidence in Speed Limit Detection Resumed
          </p>
        </div>
      )}
    </div>
  )
}

export default ScenarioDetectedBanner
