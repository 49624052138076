import { Box, Typography, LinearProgress, Grid } from "@mui/material"
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined"
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined"
import "./scss/missionInTransitItem.scss"
import { getFormattedTime } from "@/framework/utils/Utils"
import useMissionQuery from "@/framework/query/useMissionQuery"
import useMissionRouteProgressQuery from "@/framework/query/useMissionRouteProgressQuery"

const WaypointIcon = () => (
  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", position: "relative" }}>
    <FiberManualRecordOutlinedIcon sx={{ border: "2px #aaaaaa" }} />
  </Box>
)

const MissionInTransitCollapseContent = ({ missionId }: { missionId: string }) => {
  const missionQuery = useMissionQuery(missionId)

  const mission = missionQuery.data?.missions?.[0]

  const { data, isPending, isError } = useMissionRouteProgressQuery(mission?.uuid ?? "")

  const progress = data?.percent_complete ?? 0
  const departed = mission?.start_display
  const arriving = mission?.destination_display
  const departureTime = mission?.start_time ? `${getFormattedTime(mission.start_time, undefined, "HH:MM z")}` : ""
  const eta = mission?.tms_schedule_arrival_time
    ? `${getFormattedTime(mission.tms_schedule_arrival_time, undefined, "HH:MM z")}`
    : ""

  return (
    <Box display="flex" alignItems="stretch" gap={0.5}>
      <Box flex={1} display="flex" flexDirection="column" pr={2} borderRight="1px solid" borderColor="divider">
        <Box display="flex" alignItems="flex-start">
          <WaypointIcon />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body2">Departed: {departed}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                Departed: {departureTime}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <MoreVertOutlinedIcon />
        <Box display="flex" alignItems="flex-start">
          <WaypointIcon />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body2">Arriving: {arriving}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                ETA: {eta}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box flex={1} pl={2} display="flex" flexDirection="column" justifyContent="center">
        <Typography variant="body2" mb={1}>
          Mission Progress
        </Typography>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1} mr={1}>
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{
                height: 10,
                borderRadius: 5,
                "& .MuiLinearProgress-bar": {
                  borderRadius: 5,
                },
              }}
            />
            <Box
              sx={{
                position: "relative",
                left: `${progress}%`,
                transform: "translateX(0)",
              }}
            >
              <Typography variant="caption" className="progress-bar__tooltip">
                {isPending || isError ? "- " : `${progress}`}%
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" mt={0.5}>
          <Typography variant="caption">0%</Typography>
          <Typography variant="caption">100%</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default MissionInTransitCollapseContent
