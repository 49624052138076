import { useEffect, useState } from "react"

const useElapsedTime = (startTime: number) => {
  const [elapsedTime, setElapsedTime] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = Math.floor(Date.now() / 1000)
      setElapsedTime(now - startTime)
    }, 1000)

    return () => clearInterval(intervalId)
  }, [startTime])

  return elapsedTime
}

export default useElapsedTime
